import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, Tab, ListItemText, Divider, IconButton, Button, Typography, Tooltip, Box } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2, Trash } from 'react-feather'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import Body from '../../../components/Body'
import api, { blobAPI } from '../../../services/api'
import { currencyByType } from '../../../services/atom'

import Faturas from './faturas'
import Usuarios from './users'
import useStyles from './style'

const DealerView = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [tab, setTab] = useState('info')

  const [dealer, setDealer] = useState([])
  const [logo, setLogo] = useState()

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  const fetchDealer = useCallback(async () => {
    const response = await api.get(`/dealer/${location.state.dealer._id}`)
    setDealer(response.data)
    if (response.data.logo) {
      const logo = await creatBlob(response.data.logo.name)
      setLogo(logo)
    }
  }, [])

  const creatBlob = async (fileName) => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const handlerUsers = useCallback((dealer) => {
    navigate('/admin/dealer/usuarios', { state: { dealer } })
  }, [])

  const handlerDiscount = useCallback((dealer) => {
    navigate('/descontos', { state: { dealer } })
  }, [])

  const handlerEdit = useCallback((dealer) => {
    navigate('/admin/dealer/editar', { state: { dealer } })
  }, [])

  const handlerDelete = useCallback(async (dealer) => {
    if (window.confirm('Deseja realmente excluir este revendedor?')) {
      const response = await api.delete(`/dealer/${dealer._id}`)
      enqueueSnackbar(response.data.message, { variant: 'info' })
      navigate('/admin/dealers')
    }
  }, [])

  useEffect(async () => {
    await fetchDealer()
  }, [])

  return (
    <Body title='Dealer'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TabContext value={tab}>
            <TabList onChange={handleChange} indicatorColor='primary'>
              <Tab label='Informações' value='info' />
              <Tab label='Financeiro' value='financeiro' />
              <Tab label='Usuários' value='usuarios' />
            </TabList>

            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <TabPanel value='info'>
                <Grid container direction='row'>
                  <Typography variant='h5'>
                    {dealer.name}
                  </Typography>
                  <Tooltip title='Editar dealer' arrow placement='right'>
                    <IconButton size='small' aria-label='editar-dealer' className={classes.plus} onClick={() => handlerEdit(dealer)}><Edit2 /></IconButton>
                  </Tooltip>
                  <Tooltip title='Editar dealer' arrow placement='right'>
                    <IconButton size='small' aria-label='editar-dealer' className={classes.plus} onClick={() => handlerDelete(dealer)}><Trash /></IconButton>
                  </Tooltip>
                  <Box flexGrow={1} />
                  <Button size='small' variant='contained' color='primary' className={classes.options} onClick={() => handlerUsers(dealer)}>
                    Usuários
                  </Button>
                  <Button size='small' variant='contained' color='primary' onClick={() => handlerDiscount(dealer)}>
                    Descontos
                  </Button>
                </Grid>
                <List component='nav' aria-label='dealer'>
                  <ListItem><ListItemText primary='Código' secondary={dealer.code} /></ListItem>
                  <ListItem><ListItemText primary='Moeda' secondary={dealer.priceType} /></ListItem>
                  <ListItem><ListItemText primary='Máximo saldo negativo' secondary={currencyByType(dealer.maxNegativeValue, dealer.priceType)} /></ListItem>
                  <ListItem><ListItemText primary='Email' secondary={dealer.email} /></ListItem>
                  <ListItem><ListItemText primary='Telefone' secondary={dealer.phone} /></ListItem>
                  <ListItem><ListItemText primary='Facebook' secondary={dealer.facebook} /></ListItem>
                  <ListItem><ListItemText primary='Instagram' secondary={dealer.instagram} /></ListItem>
                  <ListItem><ListItemText primary='Youtube' secondary={dealer.youtube} /></ListItem>
                  <ListItem><ListItemText primary='Descrição' secondary={dealer.description} /></ListItem>
                  <Divider className={classes.divider} />
                  <ListItem>
                    <ListItemText primary={!dealer.active && 'Dealer desativada'} secondary={`Criado ${moment(dealer?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
                  </ListItem>
                  <Divider className={classes.divider} />
                  {logo
                    ? <img src={logo} className={classes.thumbnail} onClick={() => window.open(logo)} />
                    : <ListItem><ListItemText primary='Sem logo' /></ListItem>}
                </List>
              </TabPanel>

              <TabPanel value='financeiro'>
                <Faturas dealer={dealer} />
              </TabPanel>

              <TabPanel value='usuarios'>
                <Usuarios dealer={dealer} />
              </TabPanel>
            </Paper>

          </TabContext>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DealerView

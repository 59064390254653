import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel, FormHelperText } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const ModelsForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [manufacturer, setManufacturer] = useState()
  const [manufactures, setManufactures] = useState([])

  const [loadModel, setLoadModel] = useState(false)
  const [model, setModel] = useState()
  const [models, setModels] = useState([])

  const [done, setDone] = useState(false)
  const [active, setActive] = useState(true)

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          ini: Yup.string().required('Ano inicial é obrigatório.')
            .max(4, 'Ano inicial deve ter no máximo 4 caracteres.'),
          end: Yup.string().required('Ano final é obrigatório.')
            .max(4, 'Ano final deve ter no máximo 4 caracteres.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`year/${location.state?.year._id}`, {
          ini: data.ini,
          end: data.end,
          manufacturer: manufacturer._id,
          model: model._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/ano/', { state: { year: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          ini: Yup.string().required('Ano inicial é obrigatório.')
            .max(4, 'Ano inicial deve ter no máximo 4 caracteres.'),
          end: Yup.string().required('Ano final é obrigatório.')
            .max(4, 'Ano final deve ter no máximo 4 caracteres.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/year/', {
          ini: data.ini,
          end: data.end,
          manufacturer: manufacturer._id,
          model: model._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/ano/', { state: { year: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [active, manufacturer, model])

  const fetchManufactures = useCallback(async () => {
    const response = await api.get('manufactures')
    setManufactures(response.data)
  }, [])

  const fetchModels = useCallback(async () => {
    setLoadModel(false)
    const response = await api.get(`models/manufacturer/${manufacturer?._id}`)
    setModels(response.data)
    if (response.data.length > 0) {
      setLoadModel(true)
    }
  }, [manufacturer])

  const setEdit = useCallback(async (year) => {
    setActive(year.active)
    setManufacturer(year.manufacturer)
    setModel(year.model)
  }, [])

  useEffect(async () => {
    await fetchModels()
  }, [manufacturer])

  useEffect(async () => {
    await fetchManufactures()
    if (location.state) { await setEdit(location.state.year) }
    setDone(true)
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Novo'} ano ${location.state?.year?.year}`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Novo'} ano {location.state?.year?.year}
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.year}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='ini'
                  label='Ano inicial*'
                  name='ini'
                  autoFocus
                />
                <FormHelperText id='component-error-text' style={{ color: '#f44336' }}>
                  Se não tiver ano inicial, coloque 0000
                </FormHelperText>

                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='end'
                  label='Ano final*'
                  name='end'
                />
                <FormHelperText id='component-error-text' style={{ color: '#f44336' }}>
                  Se não tiver ano final, coloque 0000
                </FormHelperText>

                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='manufacturer'
                  className={classes.select}
                  options={manufactures}
                  value={manufacturer}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label='Selecione uma fabricante *' variant='outlined' />}
                  onChange={(_, newValue) => { setManufacturer(newValue) }}
                />
                {loadModel &&
                  <Autocomplete
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='model'
                    className={classes.select}
                    options={models}
                    value={model}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => <Input {...params} id='model' name='model' label='Selecione uma modelo *' variant='outlined' />}
                    onChange={(_, newValue) => { setModel(newValue) }}
                  />}
                <Divider className={classes.divider} />
                <FormControlLabel
                  label='Ano ativo?'
                  control={
                    <Switch
                      checked={active}
                      onChange={handleSwitch}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state ? 'Atualizar' : 'Criar'} ano
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ModelsForm

import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel, InputAdornment } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import InputMask from '../../../components/InputMask'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const AdditionalForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const formRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const [fromProd, setFromProd] = useState(false)
  const [active, setActive] = useState(true)
  const [done, setDone] = useState(false)

  const [price, setPrice] = useState()
  const [stage, setStage] = useState()

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state?.additional) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME é obrigatório.'),
          code: Yup.string().required('Código é obrigatório.'),
          price: Yup.string().required('Preço é obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`additional/${location.state?.additional._id}`, {
          name: data.name,
          code: data.code,
          description: data.description,
          price,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/adicional/', { state: { additional: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME é obrigatório.'),
          code: Yup.string().required('Código é obrigatório.'),
          price: Yup.string().required('Preço é obrigatório.')
        })

        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/additional', {
          name: data.name,
          code: data.code,
          description: data.description,
          price,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        if (fromProd) {
          navigate('/stage/', { state: { stage } })
        } else {
          navigate('/adicional/', { state: { additional: response.data } })
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [stage, price, active])

  const setEdit = useCallback(async (additional) => {
    setPrice(additional.price)
    setActive(additional.active)
  }, [])

  const setProdStage = useCallback(async (stage) => {
    setStage(stage)
    setFromProd(true)
  }, [])

  useEffect(async () => {
    if (location.state?.stage) {
      await setProdStage(location.state?.stage)
    }
    if (location.state?.additional) {
      await setEdit(location.state.additional)
    }
    setDone(true)
  }, [])

  return (
    <Body title={` ${location.state?.additional ? 'Editar' : 'Novo'} adicional`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state?.additional ? 'Editar' : 'Novo'} adicional
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.additional}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='NOME *'
                  name='name'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='code'
                  label='Código *'
                  name='code'
                />
                {/* <Divider className={classes.divider} /> */}
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='description'
                  label='Descrição'
                  name='description'
                  multiline
                  minRows={5}
                />
                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='price'
                  label='Preço *'
                  name='price'
                  InputProps={{ startAdornment: <InputAdornment position='start'>R$</InputAdornment> }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <Divider className={classes.divider} />
                <FormControlLabel
                  label='Adicional ativo?'
                  control={
                    <Switch
                      checked={active}
                      onChange={handleSwitch}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state?.additional ? 'Atualizar' : 'Criar'} adicional
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default AdditionalForm

import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, InputAdornment } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const DiscountForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [done, setDone] = useState(false)

  const [stagetypes, setStageTypes] = useState([])
  const [stagetype, setStageType] = useState()

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state?.discount) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          table2: Yup.string().required('Porcentagem tabela importado é obrigatória.'),
          table1: Yup.string().required('Porcentagem tabela nacional é obrigatória.'),
          stageType: Yup.string().required('Tipo de stage é obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`discount/${location.state?.discount._id}`, {
          table2: data.table2,
          table1: data.table1,
          dealer: location.state?.dealer._id,
          stageType: stagetype
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/descontos/', { state: { dealer: location.state?.dealer } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          table2: Yup.string().required('Porcentagem tabela importado é obrigatória.'),
          table1: Yup.string().required('Porcentagem tabela nacional é obrigatória.'),
          stageType: Yup.string().required('Tipo de stage é obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/discount', {
          table2: data.table2,
          table1: data.table1,
          dealer: location.state?.dealer._id,
          stageType: stagetype
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/descontos/', { state: { dealer: location.state?.dealer } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [location, stagetype])

  const fetchStage = useCallback(async () => {
    const response = await api.get('stagetypes')
    setStageTypes(response.data)
  }, [])

  const setEdit = useCallback(async (discount) => {
    setStageType(discount.stageType)
  }, [])

  useEffect(async () => {
    await fetchStage()
    if (location.state?.discount) { await setEdit(location.state.discount) }
    setDone(true)
  }, [])

  return (
    <Body title={` ${location.state?.discount ? 'Editar' : 'Novo'} desconto`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state?.discount ? 'Editar' : 'Novo'} desconto - {location.state?.dealer.name}
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.discount}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='table1'
                  label='Tabela Nacional - Porcentagem % *'
                  name='table1'
                  autoFocus
                  InputProps={{ endAdornment: <InputAdornment position='start'>%</InputAdornment> }}
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='table2'
                  label='Tabela Importado - Porcentagem % *'
                  name='table2'
                  InputProps={{ endAdornment: <InputAdornment position='start'>%</InputAdornment> }}
                />
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  className={classes.select}
                  options={stagetypes}
                  value={stagetype}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} name='stageType' label='Selecione um tipo de stage *' variant='outlined' />}
                  onChange={(_, newValue) => { setStageType(newValue) }}
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state?.discount ? 'Atualizar' : 'Criar'} desconto
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DiscountForm

import React from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import OpenNavigation from '../../components/OpenNavigation'

import useStyles from './style'

const SignIn = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <Grid item xs={12} sm={12} md={12} style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')", justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
        <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
          {t('contact.title').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} className={classes.sobre}>
            <Paper style={{ padding: 20, marginBottom: 20 }} elevation={3}>
              <Typography variant='h5' color='primary'>
                PERFORTECH MOTOR SPORT LTDA.
              </Typography>
              <br />
              SOFWARE ENGINEERING.
              <br />
              Av. General MelLO N.2759 - Jardim Tropical
              <br />
              SALA 02
              <br />
              Cuiabá / MT - CEP 78065-175
              <br />
              <a href='tel:+65984055001'>
                (65) 98405-5001
              </a>
              <br />
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15369.844725383384!2d-56.0746114!3d-15.6204113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe0d16e596baa7a15!2sPerfortech!5e0!3m2!1spt-BR!2sbr!4v1510230499676'
                width='100%'
                height='200'
                frameBorder='0'
                style={{ marginTop: 10, border: 0, background: 'lightGrey' }}
                allowFullScreen=''
                aria-hidden='false'
                tabIndex='0'
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.sobre}>
            <Paper style={{ padding: 20, marginBottom: 20 }} elevation={3}>
              <Typography variant='h5' color='primary'>
                FLORIPA MOTOR SPORT. (DEALER 01)
              </Typography>
              <br />
              AV. Patricio Caldeira de Andrade N.1180 Bairro: Capoeiras
              <br />
              Florianópolis / SC - CEP 88085-150
              <br />
              <a href='tel:+4832118001'>
                (48) 3211-8001
              </a>
              <br />
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14145.588111111681!2d-48.5840651!3d-27.581218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe24047fd0c8afd97!2sPerfortech+Motor+Sports!5e0!3m2!1spt-BR!2sbr!4v1510229997608'
                width='100%'
                height='200'
                frameBorder='0'
                style={{ marginTop: 10, border: 0, background: 'lightGrey' }}
                allowFullScreen=''
                aria-hidden='false'
                tabIndex='0'
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.sobre}>
            <Paper style={{ padding: 20, marginBottom: 20 }} elevation={3}>
              <Typography variant='h5' color='primary'>
                REGESCAP AUTO CENTER LTDA. (DEALER 02)
              </Typography>
              <br />
              Av. Carmindo de Campos, 770 - Jardim Petrópolis
              <br />
              Cuiabá / MT - CEP 78070-100
              <br />
              <a href='tel:+6536275001'>
                (65) 3627-5001
              </a>
              <br />
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15369.844725383384!2d-56.0746114!3d-15.6204113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe0d16e596baa7a15!2sPerfortech!5e0!3m2!1spt-BR!2sbr!4v1510230499676'
                width='100%'
                height='200'
                frameBorder='0'
                style={{ marginTop: 10, border: 0, background: 'lightGrey' }}
                allowFullScreen=''
                aria-hidden='false'
                tabIndex='0'
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default SignIn

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api, { blobAPI } from '../../../services/api'

import useStyles from './style'

const ManufacturerView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [manufacturer, setManufacturer] = useState([])
  const [logo, setLogo] = useState()

  const fetchManufacturer = useCallback(async () => {
    const response = await api.get(`/manufacturer/${location.state.manufacturer._id}`)
    setManufacturer(response.data)
    if (response.data.logo) {
      const logo = await creatBlob(response.data.logo.name)
      setLogo(logo)
    }
  }, [])

  const creatBlob = async (fileName) => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const handlerEdit = useCallback((manufacturer) => {
    navigate('/fabricante/editar', { state: { manufacturer } })
  }, [])

  useEffect(async () => {
    await fetchManufacturer()
  }, [])

  return (
    <Body title='Fabricante'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Fabricante
              </Typography>
              <Tooltip title='Editar fabricante' arrow placement='right'>
                <IconButton size='small' aria-label='editar-fabricante' className={classes.plus} onClick={() => handlerEdit(manufacturer)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='fabricante'>
              <ListItem>
                <ListItemText primary='NOME' secondary={manufacturer?.name} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={!manufacturer.active && 'Fabricante desativada'} secondary={`Criado ${moment(manufacturer?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
              <Divider className={classes.divider} />
              {logo
                ? <img src={logo} className={classes.thumbnail} onClick={() => window.open(logo)} />
                : <ListItem><ListItemText primary='Sem logo' /> </ListItem>}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ManufacturerView

import { red } from '@material-ui/core/colors'

const theme = {
  root: { height: '100vh' },
  palette: {
    type: 'light',
    primary: {
      main: '#f15b2b'
    },
    secondary: {
      main: '#F0F0F0',
      grey: '#707070'
    },
    error: {
      main: red.A200
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    fontFamily: '"Roboto",sans-serif'
  }
}

export default theme

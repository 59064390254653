import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros, currency } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const AdditionalForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [adds, setAdds] = useState([])
  const [add, setAdd] = useState(null)

  const [done, setDone] = useState(false)

  const handleNew = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        add: Yup.string().required('NOME é obrigatório.')
      })
      await schema.validate(data, { abortEarly: false })

      const response = await api.put('stages/additian/add', {
        _id: location.state.stage._id,
        _idAdd: add._id
      })

      if (response.data.status === 'error') { throw new Error(response.data.message) }
      navigate('/stage/', { state: { stage: location.state.stage } })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [add])

  const fetchAdd = useCallback(async () => {
    const response = await api.get('additionals')
    setAdds(response.data)
    setDone(true)
  }, [])

  useEffect(() => {
    fetchAdd()
  }, [])

  return (
    <Body title={`Novo adicional - ${location.state?.stage?.name}`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              Novo adicional - {location.state?.stage?.name}
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.additional}
              >
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  className={classes.select}
                  options={adds}
                  value={add}
                  getOptionLabel={(option) => `${option.name} ${currency(option.price)}`}
                  renderInput={(params) => <Input {...params} id='add' name='add' label='Selecione adicional *' variant='outlined' />}
                  onChange={(_, newValue) => { setAdd(newValue) }}
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  Adicionar
                </Button>
              </Form>}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default AdditionalForm

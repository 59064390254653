import React, { createContext, useCallback, useState, useContext } from 'react'
import jwtDecode from 'jwt-decode'

import api, { blobAPI, fileAPI } from '../services/api'
import socket from '../services/socket'
import setNotifications from '../services/firebase'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = window.localStorage.getItem('#perfortech:token')
    if (token) {
      const user = window.localStorage.getItem('#perfortech:user')
      const parsedUser = JSON.parse(user)
      const currentTime = new Date().getTime() / 1000
      setNotifications(parsedUser._id, token)

      if (currentTime > jwtDecode(token).exp) {
        const token = window.localStorage.getItem('#perfortech:token')
        const user = window.localStorage.getItem('#perfortech:user')
        const parsedUser = JSON.parse(user)
        api.post('session/delete', { userId: parsedUser._id, token })
        window.localStorage.removeItem('#perfortech:token')
        window.localStorage.removeItem('#perfortech:notification')
        window.localStorage.removeItem('#perfortech:user')
        window.localStorage.removeItem('#perfortech:sidebar')
        socket.disconnect()
        return {}
      }

      api.defaults.headers.authorization = `Bearer ${token}`
      fileAPI.defaults.headers.authorization = `Bearer ${token}`
      blobAPI.defaults.headers.authorization = `Bearer ${token}`
      socket.auth.authorization = token
      socket.connect()
      return { user: parsedUser, token }
    }
    return {}
  })

  const singIn = useCallback(async ({ email, password }) => {
    const response = await api.post('session', { email, password })
    if (response.data.status === 'error') { throw response.data.message }
    const sessions = response.data.user.sessions[0]
    const token = sessions.token
    const user = response.data.user
    setNotifications(user._id, token)
    window.localStorage.setItem('#perfortech:token', token)
    window.localStorage.setItem('#perfortech:user', JSON.stringify(user))
    window.localStorage.setItem('#perfortech:sidebar', false)
    api.defaults.headers.authorization = `Bearer ${token}`
    fileAPI.defaults.headers.authorization = `Bearer ${token}`
    blobAPI.defaults.headers.authorization = `Bearer ${token}`
    socket.auth.authorization = token
    socket.connect()
    setData({ user })
  }, [api, fileAPI, blobAPI, socket, setNotifications])

  const signOut = useCallback(() => {
    const token = window.localStorage.getItem('#perfortech:token')
    const user = window.localStorage.getItem('#perfortech:user')
    const parsedUser = JSON.parse(user)
    api.post('session/delete', { userId: parsedUser._id, token })
    window.localStorage.removeItem('#perfortech:token')
    window.localStorage.removeItem('#perfortech:notification')
    window.localStorage.removeItem('#perfortech:user')
    window.localStorage.removeItem('#perfortech:sidebar')
    socket.disconnect()
    setData({})
  }, [api, socket])

  return (<AuthContext.Provider value={{ user: data.user, token: data.token, singIn, signOut }}>{children}</AuthContext.Provider>)
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) { throw new Error('useAuth só pode ser usando dentro do AuthProvider') }
  return context
}

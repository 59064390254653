import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, Box, IconButton, Typography, Tooltip, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import RequestTable from '../../../components/RequestTable'
import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const PedidoIndex = () => {
  const classes = useStyles()
  const [requests, setRequests] = useState([])
  const navigate = useNavigate()

  const fetchResquest = useCallback(async () => {
    const response = await api.get('requests/dealer')
    setRequests(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/pedido/novo')
  }, [])

  useEffect(async () => {
    await fetchResquest()
  }, [])

  return (
    <Body title='Pedidos em andamento'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Pedidos em andamento
              </Typography>
              <Tooltip title='Novo pedido' arrow placement='right'>
                <IconButton size='small' aria-label='novo-pedido' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
              <Box flexGrow={1} />
              <Button variant='contained' color='primary' style={{ marginLeft: '8px' }} onClick={() => navigate('/pedido/todos')}>Todos</Button>
            </Grid>
            <RequestTable requests={requests} />
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default PedidoIndex

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import StagesTable from '../../../components/StagesTable'
import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const StagesIndex = () => {
  const classes = useStyles()
  const [stages, setStages] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const fetchStage = useCallback(async () => {
    setLoading(true)
    const response = await api.get('stages')
    setStages(response.data)
    setLoading(false)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/stage/novo')
  }, [])

  useEffect(async () => {
    await fetchStage()
  }, [])

  return (
    <Body title='Stages'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Stages
              </Typography>
              <Tooltip title='Novo stage' arrow placement='right'>
                <IconButton size='small' aria-label='novo-stage' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {loading ? <LinearProgress color='secondary' /> : <StagesTable stages={stages} />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default StagesIndex

import React, { useState, useEffect } from 'react'
import { Grid, Typography, Card, CardContent, List, LinearProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ArrowDown } from 'react-feather'

import OpenNavigation from '../../components/OpenNavigation'
import ProductHeader from '../../components/ProductHeader'
import { currency } from '../../services/atom'

import api from '../../services/api'

import useStyles from './style'

const SignIn = () => {
  const classes = useStyles()
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState([])

  const { t } = useTranslation()

  useEffect(async () => {
    const { _id } = params
    const response = await api.get(`stage/${_id}`)
    setProduct(response.data)
    setLoading(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <ProductHeader _id={product._id} name={t('product')} />
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        {loading && <LinearProgress />}
        <Card style={{ padding: 20, marginBottom: 20 }} variant='outlined'>
          <CardContent>
            <Typography className={classes.title} color='textSecondary'>
              {product.name}
            </Typography>
            <Typography className={classes.title}>
              ( {product.manufacturer?.name} - {product.model?.name} - {product.year?.year} - {product.motor?.name} )
            </Typography>
            <Typography variant='h5' component='h2'>
              {product.price ? currency(product.price) : 'Consulte valor'}
            </Typography>
            <Typography className={classes.pos} color='textSecondary'>
              {product.description}
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <List style={{ textAlign: 'center' }}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', marginBottom: 20 }}>POWER</Typography>
                  <Typography variant='body2' component='p'>
                    Original
                  </Typography>
                  <Typography variant='h6'>
                    {`${product.motor?.originalPower}`}
                  </Typography>
                  <ArrowDown size={35} />
                  <Typography variant='body2' component='p'>
                    Modificado
                  </Typography>
                  <Typography variant='h5'>
                    {`${product.newPower}HP`}
                  </Typography>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <List style={{ textAlign: 'center' }}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', marginBottom: 20 }}>TORQUE</Typography>
                  <Typography variant='body2' component='p'>
                    original
                  </Typography>
                  <Typography variant='h6'>
                    {`${product.motor?.originalTorque}(NM)`}
                  </Typography>
                  <ArrowDown size={35} />
                  <Typography variant='body2' component='p'>
                    Modificado
                  </Typography>
                  <Typography variant='h6'>
                    {`${product.newTorque}(NM)`}
                  </Typography>
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SignIn

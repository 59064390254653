import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api, { publicBlobAPI } from '../../../services/api'

import useStyles from './style'

const RepresentativeView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [representative, setRepresentative] = useState([])
  const [logo, setLogo] = useState()

  const fetchRepresentative = useCallback(async () => {
    const response = await api.get(`/representative/${location.state.representative._id}`)
    setRepresentative(response.data)
    if (response.data.logo) {
      const logo = await creatBlob(response.data.logo.name)
      setLogo(logo)
    }
  }, [])

  const creatBlob = async (fileName) => {
    const responseFile = await publicBlobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const handlerEdit = useCallback((representative) => {
    navigate('/representante/editar', { state: { representative } })
  }, [])

  useEffect(async () => {
    await fetchRepresentative()
  }, [])

  return (
    <Body title='Representante'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Representante
              </Typography>
              <Tooltip title='Editar representante' arrow placement='right'>
                <IconButton size='small' aria-label='editar-representante' className={classes.plus} onClick={() => handlerEdit(representative)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='representante'>
              <ListItem><ListItemText primary='NOME' secondary={representative.name} /></ListItem>
              <ListItem><ListItemText primary='Pais' secondary={representative.country} /></ListItem>
              {representative.country === 'Brasil' &&
                <>
                  <ListItem><ListItemText primary='Estado' secondary={representative.state} /></ListItem>
                  <ListItem><ListItemText primary='Cidade' secondary={representative.city} /></ListItem>
                </>}
              <ListItem><ListItemText primary='Endereço' secondary={representative.address} /></ListItem>
              <ListItem><ListItemText primary='Email' secondary={representative.email} /></ListItem>
              <ListItem><ListItemText primary='Telefone' secondary={representative.phone} /></ListItem>
              <ListItem><ListItemText primary='Contato' secondary={representative.contactName} /></ListItem>
              <ListItem><ListItemText primary='Site' secondary={representative.site} /></ListItem>
              <ListItem><ListItemText primary='Facebook' secondary={representative.facebook} /></ListItem>
              <ListItem><ListItemText primary='Instagram' secondary={representative.instagram} /></ListItem>
              <ListItem><ListItemText primary='Youtube' secondary={representative.youtube} /></ListItem>
              <ListItem><ListItemText primary='Descrição' secondary={representative.description} /></ListItem>
              <ListItemText primary={representative.dynamometer && 'Representante tem dinamômetro'} />
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={!representative.active && 'Representante desativada'} secondary={`Criado ${moment(representative?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
              <Divider className={classes.divider} />
              {logo
                ? <img src={logo} className={classes.thumbnail} onClick={() => window.open(logo)} />
                : <ListItem><ListItemText primary='Sem logo' /> </ListItem>}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default RepresentativeView

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const ModelsIndex = () => {
  const classes = useStyles()
  const [models, setModels] = useState([])
  const navigate = useNavigate()

  const fetchModels = useCallback(async () => {
    const response = await api.get('models')
    setModels(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/modelo/novo')
  }, [])

  const handlerView = useCallback((model) => {
    navigate('/modelo/', { state: { model } })
  }, [])

  useEffect(async () => {
    await fetchModels()
  }, [])

  return (
    <Body title='Modelos'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Modelos
              </Typography>
              <Tooltip title='Novo modelo' arrow placement='right'>
                <IconButton size='small' aria-label='novo-modelo' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {models.length > 0
              ? (
                <List component='nav' aria-label='modelos'>
                  {models.map((model, index) => (
                    <div key={index}>
                      <ListItem button onClick={() => handlerView(model)}>
                        <ListItemText primary={`${model.manufacturer?.name} - ${model?.name}`} secondary={model.active ? 'ATIVO' : 'INATIVO'} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <LinearProgress color='secondary' />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ModelsIndex

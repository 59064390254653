import { makeStyles } from '@material-ui/core'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'transparent',
    boxShadow: 0
  },
  toolBar: {
    // background: 'linear-gradient(180deg, #f15b2b, #f15b2bc0,#f15b2b90,#f15b2b50, transparent)',
    // height: 150,
    // boxShadow: 0,
    // top: -20
    background: 'linear-gradient(270deg, #000000, #0009,#0005, transparent), linear-gradient(90deg, #ffff, #ffff, #ffff, transparent)',
    height: 110
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    // color: '#ffffff'
    filter: 'invert(100%)'
  },
  drawerList: {
    width: '40vw',
    padding: 20,
    background: '#272727',
    flex: '1',
    color: '#fff',
    textAlign: 'center'
  },
  drawerPaper: {
    background: '#fff',
    border: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  logo: {
    position: 'relative',
    marginBottom: theme.spacing(4),
    height: 60,
    top: 20,
    left: 20
  }
}))

export default useStyles

import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography, ListItemText, LinearProgress } from '@material-ui/core'

import { useTranslation } from 'react-i18next'

import OpenNavigation from '../../components/OpenNavigation'
import api from '../../services/api'

import useStyles from './style'

const About = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [about, setAbout] = useState([])
  const [configuration, setConfiguration] = useState({})

  const { t } = useTranslation()

  const fetchConfiguration = useCallback(async () => {
    const response = await api.get('/configuration/about')
    setConfiguration(response.data)
    setLoading(false)
  }, [])

  useEffect(async () => {
    await fetchConfiguration()
    const selected = window.localStorage.getItem('i18nextLng') || 'pt-BR'
    setAbout(selected)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <Grid item xs={12} sm={12} md={12} style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')", justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
        <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
          {t('sobre.title').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        {loading
          ? <LinearProgress />
          : (
            <>
              <ListItemText
                style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                primary={configuration.about}
              />
              <br />
              <Typography className={classes.title}>Equipe</Typography>
              <ListItemText
                secondary={configuration.staff}
                primary={about}
                style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
              />
            </>)}
      </Grid>
    </Grid>
  )
}

export default About

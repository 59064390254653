import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: { height: '100vh' },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  appBar: {
    borderRadius: '0 0 10px 10px',
    background: 'linear-gradient(left bottom, #b8de21, #8fe256, #66e37e, #3ee2a1, #21debe)'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  plus: {
    marginLeft: theme.spacing(1)
  },
  map: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  imagePaper: {
    marginTop: theme.spacing(8),
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  image: {
    height: '100%'
  },
  chat: {
    padding: theme.spacing(0)
  },
  fatherGrid: {
    marginBotton: theme.spacing(0)
  },
  scroll: {
    height: '80vh',
    overflowY: 'scroll',
    behavior: 'smooth',
    flexGrow: 1
  },
  avatar: {
    margin: 5,
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  },
  media: {
    height: 123
  },
  thumbnail: {
    borderRadius: 10,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  right: {
    textAlign: 'right'
  },
  rightText: {
    marginRight: theme.spacing(1)
  },
  left: {
    textAlign: 'left'
  },
  leftText: {
    marginLeft: theme.spacing(1)
  },
  accordion: {
    padding: theme.spacing(0)
  },
  send: {
    margin: theme.spacing(1),
    padding: theme.spacing(0.3),
    display: 'flex',
    flex: 1
  },
  sms: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 10,
    display: 'flex',
    flex: 1
  },
  iconButton: {
    padding: theme.spacing(1)
    // margin: theme.spacing(1),
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1)
  },
  dropzone: {
    boxSizing: 'border-box',
    display: 'block',
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 99999,
    background: 'rgba(#60a7dc,.8)',
    border: '5px dashed #60a7dc'
  },
  dropzoneI: {
    boxSizing: 'border-box',
    display: 'none',
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 99999,
    background: 'rgba(#60a7dc,.8)',
    border: '5px dashed #60a7dc'
  },
  test: {
    backgroundColor: 'red',
    width: 100,
    height: 100
  },
  preview: {
    height: 40,
    margin: 5
  },
  fileIcon: {
    width: 30,
    margin: 5
  }
}))

export default useStyles

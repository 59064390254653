import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import AddTable from '../../../components/AddTable'
import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const AdditionalIndex = () => {
  const classes = useStyles()
  const [additionals, setAdditional] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const fetchAdditional = useCallback(async () => {
    setLoading(true)
    const response = await api.get('additionals')
    setAdditional(response.data)
    setLoading(false)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/adicional/novo')
  }, [])

  useEffect(async () => {
    await fetchAdditional()
  }, [])

  return (
    <Body title='Adicionais'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Adicionais
              </Typography>
              <Tooltip title='Novo adicional' arrow placement='right'>
                <IconButton size='small' aria-label='novo-adicional' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>

            {loading
              ? <LinearProgress color='secondary' />
              : <AddTable additionals={additionals} />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default AdditionalIndex

// {additionals.length > 0 ?
//   <List component="nav" aria-label="adicional">
//     {additionals.map((additional, index) => (
//       <div key={index}>
//         <ListItem button onClick={() => handlerView(additional)}>
//           {/* <ListItemText primary={`${additional.manufacturer?.name} - ${additional.model?.name} - ${additional.year?.year} - ${additional.motor?.name} - ${additional.stage.name} - ${additional.name}`} secondary={additional.active ? 'ATIVO' : 'INATIVO'} /> */}
//           <ListItemText primary={additional.name} secondary={additional.active ? 'ATIVO' : 'INATIVO'} />
//           <ListItemText primary={`${currency(additional.price)}`} />
//         </ListItem>
//         <Divider className={classes.divider} />
//       </div>
//     ))}
//   </List>
// }

import React, { useCallback, useRef, useState } from 'react'
import { Button, Typography, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const filter = createFilterOptions()

const ManufacturerDialog = ({ manufactures, fetchManufacturer, manufacturer, setManufacturer }) => {
  const classes = useStyles()
  const formRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const [open, toggleOpen] = useState(false)
  const [dialogValue, setDialogValue] = useState({ name: '' })

  const [file, setFile] = useState()

  const handleClose = async () => {
    setDialogValue({ name: '' })
    await fetchManufacturer()
    toggleOpen(false)
  }

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current.getData()
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().required('NOME obrigatório.')
      })
      await schema.validate(data, { abortEarly: false })

      const response = await api.post('/manufacturer/', {
        name: data.name,
        active: true
      })

      if (response.data.status === 'error') { throw new Error(response.data.message) }
      handleClose()

      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        await api.patch(`/manufacturer/${response.data._id}/logo`, formData)
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [file])

  const handleFile = useCallback((files) => {
    setFile(files[0])
  }, [])

  return (
    <>
      {/* <Autocomplete
noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='manufacturer'
                  className={classes.select}
                  options={manufactures}
                  value={manufacturer}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label='Selecione uma fabricante *' variant='outlined' />}
                  onChange={(_, newValue) => { setManufacturer(newValue) }}
                />
                 */}
      <Autocomplete
        noOptionsText='Nenhuma opção'
        id='manufacturer'
        className={classes.select}
        variant='outlined'
        margin='normal'
        fullWidth
        freeSolo
        value={manufacturer}
        onChange={(_, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              toggleOpen(true)
              setDialogValue({ name: newValue })
            })
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true)
            setDialogValue({ name: newValue.inputValue })
          } else {
            setManufacturer(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue !== '') {
            filtered.push({ inputValue: params.inputValue, name: `Adicionar "${params.inputValue}"` })
          }
          return filtered
        }}
        options={manufactures}
        getOptionLabel={(option) => {
          if (typeof option === 'string') { return option }
          if (option.inputValue) { return option.inputValue }
          return option.name
        }}
        renderOption={(option) => option.name}
        renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label='Selecione uma fabricante *' variant='outlined' />}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg' PaperProps={{ style: { padding: 20, justifyContent: 'center' } }}>
        <Form
          ref={formRef}
          className={classes.form}
          initialData={dialogValue}
        >
          <DialogTitle id='form-dialog-title'>
            <Typography variant='h4'>
              Nova fabricante
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Input
              variant='outlined'
              margin='normal'
              fullWidth
              id='name'
              label='NOME *'
              name='name'
              autoFocus
            />
            <Divider className={classes.divider} />
            <DropzoneArea
              dropzoneText='Arraste a logo da fabricante ou clique'
              acceptedFiles={['image/*']}
              showAlerts={false}
              filesLimit={1}
              maxFileSize={666000000}
              onChange={(files) => handleFile(files)}
            />
            <Divider className={classes.divider} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' color='secondary' className={classes.submit}>
              Cancelar
            </Button>
            <Button onClick={handleSubmit} fullWidth variant='contained' color='primary' className={classes.submit}>
              Criar fabricante
            </Button>
          </DialogActions>
          <Typography variant='caption'>
            Items marcados com * são obrigatórios
          </Typography>
        </Form>
      </Dialog>
    </>
  )
}

export default ManufacturerDialog

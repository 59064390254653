import React, { useState, useEffect } from 'react'
import { Grid, Typography, LinearProgress } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import OpenNavigation from '../../components/OpenNavigation'

import api, { publicBlobAPI } from '../../services/api'

import useStyles from './style'

const Post = () => {
  const params = useParams()
  const { _id } = params

  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState([])

  const [blob, setBlob] = useState(null)

  const creatBlob = async (fileName) => {
    try {
      const responseFile = await publicBlobAPI.get(fileName)
      const file = new Blob([responseFile.data], { type: responseFile.data.type })
      const blobURL = URL.createObjectURL(file)

      return blobURL
    } catch (err) {
      return false
    }
  }

  useEffect(async () => {
    const response = await api.get(`post/${_id}`)
    if (response.data.file) {
      const newBLob = await creatBlob(response.data.file.name)
      setBlob(newBLob)
    }
    setPost(response.data)
    setLoading(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <Grid item xs={12} sm={12} md={12} style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')", justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
        <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
          {post.title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        {blob
          ? <div style={{ background: `url('${blob}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'auto', backgroundPosition: 'center', width: '100%', height: 300 }} />
          : null}
        {loading
          ? <LinearProgress />
          : (
            <>
              <Typography variant='h5' gutterBottom>{post.cre}</Typography>
              <Typography variant='body' gutterBottom style={{ whiteSpace: 'pre-wrap' }}>{post.body}</Typography>
              <br />
              <br />
              <br />
              <Typography variant='caption' gutterBottom>Por - {post.createdBy.name}</Typography>
              <br />
              <Typography variant='caption' gutterBottom>{moment(post.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}</Typography>
            </>)}
      </Grid>
    </Grid>
  )
}

export default Post

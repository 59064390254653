import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api, { publicBlobAPI } from '../../../services/api'

import useStyles from './style'

const ConfigurationsView = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [configuration, setConfig] = useState([])
  const [header, setHeader] = useState()

  const fetchConfig = useCallback(async () => {
    const response = await api.get('/configuration')
    setConfig(response.data)
    if (response.data.homeImage) {
      const homeImage = await creatBlob(response.data.homeImage.name)
      setHeader(homeImage)
    }
  }, [])

  const handlerEdit = useCallback((configuration) => {
    navigate('/configuracoes/editar', { state: { configuration } })
  }, [])

  const creatBlob = async (fileName) => {
    const responseFile = await publicBlobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  useEffect(async () => {
    await fetchConfig()
  }, [])

  return (
    <Body title='Configurações'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5'>
                Configurações
              </Typography>
              <Tooltip title='Editar configurações' arrow placement='right'>
                <IconButton size='small' aria-label='editar-configuracoes' className={classes.plus} onClick={() => handlerEdit(configuration)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='configuracoes'>
              <Typography variant='h6'>Social & Home</Typography>
              {header
                ? (
                  <>
                    <ListItem>
                      <ListItemText primary='Header' />
                    </ListItem>
                    <ListItem>
                      <img src={header} className={classes.thumbnail} onClick={() => window.open(header)} />
                    </ListItem>
                  </>)
                : (
                  <ListItem>
                    <ListItemText primary='Sem header' />
                  </ListItem>)}
              <ListItem><ListItemText primary='Loja' secondary={configuration.store} /></ListItem>
              <ListItem><ListItemText primary='Whatsapp' secondary={configuration.whatsapp} /></ListItem>
              <ListItem><ListItemText primary='Twitter' secondary={configuration.twitter} /></ListItem>
              <ListItem><ListItemText primary='Facebook' secondary={configuration.facebook} /></ListItem>
              <ListItem><ListItemText primary='Instagram' secondary={configuration.instagram} /></ListItem>
              <ListItem><ListItemText primary='Youtube' secondary={configuration.youtube} /></ListItem>
              <ListItem><ListItemText primary='Linkedin' secondary={configuration.linkedin} /></ListItem>
              <ListItem><ListItemText primary='Behind scene' secondary={configuration.behind} /></ListItem>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Behind cene titulo' secondary={configuration.behindHead} /></ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Behind cene title' secondary={configuration.behindHeadEng} /></ListItem>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Behind cene corpo' secondary={configuration.behindBody} /></ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Behind cene body' secondary={configuration.behindBodyEng} /></ListItem>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              {/* <Typography variant="h6">Email</Typography>
              <ListItem><ListItemText primary='NOME' secondary={configuration.name} /></ListItem>
              <ListItem><ListItemText primary='Email' secondary={configuration.email} /></ListItem>
              <ListItem><ListItemText primary='Host' secondary={configuration.host} /></ListItem>
              <ListItem><ListItemText primary='Porta' secondary={configuration.port} /></ListItem>
              <ListItem><ListItemText primary='Usuário' secondary={configuration.user} /></ListItem>
              <ListItem><ListItemText primary='Senha' secondary={configuration.password} /></ListItem>
              <Divider className={classes.divider} /> */}
              <Typography variant='h6'>Sobre</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Sobre' secondary={configuration.about} style={{ Align: 'justify', whiteSpace: 'pre-wrap', height: 100, overflow: 'hidden' }} /></ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='About' secondary={configuration.aboutEng} style={{ Align: 'justify', whiteSpace: 'pre-wrap', height: 100, overflow: 'hidden' }} /></ListItem>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Equipe' secondary={configuration.staff} style={{ whiteSpace: 'pre-wrap', height: 100, overflow: 'hidden' }} /></ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Staff' secondary={configuration.staffEng} style={{ whiteSpace: 'pre-wrap', height: 100, overflow: 'hidden' }} /></ListItem>
                </Grid>
              </Grid>
              <Typography variant='h6'>Termos</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Termos de privacidade' secondary={configuration.privacy} style={{ whiteSpace: 'pre' }} /></ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Termos of privacy' secondary={configuration.privacyEng} style={{ whiteSpace: 'pre' }} /></ListItem>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Termos de uso' secondary={configuration.conditions} style={{ whiteSpace: 'pre' }} /></ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem><ListItemText primary='Terms of use' secondary={configuration.conditionsEng} style={{ whiteSpace: 'pre' }} /></ListItem>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography variant='h6'>Outros</Typography>
              <ListItem><ListItemText primary='Valores publicos' secondary={configuration.publicValues ? 'ATIVOs' : 'Inatívos'} /></ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText secondary={`Criado ${moment(configuration?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ConfigurationsView

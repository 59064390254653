import React, { useState, useEffect } from 'react'
import { Grid, Typography, LinearProgress } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import OpenNavigation from '../../components/OpenNavigation'
import api, { publicBlobAPI } from '../../services/api'
import RepresentativeInfo from './representative'

import useStyles from './style'

const Others = () => {
  const location = useLocation()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [allDealers, setAllDealers] = useState([])
  const [blobs, setBlobs] = useState([])

  const fetchDealersLogo = async (dealers) => {
    setLoading(true)
    const blobURL = {}
    for (const dealer of dealers) {
      console.log(dealer.name)
      console.log(dealer.logo.name)
      if (dealer.logo?.name) {
        try {
          blobURL[dealer.logo.name] = await creatBlob(dealer.logo.name)
        } catch (err) {
          console.log(err)
        }
      }
    }
    setBlobs(blobURL)
    setLoading(false)
  }

  const creatBlob = async (fileName) => {
    const responseFile = await publicBlobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  useEffect(async () => {
    let response
    const country = location?.state?.country
    console.log(country)
    switch (country) {
      case 'MEXICO':
        response = await api.get('representatives/mx')
        console.log(response.data)
        break
      case 'PARAGUAI':
        response = await api.get('representatives/pr')
        break
      case 'COLÔMBIA':
        response = await api.get('representatives/co')
        break
      default:
        response = await api.get('representatives/br')
        break
    }
    setAllDealers(response.data)
    await fetchDealersLogo(response.data)
    setLoading(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <Grid item xs={12} sm={12} md={12} style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')", justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
        <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
          {location?.state?.country}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        {loading
          ? <LinearProgress />
          : (
            <>{allDealers.length > 0
              ? (
                <>{allDealers.map((dealer, index) => (
                  <RepresentativeInfo blobs={blobs} key={index} dealer={dealer} />
                ))}
                </>)
              : (
                <Grid container xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    Nenhum dealer
                  </Grid>
                </Grid>)}
            </>)}
      </Grid>
    </Grid>
  )
}

export default Others

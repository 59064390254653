import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, IconButton, Button, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2, ExternalLink } from 'react-feather'
import moment from 'moment'

import { useAuth } from '../../../hooks'

import Body from '../../../components/Body'
import api, { blobAPI } from '../../../services/api'

import useStyles from './style'

const DownloadView = () => {
  const { user } = useAuth()

  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [download, setDownload] = useState([])
  const [loadingFile, setLoadingFile] = useState(false)

  const fetchDownload = useCallback(async () => {
    const response = await api.get(`/download/${location.state.download._id}`)
    setDownload(response.data)
  }, [])

  const creatBlob = async (fileName) => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const openImage = async (fileName, originalName) => {
    setLoadingFile(true)
    const blobURL = await creatBlob(fileName)
    forceDownload(blobURL, originalName)
    setLoadingFile(false)
  }

  const forceDownload = useCallback((blob, filename) => {
    const a = document.createElement('a')
    a.download = filename
    a.href = blob

    document.body.appendChild(a)
    a.click()
    a.remove()
  }, [])

  const handlerEdit = useCallback((download) => {
    navigate('/download/editar', { state: { download } })
  }, [])

  useEffect(async () => {
    await fetchDownload()
  }, [])

  return (
    <Body title='Download'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Download
              </Typography>
              {user.admin &&
                <Tooltip title='Editar download' arrow placement='right'>
                  <IconButton size='small' aria-label='editar-download' className={classes.plus} onClick={() => handlerEdit(download)}><Edit2 /></IconButton>
                </Tooltip>}
            </Grid>
            <List component='nav' aria-label='download'>
              <ListItem>
                <ListItemText primary='NOME' secondary={download.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Categoria' secondary={download.category?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Descrição' secondary={download.description} />
              </ListItem>
              {download.file
                ? (
                  <>
                    <ListItem>
                      <ListItemText primary='Arquivo' secondary={download.file.originalName} />
                    </ListItem>
                    <Button disabled={loadingFile} size='small' endIcon={<ExternalLink size={15} />} variant='outlined' onClick={() => openImage(download.file.name, download.file.originalName)}>
                      Baixar arquivo
                    </Button>
                  </>)
                : <ListItem><ListItemText primary='SEM ARQUIVO' /> </ListItem>}
              <ListItem>
                <ListItemText primary={!download.active && 'Fabricante desativada'} secondary={`Criado ${moment(download?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DownloadView

import React, { useMemo } from 'react'
import { Select, MenuItem } from '@material-ui/core'

export default ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const options = useMemo(() => {
    const options = new Set()
    options.add('...')
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })

    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <Select
      onChange={event => { setFilter(event.target.value || undefined) }}
      value={filterValue || '...'}
      style={{ width: '100%' }}
      size='small'
    >
      {options.map((option, index) =>
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      )}
    </Select>
  )
}

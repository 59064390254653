import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ChevronDown, ChevronUp } from 'react-feather'

import useStyles from './styles'

const SignInput = ({ visible = true, name, onClick, Icon, dark, nested, up }) => {
  const classes = useStyles()

  return (
    // make a white round border
    <div className={nested && classes.nested} style={{ background: dark ? '#696969' : (up ? '#f15b2baa' : '#f15b2b'), color: 'white', marginBottom: '4px', borderRadius: '4px' }}>
      {visible &&
        <ListItem button className={dark ? classes.dark : classes.listItem} onClick={onClick}>
          <ListItemIcon><Icon /></ListItemIcon>
          <ListItemText primary={name} />
          <ListItemIcon>{up ? <ChevronUp /> : <ChevronDown />}</ListItemIcon>
        </ListItem>}
    </div>
  )
}

export default SignInput

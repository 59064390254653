import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const RepresentativesIndex = () => {
  const classes = useStyles()
  const [representatives, setRepresentatives] = useState([])
  const navigate = useNavigate()

  const fetchRepresentatives = useCallback(async () => {
    const response = await api.get('representatives')
    setRepresentatives(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/representante/novo')
  }, [])

  const handlerView = useCallback((representative) => {
    navigate('/representante/', { state: { representative } })
  }, [])

  useEffect(async () => {
    await fetchRepresentatives()
  }, [])

  return (
    <Body title='Representantes'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Representantes
              </Typography>
              <Tooltip title='Novo representante' arrow placement='right'>
                <IconButton size='small' aria-label='novo-representante' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {representatives.length > 0
              ? (
                <List component='nav' aria-label='representantes'>
                  {representatives.map((representative, index) => (
                    <div key={index}>
                      <ListItem button onClick={() => handlerView(representative)}>
                        <ListItemText primary={`${representative.name} - ${representative.address}`} secondary={representative.active ? 'ATIVO' : 'INATIVO'} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <LinearProgress color='secondary' />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default RepresentativesIndex

import React, { useCallback, useState, useEffect, useRef } from 'react'
import { InputAdornment, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Paper, Divider, Box, Button, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab'
import { useParams } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { Info, Download } from 'react-feather'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import RequestBillTable from '../../../components/RequestBillTable'
import TimeIcon from '../../../components/TimeIcon'
import Body from '../../../components/Body'

import api, { blobAPI, fileAPI } from '../../../services/api'
import { currency } from '../../../services/atom'

import { useAuth } from '../../../hooks'

import useStyles from './style'

const BillView = () => {
  const optionsRef = useRef(null)
  const fileUpload = useRef(null)

  const classes = useStyles()
  const params = useParams()
  const { _id } = params
  const { user } = useAuth()

  const { enqueueSnackbar } = useSnackbar()

  const [loadingFile, setLoadingFile] = useState(false)

  const [bill, setBill] = useState([])
  const [loading, setLoading] = useState(true)

  const [openValueModal, setOpenValueModal] = useState(false)
  const [value, setValue] = useState('')

  const [sending, setSending] = useState(false)
  const [obs, setObs] = useState('')

  const [file, setFile] = useState(null)

  const handleFile = useCallback((files) => {
    setFile(files)
  }, [])

  const fetchBill = useCallback(async () => {
    setLoading(true)
    const response = await api.get(`/bill/${_id}`)
    setBill(response.data)
    setLoading(false)
  }, [])

  const handleOpenValueModal = useCallback(async () => {
    if (sending) { return }
    setOpenValueModal(true)
  }, [sending])

  const creatBlob = async (fileName) => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const openFile = async (name) => {
    setLoadingFile(true)
    const blobURL = await creatBlob(name)
    setLoadingFile(false)
    window.open(blobURL)
  }

  const handleNewValue = useCallback(async () => {
    setSending(true)
    try {
      if (value === '') {
        enqueueSnackbar('Preencha o valor', { variant: 'error' })
        return
      }
      if (obs === '') {
        enqueueSnackbar('Preencha a observação', { variant: 'error' })
        return
      }

      const formData = new FormData()
      if (file[0]) {
        formData.append('file', file[0])
      }
      formData.append('_id', _id)
      formData.append('observation', obs)
      formData.append('value', value)

      await fileAPI.patch('/bill/add/payment', formData)
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
      setOpenValueModal(false)
      setSending(false)
    }
    await fetchBill()
    setOpenValueModal(false)
    setSending(false)
  }, [value, obs, _id, file])

  const handleModalValueClose = useCallback(async () => {
    setOpenValueModal(false)
    setSending(false)
  }, [])

  useEffect(async () => {
    await fetchBill()
  }, [])

  return (
    <Body title='Fatura'>
      <TextField id='file' name='file' inputProps={{ ref: fileUpload, type: 'file', multiple: true }} style={{ display: 'none' }} />
      <Dialog
        open={openValueModal}
        aria-labelledby='dialogo-orcamento'
        aria-describedby='dialogo-orcamento'
        onClose={handleModalValueClose}
        fullWidth maxWidth='lg'
      >
        <DialogTitle id='titulo-dialogo-orcamento'>
          Enviar pagamento - Devedor: {currency(bill.value - bill.paid)}
        </DialogTitle>
        <DialogContent>
          <DropzoneArea
            dropzoneText='Arraste o arquivo ou clique'
            showAlerts={false}
            filesLimit={1}
            maxFileSize={666000000}
            onChange={(files) => handleFile(files)}
          />
          <TextField
            placeholder='Novo valor *'
            onChange={(e) => { setValue(e.target.value) }}
            value={value}
            style={{ marginTop: '8px' }}
            fullWidth
            InputProps={{ startAdornment: <InputAdornment position='start'>R$</InputAdornment> }}
            type='number'
            variant='outlined'
          />
          <TextField
            placeholder='Observação *'
            onChange={(e) => { setObs(e.target.value) }}
            className={classes.select}
            value={obs}
            fullWidth
            variant='outlined'
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleNewValue}
            variant='contained'
            disableElevation
            color='primary'
            size='small'
          >
            Enviar pagamento
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            {loading
              ? <LinearProgress />
              : (
                <>
                  <Grid container direction='row'>
                    <Typography variant='h5'>
                      FATURA - {bill.month.toUpperCase()}
                      <Typography variant='h6'>
                        {bill.dealer?.name.toUpperCase()}
                      </Typography>
                    </Typography>
                    <Box flexGrow={1} />
                    {user.admin &&
                      <>
                        <Button
                          aria-label='menu'
                          onClick={handleOpenValueModal}
                          ref={optionsRef}
                          endIcon={<Info size={15} />}
                          variant='contained' color='secondary'
                          style={{ marginRight: 10 }}
                        >
                          Enviar pagamento
                        </Button>
                      </>}
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} style={{ marginBottom: '16px', marginTop: '32px' }}>
                      <Paper
                        style={{
                          padding: '24px',
                          transitionProperty: 'box-shadow',
                          boxShadow: 'none',
                          backgroundImage: 'none',
                          borderRadius: '32px',
                          backgroundColor: '#f15b2b',
                          color: 'rgb(255, 255, 255)'
                        }}
                      >
                        <Typography variant='h3'>
                          {currency(bill.value)}
                        </Typography>
                        <Typography variant='h5' style={{ marginTop: 1, color: '#ffffff69' }}>
                          TOTAL
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={6} style={{ marginBottom: '16px', marginTop: '32px' }}>
                      <Paper
                        style={{
                          padding: '24px',
                          transitionProperty: 'box-shadow',
                          boxShadow: 'none',
                          backgroundImage: 'none',
                          borderRadius: '32px',
                          backgroundColor: (bill.value > bill.paid) ? 'red' : 'grey',
                          color: 'rgb(255, 255, 255)'
                        }}
                      >
                        <Typography variant='h3'>
                          {currency(bill.value - bill.paid)}
                        </Typography>
                        <Typography variant='h5' sx={{ mt: 1, color: '#ffffff69' }}>
                          DEVEDOR
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Divider style={{ margin: '16px' }} />
                  {bill?.payment?.length > 0
                    ? (
                      <Timeline>
                        {bill?.payment?.map((payment, index) => (
                          <TimelineItem key={index}>
                            <TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
                              <Tooltip title={moment(payment.createdAt).format('DD/MM/YYYY HH:mm')}>
                                <Typography variant='caption'>
                                  {moment(payment.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}
                                </Typography>
                              </Tooltip>
                            </TimelineOppositeContent>

                            <TimelineSeparator>
                              <TimelineDot color='primary'>
                                <TimeIcon status='paid' />
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>

                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Typography variant='body1'>
                                {currency(payment.value)}
                              </Typography>
                              {payment.file &&
                                <Typography>
                                  <Button disabled={loadingFile} size='small' onClick={() => openFile(payment.file?.name)}>
                                    <Typography variant='caption'>
                                      {payment.file?.originalName}
                                    </Typography>
                                    <Download size={15} />
                                  </Button>
                                </Typography>}
                              {payment.observation &&
                                <Typography variant='caption'>
                                  Obs: {payment.observation}
                                </Typography>}
                              <Typography variant='subtitle2'>
                                {payment.createdBy?.name}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>)
                    : <Typography variant='h6' style={{ textAlign: 'center' }}>SEM REGISTRO DE PAGAMENTO</Typography>}
                  <Divider style={{ margin: '16px' }} />
                  <RequestBillTable requests={bill?.requests} bill={bill} />
                </>)}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default BillView

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Box, Typography, LinearProgress, Button, Paper } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import TableOpenRequests from '../../../components/TableOpenRequests'
import BillTable from '../../../components/BillTable'
import Body from '../../../components/Body'

import { currency } from '../../../services/atom'
import api from '../../../services/api'

const PedidoIndex = ({ dealer }) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [bills, setBills] = useState([])

  const [total, setTotal] = useState(0)
  const [devendo, setDevendo] = useState(0)

  const fetchBill = useCallback(async () => {
    setLoading(true)
    const response = await api.get(`/bills/${dealer._id}/open`)
    setBills(response.data)

    const total = response.data.reduce((acc, bill) => acc + bill.value, 0)
    const pago = response.data.reduce((acc, bill) => acc + bill.paid, 0)
    setTotal(total)
    setDevendo(total - pago)

    setLoading(false)
  }, [])

  useEffect(() => {
    fetchBill()
  }, [])

  return (
    <Body title='FATURAS PARCIAIS'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container direction='row'>
            <Typography variant='h5' component='h2'>
              FATURAS PARCIAIS
            </Typography>
            <Box flexGrow={1} />
            <Button variant='contained' color='primary' style={{ marginLeft: '8px' }} onClick={() => navigate('/financeiro/todos')}>Todas</Button>
          </Grid>
          {bills.length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper
                  style={{
                    padding: '24px',
                    transitionProperty: 'box-shadow',
                    boxShadow: 'none',
                    backgroundImage: 'none',
                    borderRadius: '32px',
                    backgroundColor: '#f15b2b',
                    color: 'rgb(255, 255, 255)',
                    marginTop: '32px'
                  }}
                >
                  <Typography variant='h3'>
                    {currency(total)}
                  </Typography>
                  <Typography variant='h5' style={{ marginTop: 1, color: '#ffffff69' }}>
                    TOTAL FATURADO
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  style={{
                    padding: '24px',
                    transitionProperty: 'box-shadow',
                    boxShadow: 'none',
                    backgroundImage: 'none',
                    borderRadius: '32px',
                    backgroundColor: (devendo > 0) ? 'red' : 'grey',
                    color: 'rgb(255, 255, 255)',
                    marginTop: '32px'
                  }}
                >
                  <Typography variant='h3'>
                    {currency(devendo)}
                  </Typography>
                  <Typography variant='h5' sx={{ mt: 1, color: '#ffffff69' }}>
                    TOTAL DEVENDO
                  </Typography>
                </Paper>
              </Grid>
            </Grid>}
          {loading ? <LinearProgress /> : <BillTable bills={bills} />}
          {dealer?._id && <TableOpenRequests dealerId={dealer._id} />}
        </Grid>
      </Grid>
    </Body>
  )
}

export default PedidoIndex

import React, { useMemo, useCallback } from 'react'
import { Typography, Table as MaUTable, TableBody, TableCell, TableHead, TableRow, IconButton, Chip } from '@material-ui/core'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table'
import { ArrowDown, ArrowUp, Search, Layout, Users } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import SelectColumnFilter from '../SelectColumnFilter'
import GlobalFilterInput from '../GlobalFilterInput'
import { currency } from '../../services/atom'

const StagesTable = ({ stages }) => {
  const navigate = useNavigate()

  const columns = useMemo(() => [{
    Header: 'Fabricante',
    accessor: (originalRow) => originalRow.manufacturer?.name,
    Filter: SelectColumnFilter,
    filter: 'includes'
  }, {
    Header: 'Modelo',
    accessor: (originalRow) => originalRow.model?.name,
    Filter: SelectColumnFilter,
    filter: 'includes'
  }, {
    Header: 'Ano',
    accessor: (originalRow) => originalRow.year?.year,
    Filter: SelectColumnFilter,
    filter: 'includes'
  }, {
    Header: 'Motor',
    accessor: (originalRow) => originalRow.motor?.name,
    Filter: SelectColumnFilter,
    filter: 'includes'
  }, {
    Header: 'Stage',
    accessor: (originalRow) => originalRow.name,
    Filter: SelectColumnFilter,
    filter: 'includes'
  }, {
    Header: 'Tipo',
    accessor: (originalRow) => originalRow.stageType.name,
    Filter: SelectColumnFilter,
    filter: 'includes'
  }, {
    Header: 'Tabela',
    accessor: (originalRow) => originalRow.origin === 'table1' ? 'Nacional' : 'Importado',
    Filter: SelectColumnFilter,
    filter: 'includes'
  }, {
    Header: 'ATIVO',
    accessor: (originalRow) =>
      <>
        <Chip size='small' label={<Layout size={15} />} color={originalRow.activeSite ? 'primary' : 'secondary'} style={{ margin: 0.5 }} />
        <Chip size='small' label={<Users size={15} />} color={originalRow.activeDealer ? 'primary' : 'secondary'} style={{ margin: 0.5 }} />
      </>
  }, {
    Header: 'R$',
    accessor: (originalRow) => currency(originalRow.price)
  }], [])

  const filterTypes = useMemo(() => ({
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
      })
    }
  }), [])

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const { getTableProps, headerGroups, prepareRow, rows, state, visibleColumns, preGlobalFilteredRows, setGlobalFilter } = useTable({ columns, data: stages, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [{
        id: 'pedido',
        Cell: ({ row }) => (<IconButton size='small' onClick={() => handlerView({ stage: row.original })}><Search /></IconButton>)
      }, ...columns])
    }
  )

  const handlerView = useCallback(({ stage }) => {
    navigate('/stage/', { state: { stage } })
  }, [])

  return (
    <>
      {stages.length > 0
        ? (
          <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={visibleColumns.length} style={{ textAlign: 'left' }}>
                  <GlobalFilterInput preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                </TableCell>
              </TableRow>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                      {column.render('Header')}
                      {column.isSorted ? column.isSortedDesc ? <ArrowDown size={15} /> : <ArrowUp size={15} /> : ''}
                      <>{column.canFilter ? column.render('Filter') : null}</>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <TableRow key={index} hover {...row.getRowProps()}>
                    {row.cells.map((cell, index) => <TableCell key={index} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>)}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>)
        : <Typography variant='h6' color='textSecondary' style={{ alignSelf: 'center' }}>Nenhum pedido no momento</Typography>}
    </ >
  )
}

export default StagesTable

import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Switch, FormControlLabel } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const FuelForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [active, setActive] = useState(true)

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`fuel/${location.state?.fuel._id}`, {
          name: data.name,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/combustivel', { state: { fuel: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/fuel/', {
          name: data.name,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/combustivel/', { state: { fuel: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [active])

  useEffect(() => {
    if (location.state) {
      setActive(location.state.fuel.active)
    }
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Nova'} combustível`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Nova'} combustível
            </Typography>
            <Form
              ref={formRef}
              className={classes.form}
              onSubmit={handleNew}
              initialData={location.state?.fuel}
            >
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='name'
                label='NOME do combustível *'
                name='name'
                autoFocus
              />

              <FormControlLabel
                label='ATIVO?'
                control={
                  <Switch
                    checked={active}
                    onChange={handleSwitch}
                    name='active'
                    color='primary'
                  />
                }
              />
              <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                {location.state ? 'Atualizar' : 'Criar'} combustível
              </Button>
            </Form>
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default FuelForm

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const PostsIndex = () => {
  const classes = useStyles()
  const [posts, setPosts] = useState([])
  const navigate = useNavigate()

  const fetchPosts = useCallback(async () => {
    const response = await api.get('posts')
    setPosts(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/novidade/nova')
  }, [])

  const handlerView = useCallback((post) => {
    navigate('/novidade/', { state: { post } })
  }, [])

  useEffect(async () => {
    await fetchPosts()
  }, [])

  return (
    <Body title='Novidades'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Novidades
              </Typography>
              <Tooltip title='Novo post' arrow placement='right'>
                <IconButton size='small' aria-label='novo-post' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {posts.length > 0
              ? (
                <List component='nav' aria-label='posts'>
                  {posts.map((post, index) => (
                    <div key={index}>
                      <ListItem button onClick={() => handlerView(post)}>
                        <ListItemText primary={`${post.service ? 'SERVIÇO/STAGE' : 'POSTAGEM'} - ${post.title.toUpperCase()}`} secondary={post.active ? 'ATIVO' : 'INATIVO'} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <LinearProgress color='secondary' />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default PostsIndex

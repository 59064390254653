import React, { useState, useEffect } from 'react'
import { ListItem, Button, Popover, List } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import { Us, Br, Es } from 'react-flags-select'
import i18next from 'i18next'

const languageMap = {
  'pt-BR': { label: 'Portugues', dir: 'ltr', flag: 'br', active: true },
  // es: { label: 'Espanhou', dir: 'ltr', flag: 'es', active: true },
  us: { label: 'Inglês', dir: 'ltr', flag: 'us', active: true }
}

const Flag = ({ flag }) => {
  switch (flag) {
    case 'br':
      return <Br />
    case 'es':
      return <Es />
    case 'us':
      return <Us />
    default:
      return <Br />
  }
}

const LanguageSelect = ({ style }) => {
  const selected = window.localStorage.getItem('i18nextLng') || 'pt-BR'

  const [menuAnchor, setMenuAnchor] = useState(null)

  useEffect(() => {
    document.body.dir = languageMap[selected].dir
  }, [menuAnchor, selected])

  return (
    <div style={style}>
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        <Flag flag={languageMap[selected].flag} />
        <ArrowDropDown fontSize='small' />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div>
          <List>
            {Object.keys(languageMap)?.map(item => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item)
                  setMenuAnchor(null)
                }}
              >
                <Flag flag={languageMap[item].flag} />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  )
}

export default LanguageSelect

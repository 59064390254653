import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const AdminsIndex = () => {
  const classes = useStyles()
  const [admins, setAdmins] = useState([])
  const navigate = useNavigate()

  const fetchAdmin = useCallback(async () => {
    const response = await api.get('users/admin')
    setAdmins(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/administrador/novo')
  }, [])

  const handlerView = useCallback((admin) => {
    navigate('/administrador/inspecionar', { state: { admin } })
  }, [])

  useEffect(async () => {
    await fetchAdmin()
  }, [])

  return (
    <Body title='Administradores'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Administradores
              </Typography>
              <Tooltip title='Novo administrador' arrow placement='right'>
                <IconButton size='small' aria-label='novo-administrador' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {admins.length > 0
              ? (
                <List component='nav' aria-label='administradores'>
                  {admins.map(admin => (
                    <div key={admin._id}>
                      <ListItem button onClick={() => handlerView(admin)}>
                        <ListItemText primary={admin.name} secondary={admin.active ? 'ATIVO' : 'INATIVO'} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <LinearProgress color='secondary' />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default AdminsIndex

import React, { createContext, useState, useContext, useCallback } from 'react'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import themeObject from '../theme'

const ThemeContext = createContext({})

export const ThemeProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const thisTheme = themeObject
    return { theme: createMuiTheme(thisTheme), dark: false }
  })

  const handleDark = useCallback(() => {
    const thisTheme = themeObject
    thisTheme.palette.type = 'light'
    setTheme(thisTheme, false)
  }, [])

  const setTheme = useCallback((theme, dark) => {
    setData({
      theme: createMuiTheme(theme),
      dark
    })
  }, [])

  useCallback(() => {
    const systemDark = useMediaQuery('(prefers-color-scheme: dark)')
    window.localStorage.setItem('#perfortech:systemDark', systemDark)
  }, [data])

  return (
    <ThemeContext.Provider value={{ theme: data.theme, handleDark, dark: data.dark }}>
      <MaterialThemeProvider theme={data.theme}>
        {children}
      </MaterialThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) { throw new Error('useTheme  só pode ser usando dentro do ThemeProvider') }
  return context
}

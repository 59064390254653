import React, { useCallback, useState, useEffect } from 'react'
import { Grid, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import api from '../../../services/api'

import useStyles from './style'

const UsersIndex = ({ dealer }) => {
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const navigate = useNavigate()

  const fetchUsers = useCallback(async () => {
    const response = await api.get(`/users/${dealer._id}`)
    setUsers(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/admin/dealer/usuario/novo', { state: { dealer } })
  }, [])

  const handlerView = useCallback((user) => {
    navigate('/admin/dealer/usuario', { state: { dealer, user } })
  }, [])

  useEffect(async () => {
    await fetchUsers()
  }, [])

  return (
    <>
      <Grid container direction='row'>
        <Typography variant='h5' component='h2'>
          Usuários - {dealer.name}
        </Typography>
        <Tooltip title='Novo usuário' arrow placement='right'>
          <IconButton size='small' aria-label='novo-usuário' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
        </Tooltip>
      </Grid>
      {users.length > 0
        ? (
          <List component='nav' aria-label='usuarios'>
            {users.map(user => (
              <div key={user._id}>
                <ListItem button onClick={() => handlerView(user)}>
                  <ListItemText primary={user.name} secondary={user.active ? 'ATIVO' : 'INATIVO'} />
                </ListItem>
                <Divider className={classes.divider} />
              </div>
            ))}
          </List>)
        : <LinearProgress color='secondary' />}
    </>
  )
}

export default UsersIndex

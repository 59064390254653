import React, { useEffect, useState, useCallback } from 'react'
import { Grid, LinearProgress, Paper, Typography } from '@material-ui/core'

import { useAuth } from '../../hooks'
import Body from '../../components/Body'
import RequestTable from '../../components/RequestTable'

import socket from '../../services/socket'
import api from '../../services/api'

import useStyles from './style'

const Home = () => {
  const classes = useStyles()
  const { user } = useAuth()

  const [loading, setLoading] = useState(true)
  const [requests, setRequests] = useState([])
  const [requestsOut, setRequestsOut] = useState([])
  const [reqCanceled, setReqCanceled] = useState([])

  const fetchResquest = useCallback(async () => {
    setLoading(true)
    if (user.admin) {
      const response = await api.get('requests/assigned')
      setRequests(response.data)

      const out = await api.get('requests/notassigned')
      setRequestsOut(out.data)

      const canceled = await api.get('requests/canceled/awaiting')
      setReqCanceled(canceled.data)
    } else {
      const response = await api.get('requests/dealer')
      setRequests(response.data)
    }

    setLoading(false)
  }, [])

  useEffect(async () => {
    await fetchResquest()
    socket.on('status', async () => {
      await fetchResquest()
    })
  }, [])

  return (
    <Body title='Bem vindo!'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {/* <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography align='center' color='textSecondary'>{message} {name}, bem vindo a <b>Prodigy</b>.</Typography>
            {loading && <LinearProgress />}
          </Paper> */}
          {user.admin && reqCanceled.length > 0 &&
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography color='textSecondary' variant='h6'>
                {'Aguardando confirmação'.toUpperCase()}
              </Typography>
              {loading && <LinearProgress />}
              <RequestTable requests={reqCanceled} />
            </Paper>}
          {user.admin && requests.length > 0 &&
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography color='textSecondary' variant='h6'>
                {'Seus pedidos'.toUpperCase()}
              </Typography>
              {loading && <LinearProgress />}
              <RequestTable requests={requests} />
            </Paper>}
          {user.admin && requestsOut.length > 0 &&
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography color='textSecondary' variant='h6'>
                {'Pedidos sem responsável'.toUpperCase()}
              </Typography>
              {loading && <LinearProgress />}
              <RequestTable requests={requestsOut} />
            </Paper>}
        </Grid>
      </Grid>
    </Body>
  )
}

export default Home

import React from 'react'

import { CssBaseline } from '@material-ui/core'
import { AppProvider } from './hooks'
import moment from 'moment'
import 'moment/locale/pt-br'

import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
moment.locale('pt-BR')

const App = () => (
  <BrowserRouter>
    <AppProvider>
      <CssBaseline />
      <Routes />
    </AppProvider>
  </BrowserRouter>
)

export default App

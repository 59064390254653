import React from 'react'

import { AuthProvider, useAuth } from './Auth'
import { ThemeProvider, useTheme } from './Theme'
import NotificationProvider from './Notification'

const AppProvider = ({ children }) => {
  return (
    <ThemeProvider>
      <NotificationProvider>
        <AuthProvider>
          {children}
        </AuthProvider>
      </NotificationProvider>
    </ThemeProvider>
  )
}

export {
  AppProvider,
  useAuth,
  useTheme
}

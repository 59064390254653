import React, { useMemo, useCallback } from 'react'
import { Typography, Table as MaUTable, TableBody, TableCell, TableHead, TableRow, IconButton, Chip } from '@material-ui/core'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table'
import { ArrowDown, ArrowUp, Search } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import GlobalFilterInput from '../GlobalFilterInput'
import { currency } from '../../services/atom'

const AddTable = ({ additionals }) => {
  const navigate = useNavigate()

  const columns = useMemo(() => [{
    Header: 'NOME',
    accessor: (originalRow) => originalRow.name
  }, {
    Header: 'R$',
    accessor: (originalRow) => currency(originalRow.price)
  }, {
    Header: 'ATIVO',
    accessor: (originalRow) => <Chip size='small' label={originalRow.active ? 'ATIVO' : 'INATIVO'} color={originalRow.active ? 'primary' : 'secondary'} style={{ margin: 0.5 }} />
  }], [])

  const filterTypes = useMemo(() => ({
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
      })
    }
  }), [])

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const { getTableProps, headerGroups, prepareRow, rows, state, visibleColumns, preGlobalFilteredRows, setGlobalFilter } = useTable({ columns, data: additionals, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [{
        id: 'pedido',
        Cell: ({ row }) => (<IconButton size='small' onClick={() => handlerView({ additional: row.original })}><Search /></IconButton>)
      }, ...columns])
    }
  )

  const handlerView = useCallback(({ additional }) => {
    navigate('/adicional/', { state: { additional } })
  }, [])

  return (
    <>
      {additionals.length > 0
        ? (
          <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={visibleColumns.length} style={{ textAlign: 'left' }}>
                  <GlobalFilterInput preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                </TableCell>
              </TableRow>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                      {column.render('Header')}
                      {column.isSorted ? column.isSortedDesc ? <ArrowDown size={15} /> : <ArrowUp size={15} /> : ''}
                      <>{column.canFilter ? column.render('Filter') : null}</>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <TableRow hover {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => <TableCell {...cell.getCellProps()} key={index}>{cell.render('Cell')}</TableCell>)}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>)
        : <Typography variant='h6' color='textSecondary' style={{ alignSelf: 'center' }}>Nenhum pedido no momento</Typography>}
    </ >
  )
}

export default AddTable

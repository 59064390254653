import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const YearView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [year, setYear] = useState([])

  const fetchYear = useCallback(async () => {
    const response = await api.get(`/year/${location.state.year._id}`)
    setYear(response.data)
  }, [])

  const handlerEdit = useCallback((year) => {
    navigate('/ano/editar', { state: { year } })
  }, [])

  useEffect(async () => {
    await fetchYear()
  }, [])

  return (
    <Body title='Ano'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Ano
              </Typography>
              <Tooltip title='Editar ano' arrow placement='right'>
                <IconButton size='small' aria-label='editar-ano' className={classes.plus} onClick={() => handlerEdit(year)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='ano'>
              <ListItem>
                <ListItemText primary='Ano' secondary={year?.year} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Fabricante' secondary={year.manufacturer?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Modelo' secondary={year.model?.name} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={!year.active && 'Ano desativada'} secondary={`Criado ${moment(year?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default YearView

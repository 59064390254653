/* eslint-disable array-callback-return */
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, FormControlLabel, RadioGroup, Radio, Box, Checkbox, FormGroup, LinearProgress } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { useAuth } from '../../../hooks'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { currency, getValidationsErros, validateChassi, validarPlaca } from '../../../services/atom'
import api, { fileAPI } from '../../../services/api'

import useStyles from './style'

const RequestForm = () => {
  const formRef = useRef(null)
  const modelRef = useRef(null)
  const yearRef = useRef(null)
  const motorRef = useRef(null)
  const stageRef = useRef(null)

  const { user } = useAuth()
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [checkDeclare, setCheckDeclare] = useState(false)

  const [total, setTotal] = useState(0)
  const [finalTotal, setFinalTotal] = useState(0)

  const [sending, setSending] = useState(false)

  const [file, setFile] = useState()

  const [transmission, setTransmission] = useState('AUTO')

  const [discount, setDiscount] = useState()

  const [manufacturer, setManufacturer] = useState()
  const [manufactures, setManufactures] = useState([])

  const [useFuel, setUseFuel] = useState('')
  const [fuel, setFuel] = useState('')
  const [fuels, setFuels] = useState([])

  const [reading, setReading] = useState()
  const [readings, setReadings] = useState([])

  const [dealer, setDealer] = useState(null)
  const [dealers, setDealers] = useState([])

  const [loading, setLoading] = useState(false)

  const [loadModel, setLoadModel] = useState(false)
  const [model, setModel] = useState()
  const [models, setModels] = useState([])

  const [loadYear, setLoadYear] = useState(false)
  const [year, setYear] = useState()
  const [years, setYears] = useState([])

  const [loadMotor, setLoadMotor] = useState(false)
  const [motor, setMotor] = useState()
  const [motors, setMotors] = useState([])

  const [loadStage, setLoadStage] = useState(false)
  const [stage, setStage] = useState()
  const [stages, setStages] = useState([])

  const [additionals, setAdditionals] = useState([])

  const [deadline, setDeadline] = useState()

  const handleNew = useCallback(async (data) => {
    try {
      // UPDATE
      setSending(true)
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          manufacturer: Yup.string().required('Fabricante obrigatório'),
          model: Yup.string().required('Modelo obrigatório'),
          year: Yup.string().required('Ano obrigatório'),
          motor: Yup.string().required('Motor obrigatório'),
          stage: Yup.string().required('Stage obrigatório'),
          readingEquipment: Yup.string().required('Equipamento obrigatório'),
          observation: Yup.string().required('Observação obrigatório').min(10, 'Observação deve ter pelo menos 10 characteres').max(20, 'Observação deve ter no máximo 20 characteres'),
          chassi: Yup.string()
            .required('Chassi obrigatório.')
            .test('is-valid', 'Chassi inválido', chassi => validateChassi(chassi)),
          plate: Yup.string().required('Placa obrigatório')
            .test('is-valid', 'Placa inválida', plate => validarPlaca(plate))
        })

        await schema.validate(data, { abortEarly: false })

        if (dealer === null) { throw new Error('Selecione o dealer') }
        if (file.length <= 0) { throw new Error('Adicione pelomenos um arquivo no pedido') }
        if (fuel === '') { throw new Error('Selecione o combustível') }
        if (useFuel === '') { throw new Error('Selecione o uso do combustível') }
        if (deadline == null) { throw new Error('Selecione prazo') }

        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`request/${location.state?.request._id}`, {
          originalFuel: fuel,
          useFuel,
          dealer: dealer._id,
          readingEquipment: reading._id,

          stagePrice: stage.price,
          dealerDiscount: discount,

          transmission,
          finalValue: finalTotal,
          manufacturer: manufacturer._id,
          model: model._id,
          year: year._id,
          motor: motor._id,
          stage: stage._id,

          owner: data.owner,
          chassi: data.chassi,
          plate: data.plate,
          observation: data.observation,

          additionals,
          declared: checkDeclare,
          deadline
        })
        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (file.length > 0) {
          const formData = new FormData()
          for (let i = 0; i < file.length; i++) {
            formData.append('file', file[i])
          }
          await fileAPI.patch(`/request/${response.data._id}/original`, formData)
        }
        navigate(`/pedido/${response.data.protocol}`, { state: { request: response.data } })
        setSending(false)
      } else {
        // CREATE
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          manufacturer: Yup.string().required('Fabricante obrigatório'),
          model: Yup.string().required('Modelo obrigatório'),
          year: Yup.string().required('Ano obrigatório'),
          motor: Yup.string().required('Motor obrigatório'),
          stage: Yup.string().required('Stage obrigatório'),
          readingEquipment: Yup.string().required('Equipamento obrigatório'),
          observation: Yup.string().required('Observação obrigatório').min(20, 'Observação deve ter pelo menos 20 characteres'),
          chassi: Yup.string()
            .required('Chassi obrigatório.')
            .test('is-valid', 'Chassi inválido', chassi => validateChassi(chassi)),
          plate: Yup.string().required('Placa obrigatório')
            .test('is-valid', 'Placa inválida', plate => validarPlaca(plate))
        })

        await schema.validate(data, { abortEarly: false })

        if (dealer === null) { throw new Error('Selecione o dealer') }
        if (file.length <= 0) { throw new Error('Adicione pelomenos um arquivo no pedido') }
        if (fuel === '') { throw new Error('Selecione o combustível') }
        if (useFuel === '') { throw new Error('Selecione o uso do combustível') }
        if (deadline == null) { throw new Error('Selecione prazo') }

        const response = await api.post('request', {
          originalFuel: fuel,
          useFuel,
          dealer: dealer._id,
          readingEquipment: reading._id,

          stagePrice: stage.price,
          dealerDiscount: discount,

          transmission,
          finalValue: finalTotal,
          manufacturer: manufacturer._id,
          model: model._id,
          year: year._id,
          motor: motor._id,
          stage: stage._id,

          owner: data.owner,
          chassi: data.chassi,
          plate: data.plate,
          observation: data.observation,

          additionals,
          declared: checkDeclare,
          deadline
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        if (file.length > 0) {
          const formData = new FormData()
          for (let i = 0; i < file.length; i++) {
            formData.append('file', file[i])
          }
          await fileAPI.patch(`/request/${response.data._id}/original`, formData)
        }
        navigate(`/pedido/${response.data.protocol}`, { state: { request: response.data } })
        setSending(false)
      }
    } catch (err) {
      setSending(false)
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [file, total, fuel, useFuel, discount, transmission, reading, dealer, manufacturer, model, year, motor, stage, additionals, deadline, checkDeclare, finalTotal])

  const handleFile = useCallback((files) => {
    setFile(files)
  }, [])

  const fetchModels = useCallback(async () => {
    setLoading(true)
    setLoadModel(false)
    if (manufacturer?._id) {
      const response = await api.get(`models/manufacturer/${manufacturer?._id}`)
      setModels(response.data)
      if (response.data.length > 0) {
        setLoadModel(true)
        modelRef.current.children[0].children[0].focus()
      }
    }
    setLoading(false)
  }, [manufacturer, modelRef])

  const fetchManufactures = useCallback(async () => {
    setLoading(true)
    const response = await api.get('manufactures')
    setManufactures(response.data)
    setLoading(false)
  }, [])

  const fetchFuels = useCallback(async () => {
    setLoading(true)
    const response = await api.get('fuels')
    setFuels(response.data)
    setLoading(false)
  }, [])

  const fetchReadings = useCallback(async () => {
    setLoading(true)
    const response = await api.get('readings')
    setReadings(response.data)
    setLoading(false)
  }, [])

  const fetchDealers = useCallback(async () => {
    setLoading(true)
    if (user.admin) {
      const response = await api.get('dealers')
      setDealers(response.data)
    } else {
      setDealer(user.dealer)
    }
    setLoading(false)
  }, [user])

  const fetchYears = useCallback(async () => {
    setLoading(true)
    setLoadYear(false)
    if (model?._id) {
      const response = await api.get(`years/model/${model?._id}`)
      setYears(response.data)
      if (response.data.length > 0) {
        setLoadYear(true)
        yearRef.current.children[0].children[0].focus()
      }
    }
    setLoading(false)
  }, [model, yearRef])

  const fetchMotor = useCallback(async () => {
    setLoading(true)
    setLoadMotor(false)
    if (year?._id) {
      const response = await api.get(`motors/year/${year?._id}`)
      setMotors(response.data)
      if (response.data.length > 0) {
        setLoadMotor(true)
        motorRef.current.children[0].children[0].focus()
      }
    }
    setLoading(false)
  }, [year, motorRef])

  const fetchStage = useCallback(async () => {
    setLoading(true)
    setLoadStage(false)
    if (motor?._id) {
      const response = await api.get(`stage/motor/${motor?._id}/active`)
      setStages(response.data)
      if (response.data.length > 0) {
        setLoadStage(true)
        stageRef.current.children[0].children[0].focus()
      }
    }
    setLoading(false)
  }, [motor, stageRef])

  const handleAdditional = (event, index) => {
    const newAdd = [...stage.additionals]
    newAdd[index].checked = event.target.checked
    setAdditionals(newAdd)
    let newTotal = total
    additionals.map((additional) => {
      if (additional.checked) {
        newTotal += additional.price
      }
    })
    setFinalTotal(newTotal)
  }

  const handleDiscount = useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`discount/dealer/${dealer._id}/${stage.stageType._id}`)
      if (response.data[stage.origin] > 0) {
        setDiscount(response.data[stage.origin])
        return response.data[stage.origin]
      } else {
        setDiscount(null)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      return false
    }
  }, [dealer, stage])

  useEffect(async () => {
    if (stage?.additionals) {
      const newAdd = [...stage?.additionals]
      setAdditionals(newAdd)
    }
  }, [stage])

  useEffect(async () => {
    await fetchStage()
  }, [motor])

  useEffect(async () => {
    await fetchMotor()
  }, [year])

  useEffect(async () => {
    await fetchYears()
  }, [model])

  useEffect(async () => {
    await fetchModels()
  }, [manufacturer])

  useEffect(async () => {
    let discount
    if (stage?._id) {
      discount = await handleDiscount()
    }
    if (!!discount && discount > 0) {
      const discountPrice = (stage?.price / 100) * discount
      setTotal(stage?.price - discountPrice)
      setFinalTotal(stage?.price - discountPrice)
    } else {
      setTotal(stage?.price)
      setFinalTotal(stage?.price)
    }
  }, [stage])

  useEffect(async () => {
    await fetchManufactures()
    await fetchFuels()
    await fetchReadings()
    await fetchDealers()
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Novo'} pedido`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              Novo pedido
            </Typography>
            {loading && <LinearProgress />}
            <Form
              ref={formRef}
              className={classes.form}
              onSubmit={handleNew}
              initialData={location.state?.request}
            >
              <Grid container spacing={1}>
                {user.admin &&
                  <Autocomplete
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    id='dealer'
                    fullWidth
                    className={classes.select}
                    options={dealers}
                    value={dealer}
                    disabled={dealers.length === 0}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => <Input {...params} id='dealer' name='dealer' label='Selecione um dealer *' variant='outlined' />}
                    onChange={(_, newValue) => { setDealer(newValue) }}
                  />}
                <Grid item xs={6}>
                  <Autocomplete
                    disabled={!dealer}
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='manufacturer'
                    className={classes.select}
                    options={manufactures}
                    value={manufacturer}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label='Selecione uma fabricante *' variant='outlined' />}
                    onChange={(_, newValue) => { setManufacturer(newValue) }}
                  />
                  {/* {loadModel && */}
                  <Autocomplete
                    disabled={!loadModel}
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='model'
                    className={classes.select}
                    options={models}
                    value={model}
                    getOptionLabel={(option) => `${option.name}`}
                    ref={modelRef}
                    renderInput={(params) => <Input {...params} id='model' name='model' label='Selecione um modelo *' variant='outlined' />}
                    onChange={(_, newValue) => { setModel(newValue) }}
                  />
                  {/* } */}
                  {/* {loadYear && */}
                  <Autocomplete
                    disabled={!loadYear}
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='year'
                    className={classes.select}
                    options={years}
                    value={year}
                    ref={yearRef}
                    getOptionLabel={(option) => `${option.year}`}
                    renderInput={(params) => <Input {...params} id='year' name='year' label='Selecione um ano *' variant='outlined' />}
                    onChange={(_, newValue) => { setYear(newValue) }}
                  />
                  {/* }
                  {loadMotor && */}
                  <Autocomplete
                    disabled={!loadMotor}
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='motor'
                    className={classes.select}
                    options={motors}
                    value={motor}
                    ref={motorRef}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => <Input {...params} id='motor' name='motor' label='Selecione um motor *' variant='outlined' />}
                    onChange={(_, newValue) => { setMotor(newValue) }}
                  />
                  {/* }
                  {loadStage && */}
                  <Autocomplete
                    disabled={!loadStage}
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='stage'
                    className={classes.select}
                    options={stages}
                    value={stage}
                    ref={stageRef}
                    getOptionLabel={(option) => `${option.stageType.name} - ${option.name} - ${currency(option.price)}`}
                    renderInput={(params) => <Input {...params} id='stage' name='stage' label='Selecione um stage *' variant='outlined' />}
                    onChange={(_, newValue) => { setStage(newValue) }}
                  />
                  {/* } */}
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.paperFile}>
                    <DropzoneArea
                      dropzoneText='Arraste o arquivo ou clique'
                      showAlerts={false}
                      filesLimit={10}
                      maxFileSize={666000000}
                      onChange={(files) => handleFile(files)}
                    />
                  </div>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography variant='h6'>
                Descrição
              </Typography>
              <Typography variant='body'>
                {stage && stage.dealerDescription}
              </Typography>
              <Divider className={classes.divider} />
              <Paper variant='outlined' className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      Original
                    </Typography>
                    {motor &&
                      <>
                        <Typography variant='subtitle1'>
                          Power {motor.originalPower} (HP)
                        </Typography>
                        <Typography variant='subtitle1'>
                          Toquer {motor.originalTorque} (NM)
                        </Typography>
                      </>}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      Novo
                    </Typography>
                    {stage &&
                      <>
                        <Typography variant='subtitle1'>
                          Power {stage.newPower} (HP)
                        </Typography>
                        <Typography variant='subtitle1'>
                          Toquer {stage.newTorque} (NM)
                        </Typography>
                      </>}
                  </Grid>
                </Grid>
              </Paper>
              <Divider className={classes.divider} />
              <Typography variant='h6'>
                Transmissão
              </Typography>
              <RadioGroup row value={transmission} onChange={(event) => setTransmission(event.target.value)}>
                <FormControlLabel value='AUTO' control={<Radio color='primary' />} label='Automática' />
                <FormControlLabel value='MANUAL' control={<Radio color='primary' />} label='Manual' />
              </RadioGroup>

              {/* <Divider className={classes.divider} />
              <Typography variant='h6'>
                Potência do motor
              </Typography>
              <Input
                variant="outlined"
                margin="normal"
                fullWidth
                id="originalPower"
                label="HP"
                name="originalPower"
                autoFocus
              />
              <Input
                variant="outlined"
                margin="normal"
                fullWidth
                id="originalTorque"
                label="Kw"
                name="originalTorque"
              />
              <Divider className={classes.divider} /> */}

              {/* <Input
                variant="outlined"
                margin="normal"
                fullWidth
                id="originalFuel"
                label="Combustível em uso *"
                name="originalFuel"
              /> */}

              <Typography variant='h6'>
                Combustível de fábrica *
              </Typography>
              <RadioGroup row value={fuel} onChange={(event) => setFuel(event.target.value)}>
                {fuels.map((fuel, index) =>
                  <FormControlLabel key={index} value={fuel._id} control={<Radio color='primary' />} label={fuel.name} onChange={() => setFuel(fuel._id)} />
                )}
              </RadioGroup>

              <Typography variant='h6'>
                Combustível em uso *
              </Typography>
              <RadioGroup row value={useFuel} onChange={(event) => setUseFuel(event.target.value)}>
                {fuels.map((fuel, index) =>
                  <FormControlLabel key={index} value={fuel._id} control={<Radio color='primary' />} label={fuel.name} onChange={() => setUseFuel(fuel._id)} />
                )}
              </RadioGroup>

              {/* <Autocomplete
                noOptionsText='Nenhuma opção'
                variant='outlined'
                margin='normal'
                fullWidth
                id='fuel'
                className={classes.select}
                options={fuels}
                value={fuel}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => <Input {...params} id='fuel' name='fuel' label='Combustível em uso *' variant='outlined' />}
                onChange={(_, newValue) => { setFuel(newValue) }}
              /> */}

              <Autocomplete
                noOptionsText='Nenhuma opção'
                variant='outlined'
                margin='normal'
                fullWidth
                id='readingEquipment'
                className={classes.select}
                options={readings}
                value={reading}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => <Input {...params} id='readingEquipment' name='readingEquipment' label='Equipamento de leitura *' variant='outlined' />}
                onChange={(_, newValue) => { setReading(newValue) }}
              />
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='chassi'
                label='Chassi *'
                name='chassi'
              />
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='plate'
                label='Placa *'
                name='plate'
              />
              <Divider className={classes.divider} />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant='h6'>
                    Adicionais
                  </Typography>
                  {stage != null &&
                    <FormGroup>
                      {stage?.additionals.map((additional, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color='primary'
                              checked={additional.checked}
                              onChange={(event) => handleAdditional(event, index)}
                              name={additional.name}
                            />
                          }
                          label={`${additional.name} - ${currency(additional.price)}`}
                        />

                      ))}
                    </FormGroup>}
                  <Divider className={classes.divider} />
                  <Input
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='observation'
                    label='Observação *'
                    name='observation'
                  />
                </Grid>
                <Grid item xs={6}>
                  <Paper variant='outlined' className={classes.paperH}>
                    <Typography variant='subtitle1'> - {stage && `${stage.name} - ${currency(stage.price)}`}</Typography>
                    {additionals.map((additional, index) => (
                      additional.checked && <Typography key={index} variant='subtitle1'> - {additional.name} - {currency(additional.price)}</Typography>
                    ))}
                    <Box flexGrow={1} />
                    {discount && <Typography variant='subtitle1'>Desconto de {discount}%</Typography>}
                    <Paper variant='outlined' className={classes.paperTotal}>
                      <Typography variant='h5'>
                        Total
                      </Typography>
                      <Box flexGrow={1} />
                      <Typography variant='h6'>
                        {currency(finalTotal)}
                      </Typography>
                    </Paper>
                  </Paper>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography variant='h6'>
                Prazo
              </Typography>
              <RadioGroup row value={deadline} onChange={(event) => setDeadline(event.target.value)}>
                <FormControlLabel value='1' control={<Radio color='primary' />} label='1 hora' />
                <FormControlLabel value='2' control={<Radio color='primary' />} label='2 horas' />
                <FormControlLabel value='4' control={<Radio color='primary' />} label='4 horas' />
                <FormControlLabel value='6' control={<Radio color='primary' />} label='6 horas' />
                <FormControlLabel value='NEXT' control={<Radio color='primary' />} label='Próximo dia' />
                <FormControlLabel value='URGENT' control={<Radio color='primary' />} label='Urgente' />
              </RadioGroup>
              <Divider className={classes.divider} />
              <FormControlLabel
                control={<Checkbox color='primary' checked={checkDeclare} onChange={() => setCheckDeclare(!checkDeclare)} name='declaro' />}
                label='Estou de acordo com os termos e condições de uso.'
              />
              <Button disabled={!checkDeclare || sending} type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                {location.state ? 'Atualizar' : 'Criar'} pedido
              </Button>
            </Form>
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default RequestForm

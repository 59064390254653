import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const ModelView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [model, setModel] = useState([])

  const fetchModel = useCallback(async () => {
    const response = await api.get(`/model/${location.state.model._id}`)
    setModel(response.data)
  }, [])

  const handlerEdit = useCallback((model) => {
    navigate('/modelo/editar', { state: { model } })
  }, [])

  useEffect(async () => {
    await fetchModel()
  }, [])

  return (
    <Body title='Modelo'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Modelo
              </Typography>
              <Tooltip title='Editar modelo' arrow placement='right'>
                <IconButton size='small' aria-label='editar-modelo' className={classes.plus} onClick={() => handlerEdit(model)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='modelo'>
              <ListItem>
                <ListItemText primary='NOME' secondary={model?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Fabricante' secondary={model.manufacturer?.name} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={!model.active && 'Modelo desativada'} secondary={`Criado ${moment(model?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ModelView

/* eslint-disable array-callback-return */
import xlsx from 'json-as-xlsx'
import moment from 'moment'

import { currency } from './atom'

export default ({ requests }) => {
  const data = [
    {
      sheet: 'Pedidos',
      columns: [
        { label: 'OS', value: row => row.protocol },
        { label: 'DATA EMISSAO', value: row => moment(row.createdAt).format('DD/MM/yyyy') },
        { label: 'DATA PREVISTA', value: row => moment(row.forWhenDate).format('DD/MM/yyyy') },
        { label: 'CLIENTE', value: row => row.dealer?.code ? row.dealer?.code : 'Sem código' },
        { label: 'MARCA', value: row => row.manufacturer?.name.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() },
        { label: 'MODELO', value: row => row.model?.name.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() },
        { label: 'VERSAO', value: row => row.motor?.name.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() },
        { label: 'ANO', value: row => row.year?.year.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() },
        { label: 'CHASSI', value: row => row.chassi.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() },
        {
          label: 'PLACA',
          value:
            row => row.plate
              ? row.plate.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase().slice(0, 3) + '-' + row.plate.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase().slice(3)
              : (row.plage ? row.plage.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() : '').slice(0, 3) + '-' + (row.plage ? row.plage.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() : '').slice(3)
        },
        { label: 'COMBUSTIVEL DE FABRICA', value: row => row.originalFuel?.name ? row.originalFuel?.name.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() : '' },
        { label: 'COMBUSTIVEL EM USO', value: row => row.useFuel?.name ? row.useFuel?.name : '' },
        { label: 'REPARO SOLICITADO PELO CLIENTE', value: row => row.observation.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() },
        { label: 'EQUIPAMENTO', value: row => row.readingEquipment?.code.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() },
        { label: 'QUANTIDADE', value: row => 1 + (row.additional ? row.additional?.length : 0) },
        { label: 'VALOR DE VENDA', value: row => `${currency(row.finalValue)}` },
        { label: 'STAGE', value: row => row.stage?.name.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() }
        // { label: 'ADICIONAIS', value: row => row.additional?.map(additional => additional.name).join('| ') },
      ],
      content: requests
    }
  ]

  requests.map(request => {
    if (request.additional) {
      request.additional.map(additional => {
        data[0].columns.push({ label: 'ADICIONAL', value: row => additional.name.replace(/[^a-zA-Z0-9 ]/, '').toUpperCase() })
      })
    }
  })

  data[0].columns.push({ label: 'FORMA DE PAGAMENTO', value: row => '10' })
  data[0].columns.push({ label: 'PRAZO', value: row => '30' })

  xlsx(data, {
    fileName: `Prodigy - Importação - ${moment().format('DD/MM/YYYY HH:mm')}`, extraLength: 5, writeOptions: {}
  })
}

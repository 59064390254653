import React, { useState, useEffect } from 'react'
import { Button, Paper, Grid, Typography, LinearProgress, Box } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import OpenNavigation from '../../components/OpenNavigation'

import api, { publicBlobAPI } from '../../services/api'

import useStyles from './style'

const Posts = () => {
  const navigate = useNavigate()
  const classes = useStyles()

  const [blobs, setBlobs] = useState({})

  const fetchPostImages = async (posts) => {
    setLoading(true)
    console.log(posts)
    const blobURL = {}
    for (const post of posts) {
      if (post.file?.name) {
        try {
          blobURL[post.title] = await creatBlob(post.file?.name)
        } catch (err) {
          console.log(err)
        }
      }
    }
    console.log(blobURL)
    setBlobs(blobURL)
    setLoading(false)
  }

  const creatBlob = async (fileName) => {
    const responseFile = await publicBlobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const [loading, setLoading] = useState(true)
  const [posts, setPosts] = useState([])

  const { t } = useTranslation()

  useEffect(async () => {
    const response = await api.get('services/active')
    await fetchPostImages(response.data)
    setPosts(response.data)
    setLoading(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <Grid item xs={12} sm={12} md={12} style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')", justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
        <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
          {t('stage.title').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        <Grid container direction='row' alignItems='center' spacing={3}>
          {loading
            ? <LinearProgress />
            : (
              <>{posts.length > 0
                ? (
                  <>{posts.map((post, index) => (
                    <Grid item key={index} xs={12} sm={12} md={4}>
                      <Paper style={{ height: '300px', marginBottom: 20, display: 'flex', flexDirection: 'column' }} elevation={3}>
                        {blobs[post.title]
                          ? <div style={{ background: `url('${blobs[post.title]}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'auto', backgroundPosition: 'center', width: '100%', height: 300 }} />
                          : null}
                        <Typography variant='h5' gutterBottom style={{ marginTop: 15, marginLeft: 15 }}>
                          {post.title}
                        </Typography>
                        {post.body.length > 100
                          ? <Typography variant='subtitle1' gutterBottom style={{ margin: 10 }}>{post.body.substring(0, 45) + '...'}</Typography>
                          : <Typography variant='subtitle1' gutterBottom style={{ margin: 10 }}>{post.body}</Typography>}
                        <Box flexGrow={1} />
                        <Button onClick={() => navigate(`/news/${post._id}`)} variant='contained' color='primary' style={{ margin: 10 }}>
                          Veja mais
                        </Button>
                      </Paper>
                    </Grid>
                  ))}
                  </>)
                : (
                  <Grid container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12}>
                      Nada por aqui
                    </Grid>
                  </Grid>)}
              </>)}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Posts

export default {
  NEW: 'Novo',
  DOWNLOADED: 'Baixado',
  IN_PROGRESS: 'Em andamento',
  REQUEST_RETUNING: 'Solicitação de reajuste',
  REQUEST_CANCELLATION: 'Solicitação de cancelamento',
  DENIED_CANCELLATION: 'Cancelamento negado',
  ACCEPTED_CANCELLATION: 'Cancelamento aceito',
  REQUEST_FINISHED: 'Solicitação de finalização',
  REQUEST_COMPLETE: 'Pedido finalizado',
  CHANGE_VALUE: 'Alteração de valor',
  CHANGE_STAGE: 'Alteração de etapa',
  CANCEL: 'Cancelado',
  COMPLETE: 'Finalizado',
  BILLED: 'Faturado'
}

const stateResumed = {
  NEW: 'Novo',
  DOWNLOADED: 'Baixado',
  IN_PROGRESS: 'Em andamento',
  REQUEST_RETUNING: 'Solicitação de reajuste',
  REQUEST_CANCELLATION: 'Solicitação de cancelamento',
  REQUEST_FINISHED: 'Solicitação de finalização',
  REQUEST_COMPLETE: 'Pedido finalizado',
  CHANGE_VALUE: 'Alteração de valor',
  CHANGE_STAGE: 'Alteração de etapa',
  CANCEL: 'Cancelado',
  COMPLETE: 'Finalizado',
  BILLED: 'Faturado'
}

export { stateResumed }

import React, { useCallback, useRef, useState } from 'react'
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const filter = createFilterOptions()

const StagesTypesDialog = ({ stageTypes, fetchStageTypes, stageType, setStageType }) => {
  const classes = useStyles()
  const formRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const [open, toggleOpen] = useState(false)
  const [dialogValue, setDialogValue] = useState({ name: '' })

  const handleClose = async () => {
    setDialogValue({ name: '' })
    await fetchStageTypes()
    toggleOpen(false)
  }

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current.getData()
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().required('NOME é obrigatório.')
      })
      await schema.validate(data, { abortEarly: false })

      const response = await api.post('/stagetype/', {
        name: data.name
      })

      if (response.data.status === 'error') { throw new Error(response.data.message) }
      handleClose()
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [])

  return (
    <>
      <Autocomplete
        noOptionsText='Nenhuma opção'
        id='stageTypes'
        className={classes.select}
        variant='outlined'
        margin='normal'
        fullWidth
        freeSolo
        value={stageType}
        onChange={(_, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              toggleOpen(true)
              setDialogValue({ name: newValue })
            })
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true)
            setDialogValue({ name: newValue.inputValue })
          } else {
            setStageType(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue !== '') {
            filtered.push({ inputValue: params.inputValue, name: `Adicionar "${params.inputValue}"` })
          }
          return filtered
        }}
        options={stageTypes}
        getOptionLabel={(option) => {
          if (typeof option === 'string') { return option }
          if (option.inputValue) { return option.inputValue }
          return option.name
        }}
        renderOption={(option) => option.name}
        renderInput={(params) => <Input {...params} id='stageTypes' name='stageTypes' label='Tipo de performance *' variant='outlined' />}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg' PaperProps={{ style: { padding: 20, justifyContent: 'center' } }}>
        <Form
          ref={formRef}
          className={classes.form}
          initialData={dialogValue}
        >
          <DialogTitle id='form-dialog-title'>
            <Typography variant='h4'>
              Novo tipo de performance
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Input
              variant='outlined'
              margin='normal'
              fullWidth
              id='name'
              label='NOME *'
              name='name'
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' color='secondary' className={classes.submit}>
              Cancelar
            </Button>
            <Button onClick={handleSubmit} fullWidth variant='contained' color='primary' className={classes.submit}>
              Criar tipo de performance
            </Button>
          </DialogActions>
          <Typography variant='caption'>
            Items marcados com * são obrigatórios
          </Typography>
        </Form>
      </Dialog>
    </>
  )
}

export default StagesTypesDialog

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api, { publicBlobAPI } from '../../../services/api'

import useStyles from './style'

const PostView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [post, setPost] = useState([])
  const [file, setFile] = useState()

  const fetchPost = useCallback(async () => {
    const response = await api.get(`/post/${location.state.post._id}`)
    setPost(response.data)
    if (response.data.file) {
      const file = await creatBlob(response.data.file.name)
      setFile(file)
    }
  }, [])

  const creatBlob = async (fileName) => {
    const responseFile = await publicBlobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const handlerEdit = useCallback((post) => {
    navigate('/novidade/editar', { state: { post } })
  }, [])

  useEffect(async () => {
    await fetchPost()
  }, [])

  return (
    <Body title='Post'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                {post.service ? 'SERVIÇO/STAGE' : 'NOVIDADE'}
              </Typography>
              <Tooltip title='EDITAR' arrow placement='right'>
                <IconButton size='small' aria-label='editar-post' className={classes.plus} onClick={() => handlerEdit(post)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='post'>
              <ListItem>
                <ListItemText primary='NOME' secondary={post.title} />
              </ListItem>
              <ListItem>
                <ListItemText primary='CORPO' secondary={post.body} style={{ whiteSpace: 'pre' }} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={!post.active && 'posto desativada'} secondary={`Criado ${moment(post?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
              {file
                ? <img src={file} className={classes.thumbnail} onClick={() => window.open(file)} />
                : <ListItem><ListItemText primary='SEM ARQUIVO' /> </ListItem>}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default PostView

import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const ModelsForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [manufacturer, setManufacturer] = useState()
  const [manufactures, setManufactures] = useState([])

  const [done, setDone] = useState(false)
  const [active, setActive] = useState(true)

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`model/${location.state?.model._id}`, {
          name: data.name,
          manufacturer: manufacturer._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/modelo/', { state: { model: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/model/', {
          name: data.name,
          manufacturer: manufacturer._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/modelo/', { state: { model: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [active, manufacturer])

  const fetchmanufactures = useCallback(async () => {
    const response = await api.get('manufactures')
    setManufactures(response.data)
  }, [])

  const setEdit = useCallback(async (model) => {
    setActive(model.active)
    setManufacturer(model.manufacturer)
  }, [])

  useEffect(async () => {
    await fetchmanufactures()
    if (location.state) { await setEdit(location.state.model) }
    setDone(true)
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Novo'} modelo`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Novo'} modelo
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.model}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='NOME *'
                  name='name'
                  autoFocus
                />
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='manufacturer'
                  className={classes.select}
                  options={manufactures}
                  value={manufacturer}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label='Selecione uma fabricante *' variant='outlined' />}
                  onChange={(_, newValue) => { setManufacturer(newValue) }}
                />
                <Divider className={classes.divider} />
                <FormControlLabel
                  label='Modelo ativa?'
                  control={
                    <Switch
                      checked={active}
                      onChange={handleSwitch}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state ? 'Atualizar' : 'Criar'} modelo
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ModelsForm

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Button, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2, Copy, ExternalLink, Trash } from 'react-feather'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import Body from '../../../components/Body'
import api, { publicBlobAPI } from '../../../services/api'
import { currencyByType } from '../../../services/atom'

import useStyles from './style'

const stageView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [stage, setStage] = useState([])
  const [siteImage, setSiteImage] = useState()

  const creatBlob = async (fileName) => {
    const responseFile = await publicBlobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const fetchStage = useCallback(async () => {
    const response = await api.get(`/stage/${location.state.stage._id}`)
    // const responseAdditional = await api.get(`/additional/stage/${location.state.stage._id}`)
    if (response.data.siteImage) {
      try {
        const siteImage = await creatBlob(response.data.siteImage.name)
        setSiteImage(siteImage)
      } catch (err) {
        enqueueSnackbar('Erro ao pegar imagens do site', { variant: 'info' })
      }
    }
    setStage(response.data)
    // setAddStage(responseAdditional.data)
  }, [stage])

  const handlerEdit = useCallback((stage) => {
    navigate('/stage/editar/', { state: { stage, clone: false } })
  }, [])

  const handlerExternal = useCallback((stage) => {
    navigate(`/product/${stage._id}`, { state: { stage, clone: false } })
  }, [])

  const handlerClone = useCallback((stage) => {
    navigate('/stage/clone/', { state: { stage, clone: true } })
  }, [])

  const handlerAdd = useCallback((stage) => {
    navigate('/stage/adicional/', { state: { stage } })
  }, [])

  const handleAdd = useCallback((additional) => {
    navigate('/adicional/', { state: { additional } })
  }, [])

  const handleRemoveAdd = useCallback(async ({ additional, index }) => {
    const confirm = window.confirm(`Deseja remover o adicional ${additional.name}?`)
    if (confirm) {
      await api.put('stages/additian/remove', {
        _id: location.state.stage._id,
        index
      })
    }
    await fetchStage()
  }, [])

  useEffect(async () => {
    await fetchStage()
  }, [])

  return (
    <Body title='Stage'>
      {/* <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg' PaperProps={{ style: { padding: 20, justifyContent: 'center' } }}>
        <Form
          ref={formRef}
          className={classes.form}
          initialData={dialogValue}>
          <DialogTitle id="form-dialog-title">
            <Typography variant='h4'>
              Nova fabricante
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Input
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="NOME *"
              name="name"
              autoFocus
            />
            <Divider className={classes.divider} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="secondary" className={classes.submit}>
              Cancelar
            </Button>
            <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" className={classes.submit}>
              Criar adicional
            </Button>
          </DialogActions>
          <Typography variant="caption">
            Items marcados com * são obrigatórios
          </Typography>
        </Form>
      </Dialog > */}

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Stage
              </Typography>
              <Tooltip title='Editar stage' arrow placement='right'>
                <IconButton size='small' aria-label='editar-stage' className={classes.plus} onClick={() => handlerEdit(stage)}><Edit2 /></IconButton>
              </Tooltip>
              <Tooltip title='Stage publico' arrow placement='right'>
                <IconButton size='small' aria-label='stage-publico' className={classes.plus} onClick={() => handlerExternal(stage)}><ExternalLink /></IconButton>
              </Tooltip>
              <Tooltip title='Clonar stage' arrow placement='right'>
                <IconButton size='small' aria-label='clonar-stage' className={classes.plus} onClick={() => handlerClone(stage)}><Copy /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='stage'>
              <ListItem>
                <ListItemText primary='Stage' secondary={stage.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Código' secondary={stage.code} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Ganho de potência' secondary={stage.newPower} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Ganho de torque' secondary={stage.newTorque} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Fabricante' secondary={stage.manufacturer?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Modelo' secondary={stage.model?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Ano' secondary={stage.year?.year} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Motor' secondary={stage.motor?.name} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={stage.activeSite ? 'ATIVO no site' : 'INATIVO no site'} />
              </ListItem>
              <ListItem>
                <ListItemText primary={stage.activeDealer ? 'ATIVO nos dealers' : 'INATIVO nos dealers'} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText
                  primary={stage.siteFileActive ? 'Arquivo do site - ATIVO' : 'Arquivo do site - INATIVO'}
                  secondary={stage.siteFile?.name ? stage.siteFile?.name : 'Nenhum arquivo enviado'}
                />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary='Dinamometro' secondary={stage.dynamometerFile?.name ? stage.dynamometerFile?.name : 'Nenhum arquivo enviado'} />
              </ListItem>
              <ListItem>
                <ListItemText secondary={`Criado ${moment(stage?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
              <Divider className={classes.divider} />
              {siteImage
                ? <img src={siteImage} className={classes.thumbnail} onClick={() => window.open(siteImage)} />
                : <ListItem><ListItemText primary='Sem imagem' /></ListItem>}
            </List>
            <Typography variant='h5' component='h2'>
              Adicionais
              <Button size='small' variant='contained' style={{ marginLeft: 10 }} color='primary' onClick={() => handlerAdd(stage)}>
                Novo adicional
              </Button>
            </Typography>
            <List component='nav' aria-label='additional'>
              {stage?.additionals?.map((additional, index) => (
                <ListItem key={index}>
                  <ListItemText primary='Name' secondary={additional.name} />
                  <ListItemText primary='Descrição' secondary={additional.description} />
                  <ListItemText primary='Preço' secondary={`${currencyByType(additional.price)}`} />
                  <Tooltip title='Ir para adicional' arrow placement='right'>
                    <IconButton size='small' aria-label='stage-publico' className={classes.plus} onClick={() => handleAdd(additional)}><ExternalLink /></IconButton>
                  </Tooltip>
                  <Tooltip title='Remover adicional' arrow placement='right'>
                    <IconButton size='small' aria-label='stage-publico' className={classes.plus} onClick={() => handleRemoveAdd({ additional, index })}><Trash /></IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default stageView

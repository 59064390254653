import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, IconButton, LinearProgress, TableSortLabel, Button, Paper } from '@material-ui/core'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table'
import { Link, useNavigate } from 'react-router-dom'
import { Search } from 'react-feather'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import { Form } from '@unform/web'

import Input from '../../components/Inputs/Input'
import CustomAutocomplete from '../../components/Inputs/CustomAutocomplete'
import statusType from '../../services/statusType'
import api from '../../services/api'

import { useAuth } from '../../hooks'

const TableProviderOpenService = ({ dealerId }) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const { user } = useAuth()

  const [sendind, setSendind] = useState(false)
  const [addDialog, setAddDialog] = useState(false)

  const [bills, setBills] = useState([])
  const [bill, setBill] = useState(null)

  const [loading, setLoading] = useState(true)
  const [requests, setReqiests] = useState([])

  const columns = useMemo(() => [{
    Header: 'Protocolo',
    accessor: (originalRow) => `${originalRow.protocol} ${originalRow.code ? `(${originalRow.code})` : ''}`
  }, {
    Header: 'Status',
    accessor: (originalRow) => statusType[originalRow.timeline[originalRow.timeline.length - 1].name].toUpperCase()
  }, {
    Header: 'Dealer',
    accessor: (originalRow) => originalRow.dealer?.name
  }, {
    Header: 'Stage',
    accessor: (originalRow) => `${originalRow.manufacturer?.name} - ${originalRow.model?.name} - ${originalRow.year?.year} - ${originalRow.motor?.name} - ${originalRow.stage.name}`
  }, {
    Header: 'Placa',
    accessor: (originalRow) => originalRow.plate
  }, {
    Header: 'Chassi',
    accessor: (originalRow) => originalRow.chassi
  }], [])

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows
    // state: { selectedRowIds }
    // preGlobalFilteredRows,
    // setGlobalFilter
  } = useTable({
    data: requests,
    defaultColumn,
    columns
  }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect, hooks => {
    hooks.visibleColumns.push(columns => [{
      id: 'inspecionar',
      Cell: ({ row }) => <IconButton component={Link} to={`/pedido/${row.original.protocol}`} size='small'> <Search /></IconButton>
    }, {
      id: 'selection',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      )
    }, ...columns])
  })

  const handleCreateBill = useCallback(async () => {
    try {
      setLoading(true)
      setSendind(true)
      const confirm = window.confirm('Deseja criar uma nova fatura?')
      if (!confirm) return
      const requests = selectedFlatRows.map(row => row.original._id)
      const reponse = await api.post('/bill', {
        dealer: dealerId,
        requests
      })
      setSendind(false)

      navigate(`/fatura/${reponse.data._id}`)
    } catch (err) {
      setSendind(false)
      setLoading(false)
    }
  }, [selectedFlatRows, dealerId])

  const handleOpenAddDialog = useCallback(async () => {
    try {
      setLoading(true)
      setSendind(true)
      const response = await api.get(`/bills/${dealerId}/open`)
      setBills(response.data)
      setAddDialog(true)
      setLoading(false)
      setSendind(false)
    } catch (err) {
      setLoading(false)
      setSendind(false)
    }
  }, [dealerId])

  const handleAddToBill = useCallback(async (data) => {
    try {
      setLoading(true)
      setSendind(true)
      // const schema = Yup.object().shape({
      //   observation: Yup.string().required('Fazer observação é obrigatório'),
      //   bill: Yup.string().required('Selecionar fatura é obrigatório')
      // })
      // await schema.validate(data, { abortEarly: false })

      if (bill?._id == null) {
        enqueueSnackbar('Selecionar uma fatura', { variant: 'warning' })
        setLoading(false)
        setSendind(false)
        return
      }

      if (data.observation === '') {
        enqueueSnackbar('Adicione uma observação', { variant: 'warning' })
        setLoading(false)
        setSendind(false)
        return
      }

      const reponse = await api.put(`bill/provider/${bill?._id}/add`, {
        services: selectedFlatRows.map(row => row.original._id),
        observation: data.observation
      })
      navigate(`/fornecedores/fatura/${reponse.data.protocol}`)
    } catch (err) {
      setLoading(false)
      setSendind(false)
    }
  }, [selectedFlatRows, bill])

  const fetchOpen = useCallback(async () => {
    setLoading(true)
    const response = await api.get(`/requests/finished/${dealerId}`)
    setReqiests(response.data)
    setLoading(false)
  }, [dealerId])

  useEffect(() => {
    fetchOpen()
  }, [])

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <><input type='checkbox' ref={resolvedRef} {...rest} /></>
      )
    }
  )

  return (
    <>
      <Dialog open={addDialog} onClose={() => setAddDialog(false)} aria-labelledby='dialogo-additional' aria-describedby='dialogo-additional' fullWidth maxWidth='lg'>
        <Form onSubmit={handleAddToBill} ref={formRef}>
          <DialogTitle id='titulo-dialogo-additional'>Adicionar serviços para fatura</DialogTitle>
          <DialogContent>
            {selectedFlatRows.map(row => (
              <Paper key={row.original._id} variant='outlined' sx={{ m: 1, p: 1 }}>
                <Typography variant='h6'>{row.original.type}</Typography>
                <Typography variant='h6'>{row.original.protocol}</Typography>
                <Typography variant='body2'>{row.original.company?.name}</Typography>
                <Typography variant='body2'>{row.original.local?.name}</Typography>
                <Typography variant='body2'>{row.original.tec?.name}</Typography>
                <Typography variant='body2'>{row.original.ana?.name}</Typography>
                <Typography variant='body2'>{moment(row.original.serviceDate).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}</Typography>
              </Paper>
            ))}
            <CustomAutocomplete
              name='bill'
              value={bill}
              options={bills}
              getOptionLabel={(option) => `${option.protocol} - ${option.period}`}
              label='Selecione uma fatura *'
              onChange={(_, newValue) => {
                setBill(newValue)
              }}
            />
            <Input
              name='observation'
              label='Observação *'
            />
            <Typography variant='caption'>
              Itens marcados com * são obrigatórios
            </Typography>
          </DialogContent>
          <DialogActions>
            <FormGroup row>
              <Button disableElevationcolor='primary' size='small' variant='contained' type='submit' disabled={sendind}>
                Adicionar pedido para fatura
              </Button>
            </FormGroup>
          </DialogActions>
        </Form>
      </Dialog>
      {loading
        ? <LinearProgress />
        : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItens: 'end', marginTop: '32px' }}>
              <Typography variant='h5' component='h2'>
                PEDIDOS SEM FATURA
              </Typography>
              {user.admin &&
                <div>
                  <Button disabled={selectedFlatRows.length === 0} size='small' style={{ marginRight: '8px' }} variant='contained' color='primary' onClick={handleCreateBill}>
                    Criar nova fatura
                  </Button>
                  <Button disabled={selectedFlatRows.length === 0} size='small' variant='contained' color='primary' onClick={handleOpenAddDialog}>
                    Adicionar a fatura
                  </Button>
                </div>}
            </Box>
            {requests.length > 0
              ? (
                <>
                  <Table {...getTableProps()}>
                    <TableHead>
                      {headerGroups.map((headerGroup, index) =>
                        <TableRow key={index} {...headerGroup.getHeaderGroupProps()} hover>
                          {headerGroup.headers.map((column, index) =>
                            <TableCell key={index}>
                              <TableSortLabel
                                active={column.isSorted}
                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                              >
                                {column.render('Header')}
                              </TableSortLabel>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                      {/* {headerGroups.map((headerGroup, index) =>
                        <TableRow key={index} hover>
                          {headerGroup.headers.map((column, index) =>
                            <TableCell key={index}>
                              {column.canFilter ? column.render('Filter') : null}
                            </TableCell>
                          )}
                        </TableRow>
                      )} */}
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => {
                        prepareRow(row)
                        return (
                          <TableRow key={index} hover {...row.getRowProps()}>
                            {row.cells.map((cell, index) => <TableCell key={index} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>)}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </>)
              : (
                <Box sx={(theme) => (theme.nenhumBox)}><Typography variant='h6' color='textSecondary' style={{ alignSelf: 'center' }}>Nenhum pedido no momento</Typography></Box>)}
          </>)}
    </>
  )
}

export default TableProviderOpenService

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationPT from '../src/assets/translations/pt-BR.json'
import translationES from '../src/assets/translations/es.json'
import translationUS from '../src/assets/translations/us.json'

const fallbackLng = ['pt-BR']
const availableLanguages = ['pt-BR', 'es']

const resources = {
  'pt-BR': {
    translation: translationPT
  },
  es: {
    translation: translationES
  },
  us: {
    translation: translationUS
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  })

export default i18n

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, Box, Typography, LinearProgress, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import TableOpenRequests from '../../../components/TableOpenRequests'
import BillTable from '../../../components/BillTable'
import Body from '../../../components/Body'

import { currency } from '../../../services/atom'
import { useAuth } from '../../../hooks'
import api from '../../../services/api'

import useStyles from './style'

const PedidoIndex = () => {
  const classes = useStyles()
  const { user } = useAuth()

  const [loading, setLoading] = useState(true)
  const [bills, setBills] = useState([])

  const [pago, setPago] = useState([])
  const [devedor, setDevedor] = useState([])
  const navigate = useNavigate()

  const fetchBill = useCallback(async () => {
    setLoading(true)
    if (user.admin) {
      const response = await api.get('bills/open')
      let value = 0
      let paid = 0
      for (const i in response.data) {
        value += response.data[i].value
        paid += response.data[i].paid
      }
      setPago(paid)
      setDevedor(value - paid)
      setBills(response.data)
    } else {
      const response = await api.get(`/bills/${user?.dealer._id}/open`)
      let value = 0
      let paid = 0
      for (const i in response.data) {
        value += response.data[i].value
        paid += response.data[i].paid
      }
      setPago(paid)
      setDevedor(value - paid)
      setBills(response.data)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchBill()
  }, [])

  return (
    <Body title='FATURAS PARCIAIS'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                FATURAS PARCIAIS [ {bills.length} ]
              </Typography>
              <Box flexGrow={1} />
              <Button variant='contained' color='primary' style={{ marginLeft: '8px' }} onClick={() => navigate('/financeiro/todos')}>Todas</Button>
            </Grid>
            {loading
              ? <LinearProgress />
              : (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} style={{ marginBottom: '16px', marginTop: '32px' }}>
                      <Paper
                        style={{
                          padding: '24px',
                          transitionProperty: 'box-shadow',
                          boxShadow: 'none',
                          backgroundImage: 'none',
                          borderRadius: '32px',
                          backgroundColor: 'grey',
                          color: 'rgb(255, 255, 255)'
                        }}
                      >
                        <Typography variant='h3'>
                          {currency(devedor)}
                        </Typography>
                        <Typography variant='h5' style={{ marginTop: 1, color: '#ffffff' }}>
                          {user.admin ? 'AGUARDANDO PAGAMENTO' : 'DEVEDOR'}
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={6} style={{ marginBottom: '16px', marginTop: '32px' }}>
                      <Paper
                        style={{
                          padding: '24px',
                          transitionProperty: 'box-shadow',
                          boxShadow: 'none',
                          backgroundImage: 'none',
                          borderRadius: '32px',
                          backgroundColor: (devedor > pago) ? '#00CC33' : 'grey',
                          color: 'rgb(255, 255, 255)'
                        }}
                      >
                        <Typography variant='h3'>
                          {currency(pago)}
                        </Typography>
                        <Typography variant='h5' sx={{ mt: 1, color: '#ffffff69' }}>
                          PAGO
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <BillTable bills={bills} />
                </>)}
          </Paper>
          {user?.dealer?._id &&
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <TableOpenRequests dealerId={user?.dealer._id} />
            </Paper>}
        </Grid>
      </Grid>
    </Body>
  )
}

export default PedidoIndex

import React, { useEffect, useRef } from 'react'
import { TextField } from '@material-ui/core'
import { useField } from '@unform/core'

const SignInput = ({ name, ...inputProps }) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, error, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <TextField
      defaultValue={defaultValue}
      inputRef={inputRef}
      error={!!error}
      helperText={error}
      {...inputProps}
    />
  )
}

export default SignInput

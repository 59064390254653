import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Switch, FormControlLabel, Radio, RadioGroup, InputAdornment } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import InputMask from '../../../components/InputMask'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const DealerForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [file, setFile] = useState(false)

  const [max, setMax] = useState()
  const [priceType, setPriceType] = useState('Real')

  const [done, setDone] = useState(false)
  const [active, setActive] = useState(true)

  const handlepriceType = useCallback((event) => {
    setPriceType(event.target.value)
  })

  const handleActive = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleFile = useCallback((files) => {
    setFile(files[0])
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().required('NOME obrigatório.'),
        maxNegativeValue: Yup.string().required('Máximo negativo é obrigatório.')
      })
      await schema.validate(data, { abortEarly: false })

      if (location.state) {
        const response = await api.put(`dealer/${location.state?.dealer._id}`, {
          name: data.name,
          code: data.code,
          email: data.email,
          phone: data.phone,
          maxNegativeValue: max,
          facebook: data.facebook,
          instagram: data.instagram,
          youtube: data.youtube,
          description: data.description,
          priceType,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          await api.patch(`/dealer/${response.data._id}/logo`, formData)
        }

        navigate('/admin/dealer/', { state: { dealer: response.data } })
      } else {
        const response = await api.post('/dealer', {
          name: data.name,
          code: data.code,
          email: data.email,
          phone: data.phone,
          maxNegativeValue: max,
          facebook: data.facebook,
          instagram: data.instagram,
          youtube: data.youtube,
          description: data.description,
          priceType,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          await api.patch(`/dealer/${response.data._id}/logo`, formData)
        }

        navigate('/admin/dealer/', { state: { dealer: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [priceType, active, file, max])

  const setEdit = useCallback(async (dealer) => {
    setActive(dealer.active)
    setMax(dealer.maxNegativeValue)
  }, [])

  useEffect(async () => {
    if (location.state) { await setEdit(location.state.dealer) }
    setDone(true)
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Novo'} dealer`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Novo'} dealer
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.dealer}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='NOME *'
                  name='name'
                  autoFocus
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='code'
                  label='Código *'
                  name='code'
                />

                <Typography variant='h6' style={{ marginTop: '8px' }}>
                  Tipo de moeda
                </Typography>
                <RadioGroup row value={priceType} onChange={handlepriceType}>
                  <FormControlLabel value='Real' control={<Radio color='primary' />} label='Real' />
                  <FormControlLabel value='Dolar' control={<Radio color='primary' />} label='Dolar' />
                  <FormControlLabel value='Euro' control={<Radio color='primary' />} label='Euro' />
                  <FormControlLabel value='Guaraníes' control={<Radio color='primary' />} label='Guaraníes' />
                  <FormControlLabel value='Peso colombiano' control={<Radio color='primary' />} label='Peso colombiano' />
                  <FormControlLabel value='Peso mexicano' control={<Radio color='primary' />} label='Peso mexicano' />
                </RadioGroup>

                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='maxNegativeValue'
                  label='Máximo negativo *'
                  name='maxNegativeValue'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        {priceType === 'Real' && 'R$'}
                        {priceType === 'Dolar' && '$'}
                        {priceType === 'Euro' && '€'}
                        {priceType === 'Guaraníes' && 'PYG'}
                        {priceType === 'Peso colombiano' && 'COP'}
                        {priceType === 'Peso mexicano' && 'MXN'}
                      </InputAdornment>)
                  }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={max}
                  // onValueChange={(values) => setValue(values.floatValue)}
                  onChange={(e) => setMax(e.target.value)}
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='email'
                  label='Email'
                  name='email'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='phone'
                  label='Telefone'
                  name='phone'
                />

                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='facebook'
                  label='Facebook'
                  name='facebook'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='instagram'
                  label='Instagram'
                  name='instagram'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='youtube'
                  label='Youtube'
                  name='youtube'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='description'
                  label='Descrição'
                  name='description'
                  rows={5}
                  multiline
                />

                <DropzoneArea
                  dropzoneText='Arraste a logo do dealer ou clique'
                  acceptedFiles={['image/*']}
                  showAlerts={false}
                  filesLimit={1}
                  maxFileSize={666000000}
                  onChange={(files) => handleFile(files)}
                />
                <FormControlLabel
                  label='Dealer ativo?'
                  control={
                    <Switch
                      checked={active}
                      onChange={handleActive}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state ? 'Atualizar' : 'Criar'} dealer
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DealerForm

import { makeStyles, alpha } from '@material-ui/core'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  logo: {
    position: 'relative',
    marginBottom: theme.spacing(4),
    height: 60,
    top: 20,
    left: 20
  },
  forgot: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '40%',
    padding: '20vh 3vh 4vh 3vh'
  },
  paperFather: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    background: '#f15b2b'
  },

  appBar: {
    background: 'transparent',
    boxShadow: 0
  },

  drawerList: {
    width: '40vw',
    padding: 20,
    background: '#272727',
    flex: '1',
    color: '#fff',
    textAlign: 'center'
  },

  toolBar: {
    // background: 'linear-gradient(to top, transparent, #b1b1b1 100%),gray repeating-linear-gradient(45deg, transparent, transparent 35px,rgba(255, 255, 255, 0.5) 35px, rgba(255, 255, 255, 0.5) 70px)',
    // background: 'linear-gradient(180deg, #000000, #0009,#0005, transparent)',
    // boxShadow: '10px 10px 10px 10px rgba(0,0,0,0.5)',
    background: 'linear-gradient(270deg, #000000, #0009,#0005, transparent), linear-gradient(90deg, #ffff, #ffff, #ffff, transparent)',
    height: 110
  },

  titleGrid: {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${config[process.env.NODE_ENV].publicBlob}homeHeader.jpg), url('../images/0.jpg')`,
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },

  title: {
    position: 'absolute',
    top: '70%',
    left: '50%',
    width: '80%',
    textAlign: 'right',
    transform: 'translate(-50%, -50%)',
    color: '#f15b2b',
    fontWeight: '800',
    fontStyle: 'oblique'
  },

  sobreContainer: {
    height: '500px'
  },

  sobreContainerWhite: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    background: '#f0f0f0'
  },

  sobre: {
    padding: 40,
    textAlign: 'center',
    background: '#ffffff'
  },

  sobre2: {
    padding: 40,
    background: '#ffffff'
  },

  sobreImage: {
    background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/2.jpg')",
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },

  sobreTitle: {
    color: '#000000',
    fontSize: '30px',
    fontWeight: '800'
  },

  sobreSub: {
    marginTop: 50,
    color: '#000000',
    fontSize: '20px',
    fontWeight: '400'
  },

  zero: {
    display: 'flex'
  },

  auto: {
    width: 300
  },

  footer: {
    background: '#f15b2b'
  },

  footerInfo: {
    paddingTop: 20,
    paddingLeft: 50
  },

  footerInfoFont: {
    color: '#000000',
    fontSize: '12px',
    fontWeight: '500'
  },

  footerInfoFontBold: {
    color: '#000000',
    fontSize: '12px',
    fontWeight: '900'
  },

  footerInfoFontWhite: {
    color: '#ffffff',
    fontSize: '20px',
    fontWeight: '900'
  },

  footerIcon: {
    marginRight: theme.spacing(2),
    color: '#000000'
  },

  select: {
    display: 'flex',
    paddingTop: 40,
    padding: 20,
    margin: 0,
    justifyContent: 'center'
  },
  select2: {
    display: 'flex',
    paddingTop: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'row wrap',
    paddingBottom: 40,
    background: '#ffffff'
  },

  selectTitle: {
    color: 'black',
    fontWeight: '800',
    margin: 0
  },

  somos: {
    height: '300',
    overflow: 'hidden',
    background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/1.jpg')",
    textAlign: 'center',
    padding: 60
    // paddingBottom: 60,
  },

  somosTitle: {
    color: '#ffff',
    fontSize: '50px',
    fontWeight: '800'
  },

  somosSub: {
    marginTop: 100,
    color: '#ffff',
    fontSize: '30px',
    fontWeight: '500'
  },

  somosButton: {
    marginTop: 50,
    width: 200,
    marginRight: theme.spacing(2)
  },

  menuButton: {
    // marginRight: theme.spacing(2),
    filter: 'invert(100%)'
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    left: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }

}))

export default useStyles

import React from 'react'
import { Typography, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Copyright = () => {
  const { t } = useTranslation()
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      © {new Date().getFullYear() + ' '}
      <Link color='inherit' href='/'>
        Prodigy.
      </Link>{' '}{t('auth.right')}
    </Typography>
  )
}

export default Copyright

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const MotorView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [motor, setMotor] = useState([])

  const fetchMotor = useCallback(async () => {
    const response = await api.get(`/motor/${location.state.motor._id}`)
    setMotor(response.data)
  }, [])

  const handlerEdit = useCallback((motor) => {
    navigate('/motor/editar', { state: { motor } })
  }, [])

  useEffect(async () => {
    await fetchMotor()
  }, [])

  return (
    <Body title='Motor'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Motor
              </Typography>
              <Tooltip title='Editar motor' arrow placement='right'>
                <IconButton size='small' aria-label='editar-motor' className={classes.plus} onClick={() => handlerEdit(motor)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='motor'>
              <ListItem>
                <ListItemText primary='Motor' secondary={motor?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Potência original' secondary={motor.originalPower} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Torque original' secondary={motor.originalTorque} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Fabricante' secondary={motor.manufacturer?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Modelo' secondary={motor.model?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Ano' secondary={motor.year?.year} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={!motor.active && 'Ano desativada'} secondary={`Criado ${moment(motor?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default MotorView

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  logo: {
    marginBottom: theme.spacing(4),
    height: 100
  },
  forgot: {
    display: 'flex',
    justifyContent: 'center'
  },
  footer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '40%',
    padding: '20vh 3vh 4vh 3vh'
  },
  paperFather: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#f15b2b'
  }
}))

export default useStyles

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  body: {
    padding: 50,
    minHeight: '70%'
  },
  title: {
    textAlign: 'center',
    fontSize: 35
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 20
  },
  prod: {
    padding: 5
  },
  search: {
    marginBottom: 20
  }
}))

export default useStyles

import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const MotorForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [manufacturer, setManufacturer] = useState()
  const [manufactures, setManufactures] = useState([])

  const [loadModel, setLoadModel] = useState(false)
  const [model, setModel] = useState()
  const [models, setModels] = useState([])

  const [loadYear, setLoadYear] = useState(false)
  const [year, setYear] = useState()
  const [years, setYears] = useState([])

  const [done, setDone] = useState(false)
  const [active, setActive] = useState(true)

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME é obrigatório.'),
          originalPower: Yup.string().required('Potência é obrigatório.'),
          originalTorque: Yup.string().required('Torque é obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`motor/${location.state?.year._id}`, {
          name: data.name,
          originalPower: data.originalPower,
          originalTorque: data.originalTorque,
          manufacturer: manufacturer._id,
          model: model._id,
          year: year._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/motor/', { state: { motor: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME é obrigatório.'),
          originalPower: Yup.string().required('Potência é obrigatório.'),
          originalTorque: Yup.string().required('Torque é obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/motor/', {
          name: data.name,
          originalPower: data.originalPower,
          originalTorque: data.originalTorque,
          manufacturer: manufacturer._id,
          model: model._id,
          year: year._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/motor/', { state: { motor: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [active, manufacturer, model, year])

  const fetchManufactures = useCallback(async () => {
    const response = await api.get('manufactures')
    setManufactures(response.data)
  }, [])

  const fetchModels = useCallback(async () => {
    setLoadModel(false)
    const response = await api.get(`models/manufacturer/${manufacturer?._id}`)
    setModels(response.data)
    if (response.data.length > 0) {
      setLoadModel(true)
    }
  }, [manufacturer])

  const fetchYears = useCallback(async () => {
    setLoadYear(false)
    const response = await api.get(`years/model/${model?._id}`)
    setYears(response.data)
    if (response.data.length > 0) {
      setLoadYear(true)
    }
  }, [model])

  const setEdit = useCallback(async (motor) => {
    setActive(motor.active)
    setManufacturer(motor.manufacturer)
    setModel(motor.model)
    setYear(motor.year)
  }, [])

  useEffect(async () => {
    await fetchYears()
  }, [model])

  useEffect(async () => {
    await fetchModels()
  }, [manufacturer])

  useEffect(async () => {
    await fetchManufactures()
    if (location.state) { await setEdit(location.state.motor) }
    setDone(true)
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Novo'} motor`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Novo'} motor
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.motor}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='NOME *'
                  name='name'
                  autoFocus
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='originalPower'
                  label='Potência original *'
                  name='originalPower'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='originalTorque'
                  label='Toque original *'
                  name='originalTorque'
                />
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='manufacturer'
                  className={classes.select}
                  options={manufactures}
                  value={manufacturer}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label='Selecione uma fabricante *' variant='outlined' />}
                  onChange={(_, newValue) => { setManufacturer(newValue) }}
                />
                {loadModel &&
                  <Autocomplete
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='model'
                    className={classes.select}
                    options={models}
                    value={model}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => <Input {...params} id='model' name='model' label='Selecione uma modelo *' variant='outlined' />}
                    onChange={(_, newValue) => { setModel(newValue) }}
                  />}
                {loadYear &&
                  <Autocomplete
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='model'
                    className={classes.select}
                    options={years}
                    value={year}
                    getOptionLabel={(option) => `${option.year}`}
                    renderInput={(params) => <Input {...params} id='model' name='model' label='Selecione uma ano *' variant='outlined' />}
                    onChange={(_, newValue) => { setYear(newValue) }}
                  />}
                <Divider className={classes.divider} />
                <FormControlLabel
                  label='Ano ativo?'
                  control={
                    <Switch
                      checked={active}
                      onChange={handleSwitch}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state ? 'Atualizar' : 'Criar'} motor
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default MotorForm

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const FuelView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [fuel, setFuel] = useState([])

  const fetchFuel = useCallback(async () => {
    const response = await api.get(`/fuel/${location.state.fuel._id}`)
    setFuel(response.data)
  }, [])

  const handlerEdit = useCallback((fuel) => {
    navigate('/combustivel/editar', { state: { fuel } })
  }, [])

  useEffect(async () => {
    await fetchFuel()
  }, [])

  return (
    <Body title='Combustível'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Combustível
              </Typography>
              <Tooltip title='Editar combustível' arrow placement='right'>
                <IconButton size='small' aria-label='editar-combustivel' className={classes.plus} onClick={() => handlerEdit(fuel)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='combustivel'>
              <ListItem>
                <ListItemText primary='NOME' secondary={fuel.name} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary='ATIVO' secondary={fuel.active ? 'Sim' : 'Não'} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText secondary={`Criado ${moment(fuel?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default FuelView

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'

import api from '../../../services/api'

import useStyles from './style'

const administratorView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [administrator, setAdministrator] = useState([])

  const fetchAdministrator = useCallback(async () => {
    const response = await api.get(`/user/${location.state.administrator._id}`)
    setAdministrator(response.data)
  }, [])

  const handlerEdit = useCallback((administrator) => {
    navigate('/administrador/editar', { state: { administrator } })
  }, [])

  useEffect(async () => {
    await fetchAdministrator()
  }, [])

  return (
    <Body title='Administrador'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Administrador
              </Typography>
              <Tooltip title='Editar administrador' arrow placement='right'>
                <IconButton size='small' aria-label='editar-administrador' className={classes.plus} onClick={() => handlerEdit(administrator)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='administrador'>
              <ListItem>
                <ListItemText primary='NOME' secondary={administrator.name} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary='CPF' secondary={administrator.cpf} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary='Email' secondary={administrator.email} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary='Telefone' secondary={administrator.phone} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={!administrator.active && 'Administrador desativado'} secondary={`Criado ${moment(administrator?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY hh:mm' })}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default administratorView

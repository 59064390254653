import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Paper, Grid, Typography, InputAdornment, IconButton } from '@material-ui/core'
import { Eye, EyeOff, ChevronLeft } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import api from '../../../services/api'
import { getValidationsErros } from '../../../services/atom'

import SignInput from '../../../components/Input'
import useStyles from './style'

const Recover = () => {
  const classes = useStyles()
  const formRef = useRef(null)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { t } = useTranslation()

  const [email, setEmail] = useState()

  const [checked, setChecked] = useState(false)
  const [codeChecked, setCodeChecked] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleClickShowPassword = useCallback((state) => { setShowPassword(state) }, [])
  const handleClickShowConfirmPassword = useCallback((state) => { setShowConfirmPassword(state) }, [])

  const catchError = (err) => {
    if (err instanceof Yup.ValidationError) {
      const error = getValidationsErros(err)
      formRef.current?.setErrors(error)
      return
    }
    if (err.message) {
      enqueueSnackbar(err.message, { autoHideDuration: 9000, variant: 'error' })
      return
    }
    enqueueSnackbar(err, { variant: 'error' })
  }

  const handleEmailCheck = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        email: Yup.string().email('Digite um email válido.').required('Email é obrigatório.')
      })
      await schema.validate(data, { abortEarly: false })
      const checkUser = await api.post('recover', { email: data.email })
      if (checkUser.data.status === 'error') {
        enqueueSnackbar('Acesso não existe.', { variant: 'error' })
        return
      }
      enqueueSnackbar(checkUser.data.message, { autoHideDuration: 9000, variant: 'success' })
      setEmail(data.email)
      setChecked(true)
    } catch (err) { catchError(err) }
  }, [])

  const handleCheckCode = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        code: Yup.string().required('Código de verificação é obrigatório.')
      })
      await schema.validate(data, { abortEarly: false })
      const checkCode = await api.post('recover/code', { email, code: data.code })
      if (checkCode.data.status === 'error') {
        enqueueSnackbar('Código inválido.', { variant: 'error' })
        return
      }
      enqueueSnackbar(checkCode.data.message, { autoHideDuration: 9000, variant: 'success' })
      setCodeChecked(true)
    } catch (err) { catchError(err) }
  }, [email])

  const handleNewPass = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        password: Yup.string().required('Nova senha é obrigatória.'),
        confirmPassword: Yup.string().required('Confirme a nova senha.')
      })
      await schema.validate(data, { abortEarly: false })
      if (data.password === data.confirmPassword) {
        const updatePass = await api.post('recover/update', { email, password: data.password })
        if (updatePass.data.status === 'error') {
          enqueueSnackbar('Código inválido.', { variant: 'error' })
          return
        }
        enqueueSnackbar(updatePass.data.message, { autoHideDuration: 9000, variant: 'success' })
        navigate('/')
        return
      }
      enqueueSnackbar('Senha e confirmação não batem.', { variant: 'error' })
    } catch (err) { catchError(err) }
  }, [email])

  useEffect(() => {
    setEmail('')
    setChecked(false)
    setCodeChecked(false)
    setShowPassword(false)
    setShowConfirmPassword(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <Grid item xs={12} sm={12} md={12} component={Paper} className={classes.paperFather} square>
        <Paper variant='outlined' className={classes.paper} elevation={0}>
          <Typography variant='h4' className={classes.field}>{t('recover.title')}</Typography>
          <Typography>{t('recover.subtitle')}</Typography>
          {codeChecked
            ? (
              <Form ref={formRef} className={classes.form} onSubmit={handleNewPass}>
                <SignInput
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='password'
                  label='Senha'
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  autoComplete='current-password'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => handleClickShowPassword(!showPassword)}>{showPassword ? <Eye /> : <EyeOff />}</IconButton>
                      </InputAdornment>)
                  }}
                />
                <SignInput
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='confirmPassword'
                  label='Senha'
                  type={showConfirmPassword ? 'text' : 'password'}
                  id='confirmPassword'
                  autoComplete='current-password'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => handleClickShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <Eye /> : <EyeOff />}</IconButton>
                      </InputAdornment>)
                  }}
                />
                <Button type='submit' fullWidth variant='contained' color='primary'>Atualizar senha</Button>
              </Form>)
            : (
              <>
                <Form ref={formRef} className={classes.form} onSubmit={handleEmailCheck}>
                  <SignInput
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='email'
                    label='Email'
                    name='email'
                    autoComplete='email'
                    autoFocus
                    disabled={checked}
                  />
                  <Button type='submit' fullWidth variant='contained' color='primary' disabled={checked}>{t('recover.search')}</Button>
                </Form>
                <Form ref={formRef} className={classes.form} onSubmit={handleCheckCode}>
                  <SignInput
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='code'
                    label={t('recover.confirm')}
                    name='code'
                    autoComplete='code'
                    autoFocus
                    disabled={!checked}
                  />
                  <Button type='submit' fullWidth variant='contained' color='primary' disabled={!checked}>{t('recover.confirm')}</Button>
                </Form>
              </>)}
          <IconButton color='inherit' onClick={() => navigate(-1)} className={classes.back}>
            <ChevronLeft />
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Recover

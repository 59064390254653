import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  listItem: {
    padding: '8px'
  },
  dark: {
    padding: '8px',
    backgroundColor: theme.palette.secondary.grey,
    color: 'white',
    '& svg': {
      color: 'white'
    }
  },
  nested: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  }
}))

export default useStyles

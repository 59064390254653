import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Navigation from '../navigation'
import { useAuth } from '../hooks'

const AuthLayout = ({ admin = false, isPrivate = false, sideBar = true, login = false }) => {
  const { user } = useAuth()

  if (admin) {
    if (user?.admin) {
      return <Outlet />
    }
    return <Navigate to={isPrivate ? '/' : '/inicio'} replace />
  } else {
    if (isPrivate && !!user) {
      return <Navigation sideBar={sideBar}><Outlet /></Navigation>
    }
    if (isPrivate === !!user) {
      return <Outlet />
    }
    if (isPrivate !== !!user) {
      if (login) {
        return <Navigate to='/inicio' replace />
      }
      if (isPrivate) {
        return <Navigate to={isPrivate ? '/' : '/inicio'} replace />
      } else {
        return <Outlet />
      }
    }
  }
}

export default AuthLayout

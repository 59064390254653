import React, { useCallback, useState, useRef, useEffect } from 'react'
import { Grid, Paper, Button, ListItemText, Divider, IconButton, Typography, Tooltip, InputAdornment } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Plus } from 'react-feather'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const DiscountIndex = () => {
  const formRef = useRef(null)
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()

  const [stagetypes, setStageTypes] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [discountsValues, setDiscountsValues] = useState([{}])

  const handleValueChange = useCallback((e, index) => {
    const values = [...discountsValues]
    values[index][e.target.id] = e.target.value
    // values[index]._id = discountsValues[index]._id
    setDiscountsValues(values)
  }, [discountsValues])

  const fetchDiscounts = useCallback(async (_id) => {
    const response = await api.get(`discounts/dealer/${_id}`)
    setDiscounts(response.data)
  }, [])

  const fetchStage = useCallback(async () => {
    const response = await api.get('stagetypes')
    let values = []
    for (let i = 0; i < response.data.length; i++) {
      values = [...values, { name: response.data[i].name, stageType: response.data[i]._id, table1: 0, table2: 0, _id: null }]
    }
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < discounts.length; j++) {
        if (values[i].stageType === discounts[j].stageType._id) {
          values[i] = { name: values[i].name, stageType: values[i].stageType, table1: discounts[j].table1, table2: discounts[j].table2, _id: discounts[j]._id }
        }
      }
    }
    // if (discounts.length > 0) {
    //   for (let j = 0; j < discounts.length; j++) {
    //     if (response.data[i]._id === discounts[j].stageType._id) {
    //       values = [...values, { name: response.data[i].name, stageType: response.data[i]._id, table1: discounts[j].table1, table2: discounts[j].table2, _id: discounts[j]._id }]
    //     } else {
    //       values = [...values, { name: response.data[i].name, stageType: response.data[i]._id, table1: 0, table2: 0, _id: null }]
    //     }
    //   }
    // }
    setDiscountsValues(values)
    setStageTypes(response.data)
  }, [discounts])

  const handlerNew = useCallback(() => {
    navigate('/desconto/novo/', { state: { dealer: location.state.dealer } })
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      const response = await api.put('discount', {
        discountsValues,
        dealer: location.state?.dealer._id
      })

      if (response.data.status === 'error') { throw new Error(response.data.message) }
      navigate('/admin/dealer', { state: { dealer: location.state?.dealer } })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [location, discountsValues])

  useEffect(() => {
    fetchStage()
  }, [discounts])

  useEffect(() => {
    fetchDiscounts(location.state.dealer._id)
  }, [])

  return (
    <Body title='DESCONTO'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                DESCONTO - {location.state.dealer.name.toUpperCase()}
              </Typography>
              <Tooltip title='Novo desconto' arrow placement='right'>
                <IconButton size='small' aria-label='novo-desconto' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            <Form
              ref={formRef}
              className={classes.form}
              onSubmit={handleNew}
              initialData={location.state?.discount}
            >
              <Grid container spacing={1}>
                {stagetypes.map((stagetype, index) => (
                  <Grid item xs={12} key={index}>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <ListItemText primary={stagetype.name} />
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          variant='outlined'
                          margin='small'
                          size='small'
                          fullWidth
                          id='table1'
                          helperText='NACIONAL'
                          name='table1'
                          value={discountsValues[index]?.table1}
                          onChange={(e) => handleValueChange(e, index)}
                          InputProps={{ endAdornment: <InputAdornment position='start'>%</InputAdornment> }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          variant='outlined'
                          margin='small'
                          size='small'
                          fullWidth
                          id='table2'
                          helperText='IMPORTADO'
                          name='table2'
                          min={0}
                          max={100}
                          type='number'
                          value={discountsValues[index]?.table2}
                          onChange={(e) => handleValueChange(e, index)}
                          InputProps={{ endAdornment: <InputAdornment position='start'>%</InputAdornment> }}
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: '8px' }} />
                  </Grid>
                ))}
              </Grid>
              <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                Atualizar desconto
              </Button>
            </Form>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DiscountIndex

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  header: {
    background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
    height: '30%'
  },
  body: {
    padding: 50,
    minHeight: '70%'
  },
  title: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 35,
    fontWeight: 'bold'
  },
  prod: {
    padding: 5
  },
  search: {
    marginBottom: 20
  }
}))

export default useStyles

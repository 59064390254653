import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const StagesTypeView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [stagesType, setStagesType] = useState([])

  const fetchStagesType = useCallback(async () => {
    const response = await api.get(`/stagetype/${location.state.stagesType._id}`)
    setStagesType(response.data)
  }, [])

  const handlerEdit = useCallback((stagesType) => {
    navigate('/stage/tipo/editar', { state: { stagesType } })
  }, [])

  useEffect(async () => {
    await fetchStagesType()
  }, [])

  return (
    <Body title='Tipo de performance'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Tipo de performance
              </Typography>
              <Tooltip title='Editar tipo de performance' arrow placement='right'>
                <IconButton size='small' aria-label='editar-tipo-performance' className={classes.plus} onClick={() => handlerEdit(stagesType)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='tipo-performance'>
              <ListItem>
                <ListItemText primary='NOME' secondary={stagesType.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Código' secondary={stagesType.code} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText secondary={`Criado ${moment(stagesType?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default StagesTypeView

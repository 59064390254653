import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'

import useStyles from './styles'

const SignInput = ({ visible = true, name, to, Icon, dark, nested }) => {
  const classes = useStyles()

  return (
    <div className={nested && classes.nested} style={{ background: dark ? '#696969' : '#f15b2b', color: 'white', marginBottom: '4px', borderRadius: '4px' }}>
      {visible &&
        <ListItem
          button
          // activeClassName={classes.active}
          component={RouterLink} to={to}
          className={clsx(dark ? classes.dark : classes.listItem)}
        >
          <ListItemIcon><Icon /></ListItemIcon>
          <ListItemText primary={name} />
        </ListItem>}
    </div>
  )
}

export default SignInput

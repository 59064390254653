import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel, InputAdornment, Radio, RadioGroup } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import InputMask from '../../../components/InputMask'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import ManufacturerDialog from '../../Manufacturer/Dialog'
import StagesTypesDialog from '../../StagesTypes/Dialog'

import useStyles from './style'

const StageForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [clone, setClone] = useState(false)

  const [add, setAdd] = useState([])

  const [origin, setOrigin] = useState('table1')

  const [price, setPrice] = useState()
  const [dolar, setDolar] = useState()
  const [euro, setEuro] = useState()
  const [guaranies, setGuaranies] = useState()
  const [pesoColombiano, setPesoColombiano] = useState()
  const [pesoMexicano, setPesoMexicano] = useState()

  const [stageType, setStageType] = useState()
  const [stageTypes, setStageTypes] = useState([])

  const [manufacturer, setManufacturer] = useState()
  const [manufactures, setManufactures] = useState([])

  const [loadModel, setLoadModel] = useState(false)
  const [model, setModel] = useState()
  const [models, setModels] = useState([])

  const [loadYear, setLoadYear] = useState(false)
  const [year, setYear] = useState()
  const [years, setYears] = useState([])

  const [loadMotor, setLoadMotor] = useState(false)
  const [motor, setMotor] = useState()
  const [motors, setMotors] = useState([])

  const [done, setDone] = useState(false)

  const [activeSite, setActiveSite] = useState(true)
  const [activeDealer, setActiveDealer] = useState(true)

  const [siteFile, setSiteFile] = useState(false)
  const [siteImage, setSiteImage] = useState(false)
  const [fileDynoFile, setDynoFile] = useState(false)
  const [siteFileActive, setSiteFileActive] = useState(true)

  const handleOrigin = useCallback((event) => {
    setOrigin(event.target.value)
  })

  const handleActiveSite = useCallback((event) => {
    setActiveSite(event.target.checked)
  }, [])

  const handleActiveDealer = useCallback((event) => {
    setActiveDealer(event.target.checked)
  }, [])

  const handleActiveSiteFile = useCallback((event) => {
    setSiteFileActive(event.target.checked)
  }, [])

  const handleSiteFile = useCallback((files) => {
    setSiteFile(files[0])
  }, [])

  const handleDynoFile = useCallback((files) => {
    setDynoFile(files[0])
  }, [])

  const handleSiteImage = useCallback((files) => {
    setSiteImage(files[0])
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().required('NOME é obrigatório.'),
        code: Yup.string().required('Código é obrigatório.'),
        newPower: Yup.string().required('Potência é obrigatório.'),
        newTorque: Yup.string().required('Torque é obrigatório.'),
        manufacturer: Yup.string().required('Fabricante é obrigatória.'),
        model: Yup.string().required('Modelo é obrigatório.'),
        motor: Yup.string().required('Motor é obrigatório.'),
        year: Yup.string().required('Ano é obrigatório.'),
        price: Yup.string().required('Preço é obrigatório.')
      })

      await schema.validate(data, { abortEarly: false })

      if (!clone && !!location.state) {
        const response = await api.put(`stage/${location.state?.stage._id}`, {
          name: data.name,
          code: data.code,
          stageType: stageType._id,
          description: data.description,
          dealerDescription: data.dealerDescription,
          price,
          dolar,
          euro,
          guaranies,
          pesoColombiano,
          pesoMexicano,
          manufacturer: manufacturer._id,
          model: model._id,
          year: year._id,
          motor: motor._id,
          origin,
          newPower: data.newPower,
          newTorque: data.newTorque,
          activeSite,
          activeDealer,
          siteFileActive
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (fileDynoFile) {
          const formData = new FormData()
          formData.append('file', fileDynoFile)
          await api.patch(`/stage/${response.data._id}/dyno`, formData)
        }

        if (siteFile) {
          const formData = new FormData()
          formData.append('file', siteFile)
          await api.patch(`/stage/${response.data._id}/site`, formData)
        }

        if (siteImage) {
          const formData = new FormData()
          formData.append('file', siteImage)
          await api.patch(`/stage/${response.data._id}/public`, formData)
        }

        navigate('/stage/', { state: { stage: response.data } })
      } else {
        const response = await api.post('/stage/', {
          name: data.name,
          code: data.code,
          stageType: stageType._id,
          description: data.description,
          dealerDescription: data.dealerDescription,
          price,
          dolar,
          euro,
          guaranies,
          pesoColombiano,
          pesoMexicano,
          manufacturer: manufacturer._id,
          model: model._id,
          year: year._id,
          motor: motor._id,
          origin,
          newPower: data.newPower,
          newTorque: data.newTorque,
          activeSite,
          activeDealer,
          siteFileActive,
          additionals: add
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/stage/', { state: { stage: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [dolar, euro, guaranies, pesoColombiano, pesoMexicano, siteImage, siteFile, fileDynoFile, siteFileActive, activeSite, activeDealer, stageType, manufacturer, model, year, motor, price, origin, clone, add])

  const fetchManufactures = useCallback(async () => {
    const response = await api.get('manufactures')
    setManufactures(response.data)
  }, [])

  const fetchStageTypes = useCallback(async () => {
    const response = await api.get('stagetypes')
    setStageTypes(response.data)
  }, [])

  const fetchModels = useCallback(async () => {
    setLoadModel(false)
    if (manufacturer === undefined) return setLoadModel(false)
    const response = await api.get(`models/manufacturer/${manufacturer?._id}`)
    setModels(response.data)
    if (response.data.length > 0) {
      setLoadModel(true)
    }
  }, [manufacturer])

  const fetchYears = useCallback(async () => {
    setLoadYear(false)
    if (model === undefined) return setLoadYear(false)
    const response = await api.get(`years/model/${model?._id}`)
    setYears(response.data)
    if (response.data.length > 0) {
      setLoadYear(true)
    }
  }, [model])

  const fetchMotor = useCallback(async () => {
    setLoadMotor(false)
    if (year === undefined) return setLoadMotor(false)
    const response = await api.get(`motors/year/${year?._id}`)

    setMotors(response.data)
    if (response.data.length > 0) {
      setLoadMotor(true)
    }
  }, [year])

  const setEdit = useCallback(async (stage) => {
    setStageType(stage.stageType)
    setOrigin(stage.origin)
    setActiveSite(stage.activeSite)
    setActiveDealer(stage.activeDealer)
    setSiteFileActive(stage.siteFileActive)
    setManufacturer(stage.manufacturer)
    setModel(stage.model)
    setYear(stage.year)
    setMotor(stage.motor)
    setPrice(stage.price)
    setDolar(stage.dolar)
    setEuro(stage.euro)
    setGuaranies(stage.guaranies)
    setPesoColombiano(stage.pesoColombiano)
    setPesoMexicano(stage.pesoMexicano)
    setAdd(stage.additionals)
  }, [])

  useEffect(async () => {
    await fetchYears()
  }, [model])

  useEffect(async () => {
    await fetchMotor()
  }, [year])

  useEffect(async () => {
    await fetchModels()
  }, [manufacturer])

  useEffect(async () => {
    await fetchManufactures()
    await fetchStageTypes()
    if (location.state) {
      await setEdit(location.state.stage)
      setClone(location.state.clone)
    }
    setDone(true)
  }, [])

  return (
    <Body title={` ${clone ? 'Clone' : (location.state ? 'Editar' : 'Novo')} stage`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {clone ? 'Clone' : (location.state ? 'Editar' : 'Novo')} stage
            </Typography>
            {done &&
              <Form ref={formRef} className={classes.form} onSubmit={handleNew} initialData={location.state?.stage}>
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='NOME *'
                  name='name'
                  autoFocus
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='code'
                  label='Código *'
                  name='code'
                />
                <ManufacturerDialog manufacturer={manufacturer} manufactures={manufactures} setManufacturer={setManufacturer} fetchManufacturer={fetchManufactures} />
                {/* <Autocomplete
noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='manufacturer'
                  className={classes.select}
                  options={manufactures}
                  value={manufacturer}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label='Selecione uma fabricante *' variant='outlined' />}
                  onChange={(_, newValue) => { setManufacturer(newValue) }}
                /> */}
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  disabled={!loadModel}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='model'
                  className={classes.select}
                  options={models}
                  value={model}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='model' name='model' label='Selecione um modelo *' variant='outlined' />}
                  onChange={(_, newValue) => { setModel(newValue) }}
                />
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  disabled={!loadYear}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='year'
                  className={classes.select}
                  options={years}
                  value={year}
                  getOptionLabel={(option) => `${option.year}`}
                  renderInput={(params) => <Input {...params} id='year' name='year' label='Selecione uma ano *' variant='outlined' />}
                  onChange={(_, newValue) => { setYear(newValue) }}
                />
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  disabled={!loadMotor}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='motor'
                  className={classes.select}
                  options={motors}
                  value={motor}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='motor' name='motor' label='Selecione uma motor *' variant='outlined' />}
                  onChange={(_, newValue) => { setMotor(newValue) }}
                />

                <RadioGroup row value={origin} onChange={handleOrigin} className={classes.divider}>
                  <FormControlLabel value='table1' control={<Radio color='primary' />} label='Tabela nacional' />
                  <FormControlLabel value='table2' control={<Radio color='primary' />} label='Tabela importado' />
                </RadioGroup>

                <StagesTypesDialog stageTypes={stageTypes} fetchStageTypes={fetchStageTypes} stageType={stageType} setStageType={setStageType} />

                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='newPower'
                  label='Ganho de Potência (Valor final) *'
                  name='newPower'
                  type='number'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='newTorque'
                  label='Ganho de Toque (Valor final) *'
                  name='newTorque'
                  type='number'
                />
                <FormControlLabel
                  className={classes.divider}
                  label='ATIVO no site'
                  control={
                    <Switch
                      checked={activeSite}
                      onChange={handleActiveSite}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <FormControlLabel
                  className={classes.divider}
                  label='ATIVO nos dealers'
                  control={
                    <Switch
                      checked={activeDealer}
                      onChange={handleActiveDealer}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='description'
                  label='Descrição do site'
                  name='description'
                  multiline
                  minRows={5}
                />

                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='price'
                  label='Preço *'
                  name='price'
                  InputProps={{ startAdornment: <InputAdornment position='start'>R$</InputAdornment> }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />

                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='dolar'
                  label='Preço em dolar *'
                  name='dolar'
                  InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={dolar}
                  onChange={(e) => setDolar(e.target.value)}
                />

                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='euro'
                  label='Preço em euro*'
                  name='euro'
                  InputProps={{ startAdornment: <InputAdornment position='start'>€</InputAdornment> }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={euro}
                  onChange={(e) => setEuro(e.target.value)}
                />

                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='guaranies'
                  label='Preço em guaranies*'
                  name='guaranies'
                  InputProps={{ startAdornment: <InputAdornment position='start'>PYG</InputAdornment> }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={guaranies}
                  onChange={(e) => setGuaranies(e.target.value)}
                />

                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='pesoColombiano'
                  label='Preço em peso colombiano*'
                  name='pesoColombiano'
                  InputProps={{ startAdornment: <InputAdornment position='start'>COP</InputAdornment> }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={pesoColombiano}
                  onChange={(e) => setPesoColombiano(e.target.value)}
                />

                <InputMask
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='pesoMexicano'
                  label='Preço em peso mexicano*'
                  name='pesoMexicano'
                  InputProps={{ startAdornment: <InputAdornment position='start'>MXN</InputAdornment> }}
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  value={pesoMexicano}
                  onChange={(e) => setPesoMexicano(e.target.value)}
                />

                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='dealerDescription'
                  label='Descrição do dealer'
                  name='dealerDescription'
                  multiline
                  minRows={5}
                />

                <Divider className={classes.divider} />
                <DropzoneArea
                  dropzoneText='Arraste o arquivo do dinamometro ou clique'
                  // acceptedFiles={['image/*']}
                  showAlerts={false}
                  filesLimit={1}
                  maxFileSize={666000000}
                  onChange={(files) => handleDynoFile(files)}
                />
                <Divider className={classes.divider} />
                <DropzoneArea
                  dropzoneText='Arraste o arquivo do site ou clique'
                  // acceptedFiles={['image/*']}
                  showAlerts={false}
                  filesLimit={1}
                  maxFileSize={666000000}
                  onChange={(files) => handleSiteFile(files)}
                />
                <Divider className={classes.divider} />
                <DropzoneArea
                  dropzoneText='Arraste a imagem do site ou clique'
                  acceptedFiles={['image/*']}
                  showAlerts={false}
                  filesLimit={1}
                  maxFileSize={666000000}
                  onChange={(files) => handleSiteImage(files)}
                />
                <Divider className={classes.divider} />
                <FormControlLabel
                  className={classes.divider}
                  label='Ativar arquivo do site?'
                  control={
                    <Switch
                      checked={siteFileActive}
                      onChange={handleActiveSiteFile}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Divider className={classes.divider} />

                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {clone ? 'Clonar' : (location.state ? 'Atualizar' : 'Criar')} stage
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default StageForm

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: { height: '100vh' },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  paperFile: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    height: '100%'
  },
  paperH: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%'
  },
  paperTotal: {
    bottom: 0,
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'row',
    background: theme.palette.primary.main,
    color: 'white'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  select: {
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },
  divider: {
    margin: theme.spacing(2, 0, 2, 0)
  }
}))

export default useStyles

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const MotorsIndex = () => {
  const classes = useStyles()
  const [motors, setMotors] = useState([])
  const navigate = useNavigate()

  const fetchMotor = useCallback(async () => {
    const response = await api.get('motors')
    setMotors(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/motor/novo')
  }, [])

  const handlerView = useCallback((motor) => {
    navigate('/motor/', { state: { motor } })
  }, [])

  useEffect(async () => {
    await fetchMotor()
  }, [])

  return (
    <Body title='Motores'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Motores
              </Typography>
              <Tooltip title='Novo motor' arrow placement='right'>
                <IconButton size='small' aria-label='novo-motor' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {motors.length > 0
              ? (
                <List component='nav' aria-label='Motores'>
                  {motors.map((motor, index) => (
                    <div key={index}>
                      <ListItem button onClick={() => handlerView(motor)}>
                        <ListItemText primary={`${motor.manufacturer?.name} - ${motor.model?.name} - ${motor.year?.year} - ${motor?.name}`} secondary={motor.active ? 'ATIVO' : 'INATIVO'} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <LinearProgress color='secondary' />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default MotorsIndex

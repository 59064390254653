import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const readingView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [reading, setReading] = useState([])

  const fetchReading = useCallback(async () => {
    const response = await api.get(`/reading/${location.state.reading._id}`)
    setReading(response.data)
  }, [])

  const handlerEdit = useCallback((reading) => {
    navigate('/equipamento/editar', { state: { reading } })
  }, [])

  useEffect(async () => {
    await fetchReading()
  }, [])

  return (
    <Body title='Equipamento de leitura'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Equipamento de leitura
              </Typography>
              <Tooltip title='Editar equipamento' arrow placement='right'>
                <IconButton size='small' aria-label='editar-equipamento' className={classes.plus} onClick={() => handlerEdit(reading)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='equipamento'>
              <ListItem>
                <ListItemText primary='NOME' secondary={reading.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Código' secondary={reading.code} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary='ATIVO' secondary={reading.active ? 'Sim' : 'Não'} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText secondary={`Criado ${moment(reading?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default readingView

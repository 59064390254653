/* eslint-disable array-callback-return */
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import axios from 'axios'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const RepresentativeForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [file, setFile] = useState(false)

  const [country, setCountry] = useState('Brasil')

  const [done, setDone] = useState(false)
  const [active, setActive] = useState(true)
  const [dynamometer, setDynamometer] = useState(true)

  const [capital, setCapital] = useState()
  const [states, setStates] = useState(false)
  const [state, setState] = useState()

  const [cities, setCities] = useState(false)
  const [city, setCity] = useState()

  const representative = location.state?.representative

  const handleCountry = useCallback((event) => {
    setCountry(event.target.value)
  })

  const handleActive = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleDynamometer = useCallback((event) => {
    setDynamometer(event.target.checked)
  }, [])

  const handleFile = useCallback((files) => {
    setFile(files[0])
  }, [])

  const fetchStates = useCallback(async () => {
    const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    setStates(response.data)

    if (representative) {
      response.data.map((state) => {
        if (state.nome === representative?.state || state.sigla === representative?.state) {
          setState(state)
        }
      })
    }
  }, [representative])

  const fetchCities = useCallback(async () => {
    if (state) {
      const thisCapital = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.id}/regioes-imediatas`)
      setCapital(thisCapital.data[0]?.nome)

      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.id}/municipios`)
      setCities(response.data)

      if (representative) {
        response.data.map((city) => {
          if (city.nome === representative?.city) {
            setCity(city)
            setDone(true)
          }
        })
      }
    }
  }, [representative, state])

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.'),
          address: Yup.string().required('Endereço obrigatório.'),
          state: Yup.string().when('country', {
            is: 'Brasil',
            then: Yup.string().required('Estado obrigatório.'),
            otherwise: Yup.string()
          })
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`representative/${location.state?.representative._id}`, {
          name: data.name,
          address: data.address,
          contactName: data.contactName,
          email: data.email,
          facebook: data.facebook,
          instagram: data.instagram,
          phone: data.phone,
          zap: data.zap,
          site: data.site,
          youtube: data.youtube,
          description: data.description,
          dynamometers: data.dynamometers,
          state: data.state,
          city: data.city,
          capital: data.city === capital,
          country,
          dynamometer,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          await api.patch(`/representative/${response.data._id}/logo`, formData)
        }

        navigate('/representante/', { state: { representative: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.'),
          address: Yup.string().required('Endereço obrigatório.'),
          state: Yup.string().when('country', {
            is: 'Brasil',
            then: Yup.string().required('Estado obrigatório.'),
            otherwise: Yup.string()
          })
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/representative', {
          name: data.name,
          address: data.address,
          contactName: data.contactName,
          email: data.email,
          facebook: data.facebook,
          instagram: data.instagram,
          dynamometers: data.dynamometers,
          phone: data.phone,
          zap: data.zap,
          site: data.site,
          youtube: data.youtube,
          description: data.description,
          state: data.state,
          city: data.city,
          capital: data.city === capital,
          dynamometer,
          country,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          await api.patch(`/representative/${response.data._id}/logo`, formData)
        }

        navigate('/representante/', { state: { representative: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [country, city, state, capital, active, dynamometer, file])

  const setEdit = useCallback(async (representative) => {
    setActive(representative.active)
    setCountry(representative.country)
    setDynamometer(representative.dynamometer)
    fetchStates()
    setState(representative.state)
  }, [])

  useEffect(async () => {
    fetchCities()
    if (location.state) { setCity(location.state.representative.city) }
  }, [state])

  useEffect(async () => {
    fetchStates()
    if (location.state) { await setEdit(location.state.representative) }
    setDone(true)
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Novo'} representante`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Novo'} representante
            </Typography>
            {done &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.representative}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='NOME *'
                  name='name'
                  autoFocus
                />

                <Typography variant='h6' style={{ marginTop: '8px' }}>
                  Pais *
                </Typography>
                <RadioGroup row value={country} onChange={handleCountry}>
                  <FormControlLabel value='Brasil' control={<Radio color='primary' />} label='Brasil' />
                  <FormControlLabel value='Mexico' control={<Radio color='primary' />} label='Mexico' />
                  <FormControlLabel value='Paraguai' control={<Radio color='primary' />} label='Paraguai' />
                  <FormControlLabel value='Colômbia' control={<Radio color='primary' />} label='Colômbia' />
                </RadioGroup>

                {states && country === 'Brasil' &&
                  <Autocomplete
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    className={classes.select}
                    options={states}
                    value={state}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => <Input {...params} id='state' name='state' label='Selecione o estado *' variant='outlined' />}
                    onChange={(event, newValue) => { setState(newValue) }}
                  />}
                {cities && country === 'Brasil' &&
                  <Autocomplete
                    noOptionsText='Nenhuma opção'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    className={classes.select}
                    options={cities}
                    value={city}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => <Input {...params} id='city' name='city' label='Selecione a cidade *' variant='outlined' />}
                    onChange={(event, newValue) => { setCity(newValue) }}
                  />}
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='address'
                  label='Endereço *'
                  name='address'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='email'
                  label='Email'
                  name='email'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='phone'
                  label='Telefone'
                  name='phone'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='zap'
                  label='Whatsapp'
                  name='zap'
                  helperText='Somente números'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='contactName'
                  label='Contato'
                  name='contactName'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='site'
                  label='Site'
                  name='site'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='facebook'
                  label='Facebook'
                  name='facebook'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='instagram'
                  label='Instagram'
                  name='instagram'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='youtube'
                  label='Youtube'
                  name='youtube'
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='description'
                  label='Descrição'
                  name='description'
                  rows={5}
                  multiline
                />
                <Divider className={classes.divider} />
                <FormControlLabel
                  label='Dinamômetro?'
                  control={
                    <Switch
                      checked={dynamometer}
                      onChange={handleDynamometer}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='dynamometers'
                  label='Lista de dinamômetros'
                  name='dynamometers'
                  rows={5}
                  multiline
                />
                <Divider className={classes.divider} />
                <DropzoneArea
                  dropzoneText='Arraste a logo do representante ou clique'
                  acceptedFiles={['image/*']}
                  showAlerts={false}
                  filesLimit={1}
                  maxFileSize={666000000}
                  onChange={(files) => handleFile(files)}
                />
                <Divider className={classes.divider} />
                <FormControlLabel
                  label='Representante ativa?'
                  control={
                    <Switch
                      checked={active}
                      onChange={handleActive}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state ? 'Atualizar' : 'Criar'} representante
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default RepresentativeForm

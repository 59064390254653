import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import { Container } from '@material-ui/core'

import useStyles from './style'

const Body = forwardRef(({ children, title = '', ...rest }, ref) => {
  const classes = useStyles()

  return (
    <div ref={ref} {...rest}>
      <Helmet><title>{title}</title></Helmet>
      <Container className={classes.container}>
        {children}
      </Container>
    </div>
  )
})

export default Body

import React from 'react'
import { Paper, Typography, Link, IconButton, Divider, Container } from '@material-ui/core'
import { Facebook, Instagram, Youtube, ExternalLink } from 'react-feather'
import WhatsApp from '@material-ui/icons/WhatsApp'
import useStyles from './style'

const RepresentativeInfo = ({ blobs, dealer, key, loading }) => {
  const classes = useStyles()

  return (
    <Paper key={key} style={{ padding: 20, marginBottom: 20 }} elevation={3}>
      <Container disableGutters style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant='h5' color='primary'>
          {dealer.name.toUpperCase()}
        </Typography>
        {dealer.logo?.name && !loading &&
          <img
            src={blobs[dealer.logo?.name]}
            className={classes.miniImage}
          />}
      </Container>

      {dealer.contactName && <Typography variant='h6' gutterBottom color='primary'>Contato: {dealer.contactName.toUpperCase()}</Typography>}
      {dealer.phone &&
        <a href={`tel:${dealer.phone}`} style={{ textDecoration: 'none' }}>
          <Typography variant='subtitle1' gutterBottom>TELEFONE: {dealer.phone.toUpperCase()}</Typography>
        </a>}

      {dealer.email &&
        <Typography variant='subtitle1' gutterBottom>EMAIL: {dealer.email.toUpperCase()}</Typography>}

      {dealer.dynamometer && dealer.dynamometers &&
        <Typography variant='subtitle1' gutterBottom>DINAMOMETROS: {dealer.dynamometers.toUpperCase()}</Typography>}

      {!!dealer.zap &&
        <Link href={`https://wa.me/55${dealer.zap}`} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
          <IconButton edge='start' className={classes.footerIcon}>
            <WhatsApp />
          </IconButton>
        </Link>}

      {!!dealer.site &&
        <Link href={'//' + dealer.site} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
          <IconButton edge='start' className={classes.footerIcon}>
            <ExternalLink size={25} />
          </IconButton>
        </Link>}

      {!!dealer.facebook &&
        <Link href={'//' + dealer.facebook} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
          <IconButton edge='start' className={classes.footerIcon}>
            <Facebook size={25} />
          </IconButton>
        </Link>}
      {!!dealer.instagram &&
        <Link href={'//' + dealer.instagram} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
          <IconButton edge='start' className={classes.footerIcon}>
            <Instagram size={25} />
          </IconButton>
        </Link>}
      {!!dealer.youtube &&
        <Link href={'//' + dealer.youtube} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
          <IconButton edge='start' className={classes.footerIcon}>
            <Youtube size={25} />
          </IconButton>
        </Link>}
      <Divider style={{ margin: '18x' }} />
      {dealer.address &&
        <Typography variant='subtitle2'>ENDEREÇO: {dealer.address.toUpperCase()}</Typography>}
      {dealer.city && dealer.state &&
        <Typography variant='subtitle2'>{dealer.city.toUpperCase()} - {dealer.state.toUpperCase()}</Typography>}
    </Paper>
  )
}

export default RepresentativeInfo

import React from 'react'
import Body from '../../components/Body'

const PageNotFound = () => {
  return (
    <Body title='404'>
      <a>
        404
      </a>
    </Body>
  )
}

export default PageNotFound

import firebase from 'firebase/app'
import 'firebase/messaging'

import { firebaseConfig } from '../config'
import api from './api'

const setNotifications = async (userId, token) => {
  if (firebase.messaging.isSupported()) {
    if (firebase.apps.length === 0) { firebase.initializeApp(firebaseConfig) }
    const messaging = firebase.messaging()

    await messaging.getToken({ apidKey: firebaseConfig.apiKey }).then((currentToken) => {
      if (currentToken) {
        window.localStorage.setItem('#perfortech:notification', currentToken)
        api.post('/notification', { userId, token, notification: currentToken })
      }
    }).catch(() => { })

    // firebase
    //   .messaging()
    //   .usePublicVapidKey(
    //     "BDYE2EYHdIp8qHjTKcJYPvO4PgaAH2pSruP55FOtNs5jWsgdeg7YK6OgJ0daSu21kN7aSzU19NRXRqC4bfITZYQ "
    //   );

    messaging.onMessage((payload) => {
      const protocol = payload.data.protocol
      const notificationTitle = payload.data.title
      const notificationOptions = {
        body: payload.data.body,
        icon: 'https://perfortech.prodigymotorbike.com.br/perfortech.png',
        vibrate: [200, 100, 200, 100, 200, 100, 200],
        silent: false
      }

      if (!('Notification' in window)) {
        return false
      } else if (window.Notification.permission === 'granted') {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
          navigator.serviceWorker.register('firebase-messaging.js').then(function (swReg) {
            swReg.addEventListener('notificationclick', (event) => {
              event.notification.close()
              window.clients.openWindow(`https://perfortech.prodigymotorbike.com.br/pedido/${protocol}`)
            })
          }).catch(function (error) {
            console.error('Erro no service worker', error)
          })
          navigator.serviceWorker.ready.then((swReg) => { swReg.showNotification(notificationTitle, notificationOptions) })
        } else { window.pushButton.textContent = 'Sem suporte a notificação push' }
      }
    })
  }
}

export default setNotifications

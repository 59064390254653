import React, { useMemo, useState, useEffect } from 'react'
import { Typography, Table as MaUTable, TableBody, TableCell, TableHead, TableRow, IconButton, Paper } from '@material-ui/core'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table'
import { ArrowDown, ArrowUp, Search } from 'react-feather'
import moment from 'moment'

import GlobalFilterInput from '../GlobalFilterInput'
import statusType from '../../services/statusType'

const RequestTable = ({ requests }) => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 25000)
    return function cleanup () { clearInterval(timer) }
  }, [])

  const columns = useMemo(() => [{
    Header: 'PROTOCOLO',
    accessor: (originalRow) => `${originalRow.protocol} ${originalRow.code ? `(${originalRow.code})` : ''}`
  }, {
    Header: 'STATUS',
    accessor: (originalRow) => statusType[originalRow.timeline[originalRow.timeline.length - 1]?.name].toUpperCase()
  }, {
    Header: 'DEALER',
    accessor: (originalRow) => originalRow.dealer?.name
  }, {
    Header: 'STAGE',
    accessor: (originalRow) => `${originalRow.manufacturer?.name} - ${originalRow.model?.name} - ${originalRow.year?.year} - ${originalRow.motor?.name} - ${originalRow.stage?.name}`
  }, {
    Header: 'PLACA',
    // put iphen in thyrd place in plate
    accessor: (originalRow) => `${originalRow.plate.substring(0, 3)}-${originalRow.plate.substring(3, originalRow.plate.length)}`
  }, {
    Header: 'PRAZO',
    accessor: (originalRow) => `${originalRow.deadline === 'NEXT' ? 'Próximo dia'.toUpperCase() : (originalRow.deadline === 'URGENT' ? 'Urgente'.toUpperCase() : `${originalRow.deadline} HORAS`.toUpperCase())}`
  }, {
    Header: '-',
    accessor: (originalRow) => {
      // let filterFinished = originalRow.timeline.filter((timeline) => { return timeline.name === 'NEW' })
      // if (filterFinished.lenght > 0) { return '' }
      // let filterHasArrived = originalRow.status.timeline((status) => { return timeline.name === 'NEW' })
      // if (filterHasArrived[filterHasArrived.length - 1]) {
      // }
      const startDate = moment(originalRow.createdAt)
      const nowDate = moment(date)
      const ms = nowDate.diff(startDate)
      const d = moment.duration(ms)

      if (originalRow.newFiles?.length > 0) {
        return <Paper variant='outlined' style={{ background: 'blue', width: '20px', height: '20px' }} />
      } else {
        if (d.hours() === 1 && d.minutes() > 30) { return <Paper variant='outlined' style={{ background: 'yellow', width: '20px', height: '20px' }} /> }
        if (d.hours() > 2) { return <Paper variant='outlined' style={{ background: 'red', width: '20px', height: '20px' }} /> }
      }
      return <Paper variant='outlined' style={{ background: 'green', width: '20px', height: '20px' }} />
    }
  }
    // , {
    //   Header: 'Prazo',
    //   accessor: (originalRow) => `${moment(originalRow?.serviceDate).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`,
    // }
    //   , {
    //   Header: 'CHASSI',
    //   accessor: (originalRow) => originalRow.chassi
    // }
  ], [date])

  const filterTypes = useMemo(() => ({
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
      })
    }
  }), [])

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const { getTableProps, headerGroups, prepareRow, rows, state, visibleColumns, preGlobalFilteredRows, setGlobalFilter } = useTable({ columns, data: requests, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [{
        id: 'pedido',
        Cell: ({ row }) => (<IconButton size='small' href={`/pedido/${row.original.protocol}`}><Search /></IconButton>)
      }, ...columns])
    }
  )

  return (
    <>
      {requests.length > 0
        ? (
          <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={visibleColumns.length} style={{ textAlign: 'left' }}>
                  <GlobalFilterInput preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                </TableCell>
              </TableRow>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                      {column.render('Header')}
                      {column.isSorted ? column.isSortedDesc ? <ArrowDown size={15} /> : <ArrowUp size={15} /> : ''}
                      <>{column.canFilter ? column.render('Filter') : null}</>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <TableRow hover {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => <TableCell {...cell.getCellProps()} key={index}>{cell.render('Cell')}</TableCell>)}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>)
        : <Typography variant='h6' color='textSecondary' style={{ alignSelf: 'center' }}>{'Nenhum pedido no momento'.toUpperCase()}</Typography>}
    </ >
  )
}

export default RequestTable

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, Box, Typography, LinearProgress, Button } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import BillTable from '../../../components/BillTable'
import Body from '../../../components/Body'

import { useAuth } from '../../../hooks'
import api from '../../../services/api'
import { currency } from '../../../services/atom'

import useStyles from './style'

const PedidoIndex = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { user } = useAuth()

  const [loading, setLoading] = useState(true)
  const [bills, setBills] = useState([])

  const [faturado, setFaturado] = useState(0)
  const [devedor, setDevedor] = useState(0)
  const [pago, setPago] = useState(0)

  const fetchBill = useCallback(async () => {
    setLoading(true)
    if (user.admin) {
      const response = await api.get('bills')

      let faturado = 0
      let value = 0
      let paid = 0
      for (const i in response.data) {
        faturado += response.data[i].real
        value += response.data[i].value
        paid += response.data[i].paid
      }
      setFaturado(faturado)
      setPago(paid)
      setDevedor(value - paid)

      setBills(response.data)
    } else {
      const response = await api.get(`/bills/${user?.dealer._id}`)

      let faturado = 0
      let value = 0
      let paid = 0
      for (const i in response.data) {
        faturado += response.data[i].real
        value += response.data[i].value
        paid += response.data[i].paid
      }
      setFaturado(faturado)
      setPago(paid)
      setDevedor(value - paid)

      setBills(response.data)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchBill()
  }, [])

  return (
    <Body title='TODAS FATURAS'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                TODAS FATURAS [ {bills.length} ]
              </Typography>
              <Box flexGrow={1} />
              {/* <Button variant='outlined' color='primary'>Exportar</Button> */}
              <Button variant='contained' color='primary' style={{ marginLeft: '10px' }} onClick={() => navigate('/financeiro')}>PARCIAIS</Button>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: '16px', marginTop: '32px' }}>
                <Paper
                  style={{
                    padding: '24px',
                    transitionProperty: 'box-shadow',
                    boxShadow: 'none',
                    backgroundImage: 'none',
                    borderRadius: '32px',
                    backgroundColor: '#00CC33',
                    color: 'rgb(255, 255, 255)'
                  }}
                >
                  <Typography variant='h3'>
                    {currency(faturado)}
                  </Typography>
                  <Typography variant='h5' style={{ marginTop: 1, color: '#ffffff' }}>
                    FATURADO
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: '16px' }}>
                <Paper
                  style={{
                    padding: '24px',
                    transitionProperty: 'box-shadow',
                    boxShadow: 'none',
                    backgroundImage: 'none',
                    borderRadius: '32px',
                    backgroundColor: 'grey',
                    color: 'rgb(255, 255, 255)'
                  }}
                >
                  <Typography variant='h3'>
                    {currency(devedor)}
                  </Typography>
                  <Typography variant='h5' style={{ marginTop: 1, color: '#ffffff' }}>
                    {user.admin ? 'AGUARDANDO PAGAMENTO' : 'DEVEDOR'}
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6} style={{ marginBottom: '16px' }}>
                <Paper
                  style={{
                    padding: '24px',
                    transitionProperty: 'box-shadow',
                    boxShadow: 'none',
                    backgroundImage: 'none',
                    borderRadius: '32px',
                    backgroundColor: (devedor > pago) ? '#00CC33' : 'grey',
                    color: 'rgb(255, 255, 255)'
                  }}
                >
                  <Typography variant='h3'>
                    {currency(pago)}
                  </Typography>
                  <Typography variant='h5' sx={{ mt: 1, color: '#ffffff69' }}>
                    PAGO
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            {loading
              ? <LinearProgress />
              : <BillTable bills={bills} />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default PedidoIndex

import React, { useMemo } from 'react'
import { Typography, Table as MaUTable, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table'
import { ArrowDown, ArrowUp, Search } from 'react-feather'

import GlobalFilterInput from '../GlobalFilterInput'
import { currency } from '../../services/atom'

const BillsTable = ({ bills }) => {
  const columns = useMemo(() => [{
    Header: 'Dealer',
    accessor: (originalRow) => originalRow.dealer?.name.toUpperCase()
  }, {
    Header: 'Mês',
    accessor: (originalRow) => originalRow.month?.toUpperCase()
  }, {
    Header: 'Pedidos',
    accessor: (originalRow) => originalRow.requests.length
  }, {
    Header: 'Situação',
    accessor: (originalRow) =>
      <Typography color={originalRow.paid === originalRow.value ? 'primary' : 'textPrimary'}>
        {originalRow.paid === originalRow.value ? 'PAGA' : 'PARCIAL'}
      </Typography>
  }, {
    Header: 'Pago',
    accessor: (originalRow) => currency(originalRow.paid)
  }, {
    Header: 'Total',
    accessor: (originalRow) => currency(originalRow.value)
  }], [])

  const filterTypes = useMemo(() => ({
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
      })
    }
  }), [])

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const { getTableProps, headerGroups, prepareRow, rows, state, visibleColumns, preGlobalFilteredRows, setGlobalFilter } = useTable({ columns, data: bills, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [{
        id: 'pedido',
        Cell: ({ row }) => (<IconButton size='small' href={`/fatura/${row.original._id}`}><Search /></IconButton>)
      }, ...columns])
    }
  )

  return (
    <>
      {bills.length > 0
        ? (
          <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={visibleColumns.length} style={{ textAlign: 'left' }}>
                  <GlobalFilterInput preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                </TableCell>
              </TableRow>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                      {column.render('Header')}
                      {column.isSorted ? column.isSortedDesc ? <ArrowDown size={15} /> : <ArrowUp size={15} /> : ''}
                      <>{column.canFilter ? column.render('Filter') : null}</>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <TableRow hover {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => <TableCell {...cell.getCellProps()} key={index}>{cell.render('Cell')}</TableCell>)}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>)
        : <Typography variant='h6' color='textSecondary' style={{ alignSelf: 'center' }}>Nenhuma fatura no momento</Typography>}
    </ >
  )
}

export default BillsTable

import React from 'react'
import { Hexagon, Plus, XCircle, CheckCircle, Activity, Clock, DollarSign, MapPin } from 'react-feather'

export default ({ status }) => {
  switch (status) {
    case 'NEW':
    case 'budget_created':
    case 'new':
      return <Plus />
    case 'CANCELED':
    case 'UNAUTHORIZED':
    case 'bill_canceled':
    case 'budget_canceled':
    case 'additional_unauthorized':
      return <XCircle />
    case 'AUTHORIZED':
    case 'additional_authorized':
    case 'budget_company_accepted':
    case 'budget_accepted':
    case 'bill_accepted':
      return <CheckCircle />
    case 'HAS_ARRIVED':
    case 'budget_has_arrived':
    case 'budget_survey_arrived':
      return <MapPin />
    case 'SERVICE_STARTED':
    case 'budget_survey_iniciated':
    case 'budget_service_started':
      return <Activity />
    case 'ANALYST_FINISHED':
    case 'budget_analyst_finished':
    case 'COMPLETE_SERVICE':
    case 'budget_service_completed':
      return <Clock />
    case 'BILLED':
    case 'bill_paid':
    case 'paid':
      return <DollarSign />
    default:
      return <Hexagon />
  }
}

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  listItem: {
    padding: '8px'
  },
  dark: {
    padding: '8px',
    color: 'white',
    '& svg': {
      color: 'white'
    }
  },
  nested: {
    marginLeft: theme.spacing(3)
  }
}))

export default useStyles

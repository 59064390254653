import React, { useCallback, useRef, useState } from 'react'
import { Button, Paper, Grid, Typography, Link, InputAdornment, IconButton } from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { Eye, EyeOff } from 'react-feather'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { getValidationsErros } from '../../services/atom'
import Copyright from '../../components/Copyright'
import { useAuth } from '../../hooks'
import SignInput from '../../components/Input'
import useStyles from './style'

const SignIn = () => {
  const classes = useStyles()
  const formRef = useRef(null)
  const { singIn } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = useCallback((state) => { setShowPassword(state) }, [])
  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        email: Yup.string().email('Digite um email válido').required('Email é obrigatório'),
        password: Yup.string().required('Senha é obrigatória')
      })
      await schema.validate(data, { abortEarly: false })
      await singIn({ email: data.email, password: data.password })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      if (err.message) {
        enqueueSnackbar(err.message, { variant: 'error' })
        return
      }
      enqueueSnackbar(err, { variant: 'error' })
    }
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <Grid item xs={12} sm={12} md={12} component={Paper} className={classes.paperFather}>
        <Paper variant='outlined' className={classes.paper} square>
          <Typography variant='h4'><img src='/perfortech.png' className={classes.logo} /></Typography>
          <Typography>Faça seu login</Typography>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <SignInput
              margin='normal'
              fullWidth
              id='email'
              label='Email'
              name='email'
              autoComplete='email'
              autoFocus
            />
            <SignInput
              margin='normal'
              fullWidth
              name='password'
              label='Senha'
              type={showPassword ? 'text' : 'password'}
              id='password'
              autoComplete='current-password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => handleClickShowPassword(!showPassword)}>{showPassword ? <Eye /> : <EyeOff />}</IconButton>
                  </InputAdornment>)
              }}
            />
            <Button type='submit' fullWidth variant='contained' color='secondary'>Entrar</Button>
            <Grid className={classes.forgot}>
              <Link variant='body2' color='textSecondary' component={RouterLink} to='/ajuda-com-login'>Esqueceu a sua senha?</Link>
            </Grid>
            <Grid className={classes.footer}>
              <Copyright />
              <Link href='#' target='_blank' rel='noopener' variant='body2' color='textSecondary'>Politica de Privacidade</Link>
            </Grid>
          </Form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SignIn

import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const ReadingsIndex = () => {
  const classes = useStyles()
  const [readings, setReadings] = useState([])
  const navigate = useNavigate()

  const fetchReadings = useCallback(async () => {
    const response = await api.get('readings/all')
    setReadings(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/equipamento/novo')
  }, [])

  const handlerView = useCallback((reading) => {
    navigate('/equipamento/', { state: { reading } })
  }, [])

  useEffect(async () => {
    await fetchReadings()
  }, [])

  return (
    <Body title='Equipamentos de leitura'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Equipamentos de leitura
              </Typography>
              <Tooltip title='Nova equipamentos de leitura' arrow placement='right'>
                <IconButton size='small' aria-label='nova-equipamento' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {readings.length > 0
              ? (
                <List component='nav' aria-label='equipamentos'>
                  {readings.map((reading, index) => (
                    <div key={index}>
                      <ListItem button onClick={() => handlerView(reading)}>
                        <ListItemText primary={reading.name} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <LinearProgress color='secondary' />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ReadingsIndex

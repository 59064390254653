import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, FormControlLabel, Switch } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const ConfigurationsForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [active, setActive] = useState(true)

  const [file, setFile] = useState(false)

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  })

  const handleFile = useCallback((files) => {
    setFile(files[0])
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      // const schema = Yup.object().shape({
      //   name: Yup.string().required('NOME é obrigatório.')
      // })
      // await schema.validate(data, { abortEarly: false })

      const response = await api.post('/configuration', {
        whatsapp: data.whatsapp,
        store: data.store,
        twitter: data.twitter,
        facebook: data.facebook,
        instagram: data.instagram,
        youtube: data.youtube,
        linkedin: data.linkedin,
        name: data.name,
        email: data.email,
        host: data.host,
        port: data.port,
        user: data.user,

        behindHead: data.behindHead,
        behindHeadEng: data.behindHeadEng,
        behindBody: data.behindBody,
        behindBodyEng: data.behindBodyEng,
        aboutEng: data.aboutEng,
        staffEng: data.staffEng,
        privacyEng: data.privacyEng,
        conditionsEng: data.conditionsEng,

        password: data.password,
        privacy: data.privacy,
        conditions: data.conditions,
        about: data.about,
        staff: data.staff,
        behind: data.behind,
        publicValues: active
      })

      if (response.data.status === 'error') { throw new Error(response.data.message) }

      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        await api.patch('/configuration/head', formData)
      }

      navigate('/configuracoes')
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [active, file])

  useEffect(() => {
    if (location.state) {
      setActive(location.state?.configuration.publicValues)
    }
  }, [])

  const InputDefault = useCallback(({ id, label, multiline }) => (
    <Input
      variant='outlined'
      margin='normal'
      fullWidth
      id={id}
      label={label}
      name={id}
      multiline={multiline}
      minRows={5}
    />
  ), [])

  return (
    <Body title='Editar configurações'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              Editar configurações
            </Typography>
            <Form
              ref={formRef}
              className={classes.form}
              onSubmit={handleNew}
              initialData={location.state?.configuration}
            >
              <Typography variant='h5'>
                Social & Home
              </Typography>
              <InputDefault id='store' label='Loja' />
              <InputDefault id='whatsapp' label='Whatsapp' />
              <InputDefault id='twitter' label='Twitter' />
              <InputDefault id='facebook' label='Facebook' />
              <InputDefault id='instagram' label='Instagram' />
              <InputDefault id='youtube' label='Youtube' />
              <InputDefault id='linkedin' label='Linkedin' />
              <InputDefault id='behind' label='Behind scene' />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputDefault id='behindHead' label='Behind scene titulo' />
                </Grid>
                <Grid item xs={6}>
                  <InputDefault id='behindHeadEng' label='Behind scene title' />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputDefault id='behindBody' label='Behind scene corpo' />
                </Grid>
                <Grid item xs={6}>
                  <InputDefault id='behindBodyEng' label='Behind scene body' />
                </Grid>
              </Grid>
              <DropzoneArea
                dropzoneText='Arraste o header ou clique'
                acceptedFiles={['image/*']}
                showAlerts={false}
                filesLimit={1}
                maxFileSize={666000000}
                onChange={(files) => handleFile(files)}
              />
              {/*
                <Typography variant="h5">
                  Email
                </Typography>
                <InputDefault id="name" label="NOME" />
                <InputDefault id="email" label="Email" />
                <InputDefault id="host" label="Host" />
                <InputDefault id="port" label="Porta" />
                <InputDefault id="user" label="Usuário" />
                <InputDefault id="password" label="Senha" />
               */}

              <Typography variant='h5'>
                Sobre
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputDefault id='about' label='Sobre' multiline />
                </Grid>
                <Grid item xs={6}>
                  <InputDefault id='aboutEng' label='About' multiline />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputDefault id='staff' label='Equipe' multiline />
                </Grid>
                <Grid item xs={6}>
                  <InputDefault id='staffEng' label='Staff' multiline />
                </Grid>
              </Grid>

              <Typography variant='h5'>
                Termos
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputDefault id='privacy' label='Termos de privacidade' multiline />
                </Grid>
                <Grid item xs={6}>
                  <InputDefault id='privacyEng' label='Terms of privacy' multiline />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputDefault id='conditions' label='Termos de uso' multiline />
                </Grid>
                <Grid item xs={6}>
                  <InputDefault id='conditionsEng' label='Terms of use' multiline />
                </Grid>
              </Grid>

              <FormControlLabel
                label='Valores publicos?'
                control={
                  <Switch
                    checked={active}
                    onChange={handleSwitch}
                    name='active'
                    color='primary'
                  />
                }
              />
              <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                Atualziar configurações
              </Button>
            </Form>
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ConfigurationsForm

import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { validateCPF } from 'validations-br'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import InputMask from '../../../../components/InputMask'
import Input from '../../../../components/Input'
import Body from '../../../../components/Body'

import { getValidationsErros } from '../../../../services/atom'
import api from '../../../../services/api'

import useStyles from './style'

const DealerUserForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [active, setActive] = useState(true)

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  })

  const handleNew = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        name: Yup.string().required('NOME obrigatório.'),
        cpf: Yup.string().required('CPF obrigatório.'),
        email: Yup.string().required('Email obrigatório.').email('Digite um email válido.'),
        password: !location.state?.user && Yup.string().required('Senha obrigatória.'),
        passwordConfirmation: !location.state?.user && Yup.string().required('Confirme a senha.')
      })
      await schema.validate(data, { abortEarly: false })

      if (location?.state?.user) {
        const cpfIsValid = validateCPF(data.cpf)
        if (!cpfIsValid && !location.state) {
          formRef.current?.setErrors({ cpf: 'CPF inválido' })
          return
        }

        const response = await api.put(`user/${location?.state?.user._id}`, {
          admin: false,
          dealer: location?.state?.dealer._id,
          name: data.name,
          cpf: data.cpf,
          email: data.email,
          phone: data.phone,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/admin/dealer/usuarios', { state: { dealer: location.state.dealer } })
      } else {
        const cpfIsValid = validateCPF(data.cpf)
        if (!cpfIsValid && !location.state) {
          formRef.current?.setErrors({ cpf: 'CPF inválido' })
          return
        }

        const response = await api.post('user', {
          admin: false,
          dealer: location?.state?.dealer._id,
          name: data.name,
          cpf: data.cpf,
          email: data.email,
          phone: data.phone,
          password: data.password,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/admin/dealer/usuarios', { state: { dealer: location.state.dealer } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [active, location])

  useEffect(async () => {
    if (location?.state?.user) {
      setActive(location.state?.user?.active)
    }
  }, [])

  return (
    <Body title={` ${location.state.user ? 'Editar' : 'Novo'} usuário`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state.user ? 'Editar' : 'Novo'} usuário - {location.state.dealer?.name}
            </Typography>
            <Form
              ref={formRef}
              className={classes.form}
              onSubmit={handleNew}
              initialData={location.state?.user}
            >
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='name'
                label='NOME *'
                name='name'
                autoFocus
              />
              <InputMask
                variant='outlined'
                margin='normal'
                fullWidth
                id='cpf'
                label='CPF *'
                name='cpf'
                format='###.###.###-##'
                disabled={!!location.state?.user}
              />
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='email'
                label='Email *'
                name='email'
              />
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='phone'
                label='Telefone'
                name='phone'
              />
              {!location.state.user &&
                <>
                  <Input
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='password'
                    type='password'
                    label='Digite a senha *'
                    name='password'
                  />
                  <Input
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='passwordConfirmation'
                    type='password'
                    label='Confirme a senha *'
                    name='passwordConfirmation'
                  />
                </>}
              <Divider className={classes.divider} />
              <FormControlLabel
                label='Usuário ativo'
                control={
                  <Switch
                    checked={active}
                    onChange={handleSwitch}
                    name='active'
                    color='primary'
                  />
                }
              />
              <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                {location.state.user ? 'Atualizar' : 'Criar'} usuário
              </Button>
            </Form>
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DealerUserForm

import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import OpenNavigation from '../../components/OpenNavigation'

import useStyles from './style'

const SignIn = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <Grid item xs={12} sm={12} md={12} style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')", justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
        <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
          {t('country.title').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        <Grid container spacing={1}>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row' }}>
            <img src='/br.png' className={classes.miniImage} />
            <Button variant='outlined' fullWidth color='primary' onClick={() => navigate('/dealers/brasil')}>
              <Typography variant='body1' className={classes.subtitle}>BRASIL</Typography>
            </Button>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row' }}>
            <img src='/mx.png' className={classes.miniImage} />
            <Button variant='outlined' fullWidth color='primary' onClick={() => navigate('/dealers/mexico', { state: { country: 'MEXICO' } })}>
              <Typography variant='body1' className={classes.subtitle}>MEXICO</Typography>
            </Button>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row' }}>
            <img src='/pa.png' className={classes.miniImage} />
            <Button variant='outlined' fullWidth color='primary' onClick={() => navigate('/dealers/paraguai', { state: { country: 'PARAGUAI' } })}>
              <Typography variant='body1' className={classes.subtitle}>PARAGUAI</Typography>
            </Button>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row' }}>
            <img src='/co.png' className={classes.miniImage} />
            <Button variant='outlined' fullWidth color='primary' onClick={() => navigate('/dealers/colombia', { state: { country: 'COLÔMBIA' } })}>
              <Typography variant='body1' className={classes.subtitle}>COLÔMBIA</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignIn

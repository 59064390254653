import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const ManufacturesIndex = () => {
  const classes = useStyles()
  const [manufactures, setManufactores] = useState([])
  const navigate = useNavigate()

  const fetchmanufactures = useCallback(async () => {
    const response = await api.get('manufactures')
    setManufactores(response.data)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/fabricante/nova')
  }, [])

  const handlerView = useCallback((manufacturer) => {
    navigate('/fabricante/', { state: { manufacturer } })
  }, [])

  useEffect(async () => {
    await fetchmanufactures()
  }, [])

  return (
    <Body title='Fabricantes'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Fabricantes
              </Typography>
              <Tooltip title='Novo fabricante' arrow placement='right'>
                <IconButton size='small' aria-label='novo-fabricante' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {manufactures.length > 0
              ? (
                <List component='nav' aria-label='fabricantes'>
                  {manufactures.map((manufacturer, index) => (
                    <div key={index}>
                      <ListItem button onClick={() => handlerView(manufacturer)}>
                        <ListItemText primary={manufacturer?.name} secondary={manufacturer.active ? 'ATIVO' : 'INATIVO'} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <LinearProgress color='secondary' />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default ManufacturesIndex

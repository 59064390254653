import React, { useMemo, useCallback, useState } from 'react'
import { Typography, Table as MaUTable, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table'
import { ArrowDown, ArrowUp, Search, Download } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import GlobalFilterInput from '../GlobalFilterInput'
import { blobAPI } from '../../services/api'

const DownloadsTable = ({ downloads }) => {
  const navigate = useNavigate()

  const columns = useMemo(() => [{
    Header: 'NOME',
    accessor: (originalRow) => originalRow.name
  }, {
    Header: 'Descrição',
    accessor: (originalRow) => originalRow.description
  }, {
    Header: 'Categoria',
    accessor: (originalRow) => originalRow.category?.name
  }, {
    Header: 'Criado em',
    accessor: (originalRow) => moment(originalRow.createdAt).format('DD/MM/YYYY HH:mm')
  }], [])

  const [loadingFile, setLoadingFile] = useState(false)

  const creatBlob = async (fileName) => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const openImage = async (fileName, originalName) => {
    setLoadingFile(true)
    const blobURL = await creatBlob(fileName)
    forceDownload(blobURL, originalName)
    setLoadingFile(false)
  }

  const forceDownload = useCallback((blob, filename) => {
    const a = document.createElement('a')
    a.download = filename
    a.href = blob

    document.body.appendChild(a)
    a.click()
    a.remove()
  }, [])

  const filterTypes = useMemo(() => ({
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
      })
    }
  }), [])

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const { getTableProps, headerGroups, prepareRow, rows, state, visibleColumns, preGlobalFilteredRows, setGlobalFilter } = useTable({ columns, data: downloads, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [{
        id: 'pedido',
        Cell: ({ row }) => (<IconButton size='small' onClick={() => navigate('/download/', { state: { download: row.original } })}><Search /></IconButton>)
      }, {
        id: 'download',
        Cell: ({ row }) => (<IconButton disabled={loadingFile} size='small' onClick={() => openImage(row.original.file.name, row.original.file.originalName)}><Download /></IconButton>)
      }, ...columns])
    }
  )

  return (
    <>
      {downloads?.length > 0
        ? (
          <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={visibleColumns.length} style={{ textAlign: 'left' }}>
                  <GlobalFilterInput preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                </TableCell>
              </TableRow>
              {headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted ? column.isSortedDesc ? <ArrowDown size={15} /> : <ArrowUp size={15} /> : ''}
                      <>{column.canFilter ? column.render('Filter') : null}</>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <TableRow key={index} hover {...row.getRowProps()}>
                    {row.cells.map((cell, index) => <TableCell {...cell.getCellProps()} key={index}>{cell.render('Cell')}</TableCell>)}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>)
        : <Typography color='textSecondary'>Nenhuma arquivo para donwload</Typography>}
    </ >
  )
}

export default DownloadsTable

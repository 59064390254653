import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import { currency } from '../../../services/atom'

import useStyles from './style'

const AdditionalView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [additional, setAddicitonal] = useState([])

  const fetchAdditional = useCallback(async () => {
    const response = await api.get(`/additional/${location.state?.additional._id}`)
    setAddicitonal(response.data)
    setLoading(false)
  }, [])

  const handlerEdit = useCallback((additional) => {
    navigate('/adicional/editar', { state: { additional } })
  }, [])

  useEffect(async () => {
    await fetchAdditional()
  }, [])

  return (
    <Body title='Adicional'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Adicional
              </Typography>
              <Tooltip title='Editar adicional' arrow placement='right'>
                <IconButton size='small' aria-label='editar-adicional' className={classes.plus} onClick={() => handlerEdit(additional)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            {loading && <LinearProgress color='secondary' />}
            <List component='nav' aria-label='adicional'>
              <ListItem>
                <ListItemText primary='NOME' secondary={additional.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Código' secondary={additional.code} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Descrição' secondary={additional.description} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Preço' secondary={`${currency(additional.price)}`} />
              </ListItem>
              {/* <ListItem>
                <ListItemText primary='Fabricante' secondary={additional.manufacturer?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Modelo' secondary={additional.model?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Ano' secondary={additional.year?.year} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Motor' secondary={additional.motor?.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Stage' secondary={additional.stage?.name} />
              </ListItem>
               */}
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText primary={additional.active ? 'ATIVO' : 'INATIVO'} />
              </ListItem>
              <ListItem>
                <ListItemText secondary={`Criado ${moment(additional?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default AdditionalView

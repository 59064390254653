import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { Edit2 } from 'react-feather'
import moment from 'moment'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const categoryView = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [category, setCategory] = useState([])

  const fetchCategory = useCallback(async () => {
    const response = await api.get(`/category/${location.state.category._id}`)
    setCategory(response.data)
  }, [])

  const handlerEdit = useCallback((category) => {
    navigate('/download/categoria/editar', { state: { category } })
  }, [])

  useEffect(async () => {
    await fetchCategory()
  }, [])

  return (
    <Body title='Categoria'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Categoria
              </Typography>
              <Tooltip title='Editar categoria' arrow placement='right'>
                <IconButton size='small' aria-label='editar-categoria' className={classes.plus} onClick={() => handlerEdit(category)}><Edit2 /></IconButton>
              </Tooltip>
            </Grid>
            <List component='nav' aria-label='categoria'>
              <ListItem>
                <ListItemText primary='NOME' secondary={category.name} />
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemText secondary={`Criado ${moment(category?.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' }).toUpperCase()}`} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default categoryView

import React, { useCallback, useRef } from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const StagesTypeForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME é obrigatório.'),
          code: Yup.string().required('Código é obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`stagetype/${location.state?.stagesType._id}`, {
          name: data.name,
          code: data.code
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/stage/tipo/', { state: { stagesType: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME é obrigatório.'),
          code: Yup.string().required('Código é obrigatório.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/stagetype/', {
          name: data.name,
          code: data.code
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }
        navigate('/stage/tipo/', { state: { stagesType: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [])

  return (
    <Body title={`${location.state ? 'Editar' : 'Novo'} tipo de performance`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Novo'} tipo de performance
            </Typography>
            <Form
              ref={formRef}
              className={classes.form}
              onSubmit={handleNew}
              initialData={location.state?.stagesType}
            >
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='name'
                label='NOME *'
                name='name'
                autoFocus
              />
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='code'
                label='Código *'
                name='code'
                autoFocus
              />
              <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                {location.state ? 'Atualizar' : 'Criar'} tipo de performance
              </Button>
              <Typography variant='caption'>
                Items marcados com * são obrigatórios
              </Typography>
            </Form>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default StagesTypeForm

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: { height: '100vh' },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  select: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },
  divider: {
    margin: theme.spacing(2, 0, 2, 0)
  }
}))

export default useStyles

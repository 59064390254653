import React, { useMemo } from 'react'
import { Typography, Table as MaUTable, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core'
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table'
import { ArrowDown, ArrowUp, Search, Trash2 } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../hooks'

import statusType from '../../services/statusType'
import api from '../../services/api'

const RequestBillTable = ({ requests, bill }) => {
  const navigate = useNavigate()
  const { user } = useAuth()

  const columns = useMemo(() => [{
    Header: 'Protocolo',
    accessor: (originalRow) => `${originalRow.protocol} ${originalRow.code ? `(${originalRow.code})` : ''}`
  }, {
    Header: 'Status',
    accessor: (originalRow) => statusType[originalRow.timeline[originalRow.timeline.length - 1].name].toUpperCase()
  }, {
    Header: 'Stage',
    accessor: (originalRow) => `${originalRow.manufacturer?.name} - ${originalRow.model?.name} - ${originalRow.year?.year} - ${originalRow.motor?.name} - ${originalRow.stage.name}`
  }, {
    Header: 'Placa',
    accessor: (originalRow) => originalRow.plate
  }, {
    Header: 'Chassi',
    accessor: (originalRow) => originalRow.chassi
  }], [])

  const filterTypes = useMemo(() => ({
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
      })
    }
  }), [])

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const { getTableProps, headerGroups, prepareRow, rows } = useTable({ columns, data: requests, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [{
        id: 'pedido',
        Cell: ({ row }) => (<IconButton size='small' href={`/pedido/${row.original.protocol}`}><Search /></IconButton>)
      }, {
        id: 'remove',
        Cell: ({ row }) => user.super && (<IconButton size='small' onClick={() => removeFroMFatura(row)}><Trash2 /></IconButton>)
      }, ...columns])
    }
  )

  const removeFroMFatura = (row) => {
    const confirmation = window.confirm(`Deseja remover o pedido ${row.original.protocol} da fatura?`)
    if (!confirmation) return
    api.patch('/bill/remove/request', {
      _id: bill._id,
      index: row.index
    })
    navigate(`/fatura/${bill._id}`)
  }

  return (
    <>
      {requests.length > 0
        ? (
          <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
            <TableHead>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                      {column.render('Header')}
                      {column.isSorted ? column.isSortedDesc ? <ArrowDown size={15} /> : <ArrowUp size={15} /> : ''}
                      <>{column.canFilter ? column.render('Filter') : null}</>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <TableRow hover {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => <TableCell {...cell.getCellProps()} key={index}>{cell.render('Cell')}</TableCell>)}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>)
        : <Typography variant='h6' color='textSecondary' style={{ alignSelf: 'center' }}>Nenhum pedido no momento</Typography>}
    </ >
  )
}

export default RequestBillTable

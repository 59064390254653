import React, { useCallback, useRef } from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { useAuth } from '../../../hooks'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const PasswordChange = () => {
  const { user } = useAuth()
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  // const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const handleNew = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})
      const schema = Yup.object().shape({
        oldPassword: Yup.string().required('Digita sua senha antiga.'),
        password: Yup.string().required('Digite sua nova senha.'),
        passwordConfirmation: Yup.string().required('Confirme sua nova senha.')
      })
      await schema.validate(data, { abortEarly: false })

      if (data.password !== data.passwordConfirmation) { throw new Error('Confirmação da nova senha não bate.') }
      const response = await api.patch('user', {
        userId: user._id,
        lastPassword: data.oldPassword,
        updatedPassword: data.password
      })

      if (response.data.status === 'error') { throw new Error(response.data.message) }
      navigate('/inicio')
      enqueueSnackbar('Senha atualizada com sucesso ', { variant: 'info' })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [])

  return (
    <Body title='Trocar senha'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              Trocar senha
            </Typography>
            <Form ref={formRef} className={classes.form} onSubmit={handleNew}>
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='oldPassword'
                type='password'
                label='Digite sua senha anterior *'
                name='oldPassword'
              />
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='password'
                type='password'
                label='Digite sua nova senha *'
                name='password'
              />
              <Input
                variant='outlined'
                margin='normal'
                fullWidth
                id='passwordConfirmation'
                type='password'
                label='Confirme sua nova senha *'
                name='passwordConfirmation'
              />
              <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                Trocar senha
              </Button>
            </Form>
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default PasswordChange

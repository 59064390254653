import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: theme.root,
  field: { marginBottom: theme.spacing(4) },
  form: { width: '100%' },
  logo: { height: 55 },
  footer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(4),
    flexDirection: 'column',
    marginBottom: '10vh',
    opacity: '95%',
    padding: '4vh 3vh 0vh 3vh'
  },
  paperFather: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(180deg,#f15b2b,#f15b2b )',
    backgroundSize: '100% 100%'
  },
  back: {
    margin: theme.spacing(4)
  }
}))

export default useStyles

import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Grid, Paper, Button, Typography, Divider, Switch, FormControlLabel } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const DownloadForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(null)

  const [active, setActive] = useState(true)
  const [file, setFile] = useState(false)
  const [load, setLoad] = useState(false)

  const handleSwitch = useCallback((event) => {
    setActive(event.target.checked)
  }, [])

  const handleFile = useCallback((files) => {
    setFile(files[0])
  }, [])

  const handleNew = useCallback(async (data) => {
    try {
      if (location.state) {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.'),
          category: Yup.string().required('Selecione uma categoria.')
        })

        await schema.validate(data, { abortEarly: false })

        const response = await api.put(`download/${location.state?.download._id}`, {
          name: data.name,
          description: data.description,
          category: category._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          await api.patch(`/download/${response.data._id}/file`, formData)
        }

        navigate('/download/', { state: { download: response.data } })
      } else {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string().required('NOME obrigatório.'),
          category: Yup.string().required('Selecione uma categoria.')
        })
        await schema.validate(data, { abortEarly: false })

        const response = await api.post('/download/', {
          name: data.name,
          description: data.description,
          category: category._id,
          active
        })

        if (response.data.status === 'error') { throw new Error(response.data.message) }

        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          await api.patch(`/download/${response.data._id}/file`, formData)
        }

        navigate('/download/', { state: { download: response.data } })
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationsErros(err)
        formRef.current?.setErrors(error)
        return
      }
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }, [active, file, category])

  const fetchCategory = useCallback(async () => {
    const response = await api.get('categories')
    setCategories(response.data)
  }, [])

  useEffect(async () => {
    await fetchCategory()
    if (location.state) {
      setActive(location.state?.download.active)
      setCategory(location.state?.download.category)
    }
    setLoad(true)
  }, [])

  return (
    <Body title={` ${location.state ? 'Editar' : 'Nova'} download`}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Typography variant='h4' component='h2'>
              {location.state ? 'Editar' : 'Nova'} download
            </Typography>
            {load &&
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleNew}
                initialData={location.state?.download}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  label='NOME *'
                  name='name'
                  autoFocus
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='description'
                  label='Descrição'
                  name='description'
                  multiline
                  rows={5}
                />
                <Autocomplete
                  noOptionsText='Nenhuma opção'
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='category'
                  className={classes.select}
                  options={categories}
                  value={category}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => <Input {...params} id='category' name='category' label='Selecione uma categoria *' variant='outlined' />}
                  onChange={(_, newValue) => { setCategory(newValue) }}
                />
                <Divider className={classes.divider} />
                <DropzoneArea
                  dropzoneText='Arraste o arquivo ou clique'
                  showAlerts={false}
                  filesLimit={1}
                  maxFileSize={666000000}
                  onChange={(files) => handleFile(files)}
                />
                <Divider className={classes.divider} />
                <FormControlLabel
                  label='Download ativo?'
                  control={
                    <Switch
                      checked={active}
                      onChange={handleSwitch}
                      name='active'
                      color='primary'
                    />
                  }
                />
                <Button type='submit' fullWidth variant='contained' color='secondary' className={classes.submit}>
                  {location.state ? 'Atualizar' : 'Criar'} download
                </Button>
              </Form>}
            <Typography variant='caption'>
              Items marcados com * são obrigatórios
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DownloadForm

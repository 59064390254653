import React from 'react'
import { Autocomplete } from '@material-ui/lab'

import Input from '../Input'

const CustomAutocomplete = ({ name, value, options, disabled, getOptionLabel, label, onChange, ...rest }) => {
  return (
    <Autocomplete
      noOptionsText='Nenhum opcão'
      id={name}
      name={name}
      disabled={disabled}
      options={options}
      value={value}
      key={value}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => <Input {...params} id={name} name={name} label={label} {...rest} />}
      onChange={onChange}
    />
  )
}

export default CustomAutocomplete

import React, { useCallback, useState, useEffect } from 'react'
import { Button, LinearProgress, Grid, Typography, Box, Container, Link, Divider, Drawer, List, ListItem, IconButton, AppBar, Toolbar, Hidden } from '@material-ui/core'
import { LogIn, Menu, Facebook, Instagram, Youtube } from 'react-feather'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useTranslation } from 'react-i18next'

import LanguageSelect from '../../components/LanguageSelect'
import useStyles from './style'

import config from '../../config'

import api from '../../services/api'

const Home = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [configuration, setConfiguration] = useState({})
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState(false)
  const [stages, setStages] = useState([])
  const [open, setOpen] = useState(false)

  const fetchConfiguration = useCallback(async () => {
    const response = await api.get('/configuration/public')
    setConfiguration(response.data)
  }, [])

  const fetchStore = useCallback(async () => {
    const response = await api.get('/configuration/store')
    setStore(response.data)
  }, [])

  const fetchStages = useCallback(async () => {
    const response = await api.get('/stages/home')
    setStages(response.data)
  }, [])

  useEffect(() => {
    fetchConfiguration()
    fetchStore()
    fetchStages()
    setLoading(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolBar} variant='dense'>
          <IconButton edge='start' onClick={() => setOpen(!open)} className={classes.menuButton} color='inherit' aria-label='open drawer'>
            <Menu />
          </IconButton>
          <Link to='/' style={{ color: 'white', textDecoration: 'none' }}>
            <img src='/perfortech.png' className={classes.logo} />
          </Link>
          <Box flexGrow={1} />
          <Hidden smDown>
            {store?.store &&
              <a style={{ textDecoration: 'none' }} href={store?.store} target='_blank' rel='noreferrer'>
                <Button size='medium' variant='contained' color='primary' style={{ marginRight: 10, paddingRight: 20, paddingLeft: 20 }}>
                  {t('menu.store')}
                </Button>
              </a>}
            <Button href='/about' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.sobre')}
            </Button>
            <Button href='/stage-services' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.stages')}
            </Button>
            <Button href='/contact' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.contato')}
            </Button>
            <Button href='/products' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.performance')}
            </Button>
            <Button href='/news' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.posts')}
            </Button>
            <Button href='/dealers' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.dealers')}
            </Button>
            <LanguageSelect />
            <Link href='/login' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.menuButton} color='inherit'>
                <LogIn />
              </IconButton>
            </Link>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Drawer anchor='left' open={open} onClose={() => setOpen(!open)}>
        <List className={classes.drawerList}>
          <img src='/perfortech-branco.png' className={classes.sideLogo} />
          {store?.store &&
            <>
              <ListItem button>
                <Button href={store?.store} target='_blank' fullWidth variant='contained' color='secondary'>
                  {t('menu.store')}
                </Button>
              </ListItem>
            </>}
          <ListItem button>
            <Button href='/about' fullWidth variant='text' color='secondary'>
              {t('menu.sobre')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/stage-services' fullWidth variant='text' color='secondary'>
              {t('menu.stages')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/contact' fullWidth variant='text' color='secondary'>
              {t('menu.contato')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/products' fullWidth variant='text' color='secondary'>
              {t('menu.performance')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/news' fullWidth variant='text' color='secondary'>
              {t('menu.posts')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/dealers' fullWidth variant='text' color='secondary'>
              {t('menu.dealers')}
            </Button>
          </ListItem>
          <Divider />
          <LanguageSelect />
          <Divider />
          <Link href='/login' variant='body2' color='textSecondary'>
            <IconButton edge='start' className={classes.menuButton} color='inherit'>
              <LogIn />
            </IconButton>
          </Link>
        </List>
      </Drawer>

      <Grid item xs={12} sm={12} md={12} className={classes.titleGrid}>
        <Hidden smDown>
          <Typography variant='h1' className={classes.title}>{t('perfortech.title')}</Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography variant='h2' className={classes.title}>{t('perfortech.title')}</Typography>
        </Hidden>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.select}>
        <Typography variant='h4' className={classes.selectTitle}>RECENT REMAPS</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.select2}>
        {loading
          ? <LinearProgress />
          : (
            <>
              {stages.length > 0
                ? (
                  <>
                    {stages.map((stage, index) =>
                      <div key={index} className={classes.reremapLink}>
                        <Link href={`/product/${stage._id}`} variant='body2' color='textSecondary'>
                          <div><img src={stage?.image?.name ? `${config[process.env.NODE_ENV].blob}${stage?.image?.name}` : `/images/${Math.floor(Math.random() * 3)}.jpg`} style={{ maxHeight: 200, maxWidth: 300, padding: 5 }} /></div>
                          <Typography variant='subtitle1'>{stage.manufacturer.name} - {stage.model.name}</Typography>
                          <Typography variant='h6' color='primary'>{stage.motor.name}</Typography>
                        </Link>
                      </div>
                    )}
                  </>)
                : (
                  <Link variant='body2' color='textSecondary' className={classes.reremapLink}>
                    <Typography variant='subtitle1'>{t('anyOne')}</Typography>
                  </Link>)}
            </>)}
      </Grid>

      {/*
      <Grid item xs={12} sm={12} md={12} className={classes.select} >
        <Typography variant='h4' className={classes.selectTitle} >{t('perfortech.select')}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.select2} >
        <Form ref={formRef} className={classes.select2}>
          <Autocomplete
            variant='outlined'
            margin='normal'
            className={classes.auto}
            options={[{ name: 'Fabricante' }]}
            style={{ width: 200 }}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <Input {...params} margin='dense' id='fabricante' name='fabricante' label='Fabricante' color='primary' variant='outlined' />}
          />
          <Autocomplete
            variant='outlined'
            margin='normal'
            className={classes.auto}
            options={[{ name: 'Modelo' }]}
            disabled
            style={{ width: 200, marginLeft: 10 }}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <Input {...params} margin='dense' id='modelo' name='modelo' label='Modelo' color='primary' variant='outlined' />}
          />

          <Autocomplete
            variant='outlined'
            margin='normal'
            className={classes.auto}
            options={[{ name: 'Fabricação' }]}
            disabled
            style={{ width: 200, marginLeft: 10 }}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <Input {...params} margin='dense' id='fab' name='fab' label='Fabricação' color='primary' variant='outlined' />}
          />

          <Autocomplete
            variant='outlined'
            margin='normal'
            className={classes.auto}
            options={[{ name: 'Versão' }]}
            disabled
            style={{ width: 200, marginLeft: 10 }}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <Input {...params} margin='dense' id='versao' name='versao' label='Versão' color='primary' variant='outlined' />}
          />
          <Button style={{ width: 200, marginLeft: 10 }} type='submit' variant='contained' color='primary'>{t('perfortech.selectOption')}</Button>
        </Form>

      </Grid> */}

      <Grid item xs={12} sm={12} md={12} className={classes.somos}>
        <Typography className={classes.somosTitle}>{t('perfortech.somosTitle')}</Typography>
        <Typography className={classes.somosSub}>{t('perfortech.somosSub')}</Typography>
        <Link href='/contact' variant='body2' color='textSecondary'>
          <Button className={classes.somosButton} variant='contained' color='primary'>{t('perfortech.contato')}</Button>
        </Link>
      </Grid>

      <Grid container xs={12} sm={12} md={12} className={classes.sobreContainer}>
        <Grid item xs={6} sm={6} md={6} className={classes.sobre}>
          <Typography className={classes.sobreTitle}>{t('perfortech.sobreTitle')}</Typography>
          <Typography className={classes.sobreSub}>{t('perfortech.sobreSub')}</Typography>
          <Link href='/about' variant='body2' color='textSecondary'>
            <Button className={classes.somosButton} type='submit' variant='contained' color='primary'>{t('perfortech.sobre')}</Button>
          </Link>
          <Link href='/posts' variant='body2' color='textSecondary'>
            <Button className={classes.somosButton} type='submit' variant='contained' color='primary'>{t('perfortech.posts')}</Button>
          </Link>
        </Grid>
        <Grid container xs={6} sm={6} md={6} className={classes.sobre2}>
          <Grid item xs={12} sm={12} md={12} className={classes.sobreImage} />
        </Grid>
      </Grid>

      <Grid container className={classes.sobreContainerWhite}>
        <Grid item xs={12} sm={12} md={12} className={classes.select}>
          <Typography variant='h4' className={classes.selectTitle}>{t('prodigy.behinde')}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ padding: '0% 5% 0% 5%' }}>
          {loading
            ? <Divider />
            : <iframe width='100%' height='420' src={configuration.behind ? configuration.behind : 'https://www.youtube.com/embed/bNltm3cGupc'} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />}
          {/* i18n.language */}
          <Typography variant='body1' className={classes.selectTitle}>{configuration.behindBody}</Typography>

          {/* behindBody */}
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} style={{ padding: '1% 5% 5% 5%' }}>
          <Typography variant='h6' style={{
            color: 'black',
            fontWeight: '800',
            textAlign: 'left',
            margin: 0,
          }} >DYNO DEVELOPMENT</Typography>
          <Typography variant='h6' style={{
            color: 'black',
            fontWeight: '800',
            textAlign: 'left',
            margin: 0,
          }}>FOR KTM RC 390</Typography>
          <Typography variant='subtitle1'>Lorem ipsum dolor sit amet, consec</Typography>
        </Grid> */}
      </Grid>

      <Grid container xs={12} sm={12} md={12} className={classes.footer}>
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo}>
          <Typography className={classes.footerInfoFontWhite}>{t('home.follow')}</Typography>

          {configuration.facebook &&
            <Link href={'//' + configuration.facebook} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.footerIcon}>
                <Facebook size={30} />
              </IconButton>
            </Link>}
          {configuration.instagram &&
            <Link href={'//' + configuration.instagram} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.footerIcon}>
                <Instagram size={30} />
              </IconButton>
            </Link>}
          {configuration.youtube &&
            <Link href={'//' + configuration.youtube} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.footerIcon}>
                <Youtube size={30} />
              </IconButton>
            </Link>}

        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo} />
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo}>
          <Typography className={classes.footerInfoFontBold}>PERFOTECH MOTOR SPORT. (ESCRITÓRIO)</Typography>
          <Typography className={classes.footerInfoFont}>Av.General Mello N. 2759, Jardim Tropical SALA 02, Cuiabá - MT / CEP 78065-175</Typography>
          <Typography className={classes.footerInfoFontBold}>(65) 8405-5001</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo}>
          {/* <Typography className={classes.footerInfoFontBold}>FLORIPA MOTOR SPORT</Typography>
          <Typography className={classes.footerInfoFont}>Av. Patricio Caldeira de Andrade N. 1180, Capoeiras, Florianópolis - SC / CEP 88085-150</Typography>
          <Typography className={classes.footerInfoFontBold}>(48) 3211-8001</Typography> */}
        </Grid>
        <div style={{ textAlign: 'center', margin: 'auto', paddingBottom: 20, paddingTop: 40 }}>
          <Typography className={classes.footerInfoFont}>© Copyright 2022 - Perfortech - Todos direitos reservados</Typography>
        </div>
      </Grid>
      {configuration.whatsapp &&
        <Container style={{ position: 'absolute' }}>
          <FloatingWhatsApp
            phoneNumber={configuration.whatsapp}
            accountName='Prodigy Performance'
            avatar='/perfortech.png'
            darkMode={false}
            statusMessage='Prodigy Performance'
            chatMessage='Olá, como podemos ajudar?'
            allowClickAway
            placeholder='Digite aqui...'
          />
        </Container>}
    </Grid>
  )
}

export default Home

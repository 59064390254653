import React, { useState } from 'react'
import { FormControl, TextField } from '@material-ui/core'
import { useAsyncDebounce } from 'react-table'

export default ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, label = 'PESQUISA' }) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => { setGlobalFilter(value || undefined) }, 200)

  return (
    <FormControl fullWidth>
      <TextField
        size='small'
        label={label + ` [ ${count} ]`}
        defaultValue={value || ''}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </FormControl>
  )
}

import React, { useCallback, useState, useEffect } from 'react'
import { Button, Grid, Paper, Box, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import { useAuth } from '../../../hooks'

import DownloadTable from '../../../components/DownloadTable'
import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const DownloadIndex = () => {
  const { user } = useAuth()
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [downloads, setDownloads] = useState([])
  const navigate = useNavigate()

  const fetchDownload = useCallback(async () => {
    setLoading(true)
    const response = await api.get('downloads')
    setDownloads(response.data)
    setLoading(false)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/download/novo')
  }, [])

  const handlerCategories = useCallback((download) => {
    navigate('/download/categorias/')
  }, [])

  useEffect(async () => {
    await fetchDownload()
  }, [])

  return (
    <Body title='Downloads'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Downloads
              </Typography>
              {user.admin &&
                <>
                  <Tooltip title='Novo fabricante' arrow placement='right'>
                    <IconButton size='small' aria-label='novo-fabricante' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
                  </Tooltip>
                  <Box flexGrow={1} />
                  <Button variant='contained' color='primary' onClick={handlerCategories}>
                    Categorias
                  </Button>
                </>}
            </Grid>
            {loading
              ? <LinearProgress color='secondary' />
              : <DownloadTable downloads={downloads} />}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default DownloadIndex
